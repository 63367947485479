export const UserMenuIconSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2.66683 8L9.3335 8'
      stroke='rgb(var(--brand-color))'
      strokeWidth='1.75'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.66683 4L13.3335 4'
      stroke='rgb(var(--brand-color))'
      strokeWidth='1.75'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.66683 12L5.3335 12'
      stroke='rgb(var(--brand-color))'
      strokeWidth='1.75'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
