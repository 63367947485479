import type { SWRMutationConfig } from './useApiHooks';
import { useMutationApi } from './useApiHooks';

interface UpdateDeviceRequest {
  name: string;
  organizationId: string;
  projectId: string;
}

interface UpdateDevicePathParams {
  deviceId: string;
}

export const useUpdateDevice = (
  { deviceId }: UpdateDevicePathParams,
  swrConfig?: SWRMutationConfig<never>
) => useMutationApi<UpdateDeviceRequest>('patch', `/devices/${deviceId}`, undefined, swrConfig);
