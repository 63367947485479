import type { SelectOption } from '@fleet/components';
import { ProjectUserRole } from '../const';
import type { User } from '../hooks/api/useGetOrganizations';
import { isEmpty } from '@fleet/utils';
import type { StringKeys } from '../types';

export const getUserFullName = (user?: User) => {
  if (!user || (!user.givenName && !user.familyName)) return;
  return `${user.givenName || ''} ${user.familyName || ''}`.trim();
};

export const projectUserRoleOptions: SelectOption<ProjectUserRole>[] = Object.values(
  ProjectUserRole
)
  .map(option => ({
    label: option.charAt(0).toUpperCase() + option.slice(1).toLowerCase(),
    value: option
  }))
  .filter(option => option !== null) as SelectOption<ProjectUserRole>[];

export const shouldUserUpdateDetails = (user: User): boolean => {
  const USER_EDITABLE_FIELDS: StringKeys<User>[] = ['givenName', 'familyName'];
  return USER_EDITABLE_FIELDS.some(key => key && isEmpty(user[key]));
};
