import { twMerge } from 'tailwind-merge';

import { Spinner } from '../../icons';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: (e: React.SyntheticEvent) => void;
  variant?: 'mono' | 'solid' | 'outline';
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  dataPw?: string;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  variant = 'mono',
  icon,
  className,
  disabled,
  loading,
  dataPw,
  ...buttonProps
}) => {
  const buildClasses = () => {
    // Base classes
    const classes = [
      'relative shadow-filter-button flex items-center rounded-full px-4 py-2 text-sm truncate transition-all duration-300 ease-out'
    ];

    // Hover classes
    if (variant === 'mono' || variant === 'outline') {
      classes.push('hover:brightness-95 active:brightness-90');
    } else if (variant === 'solid') {
      classes.push('hover:brightness-90 active:brightness-75');
    }

    // Variant classes
    if (variant === 'mono') {
      classes.push('bg-white text-gray-700 [&>*>svg]:stroke-black border border-gray-200');
    } else if (variant === 'solid') {
      classes.push('bg-brand text-white [&>*>svg]:stroke-white');
    } else if (variant === 'outline') {
      classes.push('bg-white text-brand [&>*>svg]:stroke-brand border border-brand');
    }

    // State classes
    if (disabled || loading) {
      classes.push('opacity-50 pointer-events-none');
    }

    return twMerge(classes, className);
  };

  const classes = buildClasses();

  return (
    <button
      className={classes}
      onClick={onClick}
      disabled={disabled}
      data-pw={dataPw}
      {...buttonProps}
    >
      {icon ? <div className='mr-2'>{icon}</div> : null}
      {loading ? (
        <Spinner
          className={twMerge(
            'absolute left-0 right-0 mx-auto size-5 border-2',
            variant === 'mono' && 'border-gray-200',
            variant === 'solid' && 'border-white',
            variant === 'outline' && 'border-brand'
          )}
        />
      ) : null}
      <div className={loading ? 'invisible' : 'visible'}>{children}</div>
    </button>
  );
};
