const style: React.CSSProperties = { maskType: 'luminance' };

export const SkidsteerTopSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='282'
    height='500'
    viewBox='0 0 282 500'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_120_2891)'>
      <path
        d='M95.4961 343.06L95.4961 227.96L186.107 227.96L186.107 343.06L95.4961 343.06Z'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M112.639 199.063L112.639 181.92L168.474 181.92L168.474 199.063L112.639 199.063Z'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <path
        d='M75.415 169.675L75.415 95.7178L205.698 95.7178L205.698 169.675L75.415 169.675Z'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinejoin='round'
      />
      <mask
        id='mask0_120_2891'
        style={style}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='282'
        height='501'
      >
        <path
          d='M281.113 0.000500569L281.113 500.001L-2.18557e-05 500.001L0 0.000488281L281.113 0.000500569Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_120_2891)'>
        <path
          d='M125.895 234.817L113.619 242.319L113.619 259.845C113.619 262.794 116.009 265.184 118.958 265.184L162.157 265.184C165.105 265.184 167.495 262.794 167.495 259.845L167.495 242.465L155.219 234.817L125.895 234.817Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M110.465 327.876L105.292 330.054L105.292 336.692L175.821 336.692L175.821 330.096L170.649 327.876L110.465 327.876Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M112.149 386.162L112.11 381.264L183.658 381.264L184.755 383.223L197.372 383.223L197.372 394.978L84.2314 394.978L84.2314 383.223L96.476 383.223L98.4891 381.264L101.374 381.264L101.374 383.223L101.374 386.162'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.761 389.1C104.597 389.1 102.843 387.346 102.843 385.182C102.843 383.018 104.597 381.264 106.761 381.264C108.925 381.264 110.68 383.018 110.68 385.182C110.68 387.346 108.925 389.1 106.761 389.1Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.149 385.672C112.149 388.647 109.737 391.06 106.762 391.06C103.786 391.06 101.374 388.647 101.374 385.672'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.2305 397.328L84.2305 394.977L197.371 394.977L197.371 397.328C197.371 401.169 194.257 404.283 190.416 404.283L91.1851 404.283C87.3443 404.283 84.2305 401.169 84.2305 397.328Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.8027 175.553L80.8027 169.675L200.31 169.675L200.31 175.553L80.8027 175.553Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.698 104.044L75.415 104.044'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.698 114.33L75.415 114.33'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.698 121.676L75.415 121.676'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.698 153.513L75.415 153.513'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M190.515 354.324L90.5986 354.324'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M173.862 358.243L173.862 377.345'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.087 358.243L187.087 377.345'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.638 358.243L160.638 377.345'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.413 357.753L147.413 376.855'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.189 357.753L134.189 376.855'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.965 357.753L120.965 376.855'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.741 357.753L107.741 376.855'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.5166 357.753L94.5166 376.855'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M90.5986 379.793L90.5986 227.959L190.515 227.959L190.515 379.793L90.5986 379.793Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M190.515 246.571L190.515 221.103L197.861 221.103L197.861 246.571L190.515 246.571Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M190.515 204.94L190.515 175.553L197.861 175.553L197.861 204.94L190.515 204.94Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M190.515 249.021L190.515 246.572L208.637 246.572L208.637 249.021L190.515 249.021Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M190.515 381.263L190.515 249.021L197.861 249.021L197.861 381.263L190.515 381.263Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M189.575 217.184L188.066 215.147L188.066 204.94L202.27 204.94L202.27 215.088L200.762 217.184L189.575 217.184Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M189.535 221.102L189.535 217.184L200.8 217.184L200.8 221.102L189.535 221.102Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M178.271 350.406L178.271 343.06L183.658 343.06L183.658 350.406L178.271 350.406Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M112.639 191.226L90.5986 191.226L90.5986 178.981L190.515 178.981L190.515 191.226L168.475 191.226'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M168.475 205.919L168.475 191.226L182.189 191.226L182.189 205.919L168.475 205.919Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M172.883 191.226L172.883 205.919'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M182.188 205.919L188.066 205.919'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M186.107 205.919L186.107 227.96'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M183.658 224.532L97.9453 224.532'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.658 205.919L183.658 224.531'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.861 374.896L197.861 364.61L203.249 364.61L203.249 374.896L197.861 374.896Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.372 383.713L197.372 381.264'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.27 212.777L202.27 205.92L205.698 205.92L205.698 212.777L202.27 212.777Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.698 211.797L205.698 206.899L209.127 206.899L209.127 211.797L205.698 211.797Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 182.41L190.516 182.41'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.821 169.675L151.821 163.308L178.27 163.308L178.27 169.675L151.821 169.675Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.761 167.716L154.761 165.268L175.332 165.268L175.332 167.716L154.761 167.716Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 258.817C199.432 258.782 197.862 258.817 198.923 258.779'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.917 230.409L203.694 223.045'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.778 220.833L203.695 217.115'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 224.549L200.567 221.103'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 228.998L203.696 221.563'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.821 219.294L203.694 215.632'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.866 223.062L199.403 221.103'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.695 205.919L203.695 189.525'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.694 246.488L203.694 212.776'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.694 257.34L203.694 249.021'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.518 205.92L204.518 191.754'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.518 246.571L204.518 212.776'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.518 255.111L204.518 249.021'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.331 204.886L203.695 199.324'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 200.829L203.696 193.394'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.126 204.94L203.696 197.841'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.27 205.588L203.695 203.771'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.695 189.525C203.291 189.346 202.884 189.177 202.474 189.02'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.811 189.864C201.507 189.753 201.201 189.648 200.895 189.549'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.57 188.691C200.626 188.367 199.672 188.099 198.709 187.889'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.518 191.754L203.695 189.525'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.695 189.525L202.474 189.02'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.473 189.02L201.811 189.864'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.811 189.864L200.895 189.549'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.896 189.55L201.569 188.691'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.57 188.691L198.709 187.889'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.708 187.889L197.991 188.803'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.1 194.654L202.474 189.02'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.028 188.777L198.203 188.549L198.708 187.889'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.912 205.919L190.514 198.597'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 199.346L203.696 191.911'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.843 193.523L201.569 188.691'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.045 204.939L190.514 203.024'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.035 246.572L203.695 239.354'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 242.353L203.696 234.906'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 236.41L203.696 228.975'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 240.857L203.696 233.423'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.948 234.817L203.695 227.493'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.676 246.572L203.695 245.284'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.024 252.45L200.738 249.021'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.13 253.919L201.941 249.021'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.923 258.779L203.695 252.697'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.346 258.449L203.695 254.18'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.939 257.483C200.44 257.357 200.938 257.217 201.434 257.061'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.346 258.449C201.476 258.153 202.595 257.783 203.695 257.34'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.923 258.78L199.94 257.483'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.939 257.483L201.434 257.061'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.434 257.061L200.346 258.448'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.346 258.449L203.695 257.34'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.695 257.339L204.518 255.11'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.33 191.226L183.33 205.919'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.249 246.572L200.347 245.173L200.699 244.655L203.695 240.836'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.658 205.712L188.073 200.085L188.749 199.165L190.366 197.104L183.658 205.712Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M190.515 192.759L183.33 201.685'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M190.515 191.149L183.33 200.202'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.617 191.319L183.33 194.272'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.564 191.226L183.33 192.789'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 344.039C199.432 344.005 197.373 344.039 198.923 344.002'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 315.703L203.696 308.268'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 309.773L203.696 302.338'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 314.221L203.696 306.786'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.866 308.285L203.695 300.855'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 303.842L203.696 296.407'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.694 342.562L203.694 274.748'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.518 340.333L204.518 276.977'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 291.982L203.696 284.546'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.095 285.755L203.695 278.617'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 290.499L203.696 283.064'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 297.912L203.696 290.477'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.167 296.04L203.695 288.995'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.695 274.747C203.291 274.568 202.884 274.4 202.474 274.242'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.811 275.087C201.507 274.976 201.201 274.871 200.895 274.772'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.57 273.913C200.626 273.589 199.672 273.322 198.709 273.111'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.517 276.977L203.694 274.748'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.695 274.747L202.474 274.242'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.473 274.242L201.811 275.087'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.811 275.087L200.895 274.772'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.896 274.772L201.569 273.913'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.57 273.913L198.709 273.111'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.708 273.112L197.991 274.026'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.1 279.877L202.474 274.243'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 274.219L198.709 273.112'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 284.569L203.696 277.134'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.104 278.408L201.569 273.913'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 302.36L203.696 294.925'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.035 331.794L203.695 324.576'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 327.576L203.696 320.129'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 321.633L203.696 314.198'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.989 325.917L203.695 318.646'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 320.151L203.696 312.716'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.024 337.671L203.695 330.506'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 339.486L203.696 331.989'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.923 344.002L203.695 337.92'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.346 343.671L203.695 339.402'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.939 342.706C200.44 342.58 200.938 342.44 201.434 342.284'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.346 343.672C201.476 343.377 202.595 343.007 203.695 342.563'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.923 344.002L199.94 342.706'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.939 342.706L201.434 342.284'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.434 342.284L200.346 343.672'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.346 343.672L203.695 342.563'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.694 342.562L204.517 340.333'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.862 333.585L199.302 331.75L199.765 331.07L203.696 326.06'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M190.515 379.793L190.515 227.959L90.5986 227.959L90.5986 379.793L190.515 379.793Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M90.5986 246.571L90.5986 221.103L83.252 221.103L83.252 246.571L90.5986 246.571Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M90.5986 204.94L90.5986 175.553L83.252 175.553L83.252 204.94L90.5986 204.94Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M90.5979 249.021L90.5979 246.572L72.4756 246.572L72.4756 249.021L90.5979 249.021Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M90.5986 381.263L90.5986 249.021L83.252 249.021L83.2519 381.263L90.5986 381.263Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M91.5389 217.184L93.0473 215.147L93.0473 204.94L78.8438 204.94L78.8437 215.088L80.3522 217.184L91.5389 217.184Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M91.5775 221.102L91.5775 217.184L80.3125 217.184L80.3125 221.102L91.5775 221.102Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M102.844 350.406L102.844 343.06L97.4561 343.06L97.4561 350.406L102.844 350.406Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M168.475 191.226L190.515 191.226L190.515 178.981L90.5986 178.981L90.5986 191.226L112.639 191.226'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M112.639 205.919L112.639 191.226L98.9248 191.226L98.9248 205.919L112.639 205.919Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M108.231 191.226L108.231 205.919'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M98.9243 205.919L93.0469 205.919'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M95.0059 205.919L95.0059 227.96'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M97.4561 224.532L183.169 224.532'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.4561 205.919L97.4561 224.531'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2519 374.896L83.2519 364.61L77.8643 364.61L77.8643 374.896L83.2519 374.896Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.7422 383.713L83.7422 381.264'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.8437 212.777L78.8437 205.92L75.415 205.92L75.415 212.777L78.8437 212.777Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.415 211.797L75.415 206.899L71.9863 206.899L71.9863 211.797L75.415 211.797Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.252 182.41L90.5986 182.41'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.291 169.675L129.291 163.308L102.843 163.308L102.843 169.675L129.291 169.675Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.352 167.716L126.352 165.268L105.781 165.268L105.781 167.716L126.352 167.716Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2516 258.817C81.6816 258.782 83.2516 258.817 82.1904 258.779'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.1954 230.409L77.418 223.045'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.3345 220.832L77.418 217.115'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2515 224.549L80.5469 221.103'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 228.998L77.418 221.562'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.2919 219.294L77.4189 215.632'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2469 223.062L81.71 221.103'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4189 205.919L77.4189 189.525'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4189 246.488L77.4189 212.776'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4189 257.34L77.4189 249.021'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.5957 205.92L76.5957 191.754'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.5957 246.571L76.5957 212.776'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.5957 255.111L76.5957 249.021'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.7822 204.886L77.418 199.324'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 200.829L77.418 193.394'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.9875 204.94L77.418 197.841'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.8443 205.588L77.4189 203.771'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4189 189.525C77.8234 189.346 78.2304 189.177 78.6404 189.02'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.3018 189.865C79.6059 189.754 79.9112 189.649 80.2179 189.55'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5449 188.691C80.4882 188.367 81.4424 188.099 82.4055 187.889'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.5957 191.754L77.4182 189.525'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4189 189.525L78.6404 189.02'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.6396 189.02L79.3022 189.864'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.3018 189.865L80.2179 189.55'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.2178 189.55L79.5439 188.691'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5449 188.691L82.4055 187.889'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.4053 187.889L83.1223 188.803'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.0137 194.654L78.6396 189.02'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.084 188.777L82.9098 188.549L82.4043 187.889'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.2008 205.919L90.5986 198.597'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 199.346L77.418 191.911'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2698 193.523L79.5439 188.691'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M92.0679 204.939L90.5986 203.024'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.0785 246.572L77.4189 239.354'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 242.354L77.418 234.906'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 236.41L77.418 228.975'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 240.857L77.418 233.423'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.1643 234.817L77.418 227.493'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.4374 246.572L77.418 245.284'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.0894 252.45L80.376 249.021'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.984 253.919L79.1729 249.021'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.1903 258.779L77.418 252.697'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.7675 258.449L77.418 254.18'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.1732 257.483C80.6725 257.357 80.1743 257.217 79.6787 257.061'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.7675 258.449C79.6369 258.153 78.5186 257.783 77.418 257.34'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.1897 258.78L81.1729 257.483'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.1732 257.483L79.6787 257.061'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.6787 257.061L80.7673 258.448'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.7675 258.449L77.418 257.34'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4182 257.339L76.5957 255.11'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.7832 191.226L97.7832 205.919'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.8648 246.572L80.767 245.173L80.4149 244.655L77.4189 240.836'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.4553 205.712L93.0403 200.085L92.3646 199.165L90.7471 197.104L97.4553 205.712Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M90.5986 192.759L97.7835 201.685'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M90.5986 191.149L97.7835 200.202'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.4961 191.319L97.7831 194.272'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.5498 191.226L97.7833 192.789'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2516 344.039C81.6816 344.005 83.7413 344.039 82.1904 344.002'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 315.703L77.418 308.268'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 309.773L77.418 302.338'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 314.221L77.418 306.786'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.248 308.285L77.4189 300.855'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 303.842L77.418 296.407'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4189 342.562L77.4189 274.748'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.5957 340.333L76.5957 276.977'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 291.982L77.418 284.547'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.0186 285.755L77.418 278.617'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 290.499L77.418 283.064'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 297.912L77.418 290.477'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.9468 296.04L77.4189 288.995'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4189 274.747C77.8234 274.568 78.2304 274.4 78.6404 274.242'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.3018 275.087C79.6059 274.976 79.9112 274.871 80.2179 274.772'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5449 273.913C80.4882 273.589 81.4424 273.322 82.4055 273.111'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.5957 276.977L77.4182 274.748'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4189 274.747L78.6404 274.242'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.6396 274.242L79.3022 275.087'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.3018 275.087L80.2179 274.772'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.2178 274.772L79.5439 273.913'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5449 273.913L82.4055 273.111'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.4053 273.111L83.1223 274.025'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.0137 279.877L78.6396 274.243'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2523 274.219L82.4053 273.112'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 284.569L77.418 277.134'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.0109 278.408L79.5449 273.913'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 302.36L77.418 294.925'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.0785 331.794L77.4189 324.576'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 327.576L77.418 320.129'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 321.633L77.418 314.198'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.1247 325.917L77.4189 318.646'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 320.151L77.418 312.716'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.0899 337.671L77.4189 330.506'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 339.486L77.418 331.989'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.1903 344.002L77.418 337.92'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.7675 343.671L77.418 339.402'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.1732 342.706C80.6725 342.58 80.1743 342.44 79.6787 342.284'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.7675 343.672C79.6369 343.377 78.5186 343.007 77.418 342.563'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.1897 344.002L81.1729 342.706'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.1732 342.706L79.6787 342.284'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.6787 342.284L80.7673 343.672'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.7675 343.672L77.418 342.563'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4182 342.563L76.5957 340.334'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2514 333.585L81.8115 331.75L81.3492 331.07L77.418 326.06'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_120_2891'>
        <rect width='500' height='281.113' fill='white' transform='translate(281.113) rotate(90)' />
      </clipPath>
    </defs>
  </svg>
);
