import { useApi } from './useApiHooks';
import { generateURLSearchParams } from '../../utils';

export enum InsightAudience {
  AUDIENCE_CUSTOMER = 'AUDIENCE_CUSTOMER',
  AUDIENCE_SUPPORT = 'AUDIENCE_SUPPORT',
  AUDIENCE_ENGINEERING = 'AUDIENCE_ENGINEERING'
}

export enum InsightSeverity {
  CRITICAL = 0,
  PROBLEM = 1,
  WARNING = 2,
  INTERESTING = 3,
  NORMAL = 4
}

export type InsightsData = Record<string, string | number | undefined> & {
  device_id: string;
  project_id: string;
  organization_id: string;
};

export interface Insight {
  startedAt: string;
  insightClass: string;
  severity: InsightSeverity;
  priority: number;
  summaryTemplate: string;
  detailsTemplate: string;
  data: InsightsData;
  audience: InsightAudience;
  insightId: string;
}

export interface GetInsightsQuery {
  audience?: InsightAudience;
  severity?: number;
  organizationIds?: string;
  projectIds?: string;
  deviceIds?: string;
}

export interface GetInsightsResponse {
  results: Insight[];
}

export const useGetInsights = (queryParams: GetInsightsQuery, disableQuery?: boolean) => {
  const searchParams = generateURLSearchParams(queryParams);

  return useApi<GetInsightsResponse>('get', disableQuery ? null : `/insights${searchParams}`);
};
