import {
  AlertType,
  DateOptions,
  DetectionType,
  FilterLabels,
  ReviewStatus,
  RiskState,
  Routes
} from './Enums';

export const ENABLED_FILTERS_PER_ROUTE: Record<Routes, FilterLabels[]> = {
  // Set the default value for all routes before adding the specific ones
  ...(Object.fromEntries(
    Object.values(Routes).map(route => [route, [] as FilterLabels[]])
  ) as Record<Routes, FilterLabels[]>),
  [Routes.ACTION_CENTER]: [FilterLabels.PROJECT, FilterLabels.DEVICE],
  [Routes.DETECTIONS]: Object.values(FilterLabels),
  [Routes.ANALYTICS]: [FilterLabels.PROJECT, FilterLabels.DEVICE, FilterLabels.DATE]
};

export const REQUIRED_FILTERS = [
  FilterLabels.ALERT_TYPE,
  FilterLabels.DATE,
  FilterLabels.DETECTION_TYPE,
  FilterLabels.RISK_STATE
];

export const DEFAULT_FILTERS = {
  [FilterLabels.ALERT_TYPE]: AlertType.CRITICAL,
  [FilterLabels.DATE]: DateOptions.LAST_7_DAYS,
  [FilterLabels.DETECTION_TYPE]: DetectionType.PERSON,
  [FilterLabels.RISK_STATE]: RiskState.ANY_RISK,
  [FilterLabels.REVIEW_STATUS]: ReviewStatus.ALL
};

export const DEFAULT_TIMEZONE = 'Australia/Sydney';

export const SUPPORTED_TIMEZONES: { timezone: string; label: string }[] = [
  { timezone: 'UTC', label: 'UTC' },
  { timezone: 'Australia/Perth', label: 'Perth' },
  { timezone: 'Australia/Brisbane', label: 'Brisbane' },
  { timezone: 'Australia/Adelaide', label: 'Adelaide' },
  { timezone: 'Australia/Sydney', label: 'Canberra, Melbourne, Sydney' }
];
