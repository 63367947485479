export const PresienLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='70'
    height='20'
    viewBox='0 0 70 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M1.62791 18.1252H4.5761V12.0685H9.04053C12.2835 12.0685 14.0946 10.2471 14.0946 6.86759C14.0946 3.48809 12.2835 1.66667 9.04053 1.66667H1.62791V18.1252ZM4.5761 9.58874V4.1245H7.94547C10.2198 4.1245 10.9779 4.80478 10.9779 6.86759C10.9779 8.93038 10.2198 9.58874 7.94547 9.58874H4.5761Z'
      fill='#3E3E3E'
    />
    <path
      d='M14.7094 18.1251H17.5733V11.7612C17.5733 9.9617 18.6262 8.6889 20.4373 8.6889H21.6588V6.07747H20.5848C18.8789 6.07747 17.9734 7.15276 17.5313 8.33779V6.27497H14.7094V18.1251Z'
      fill='#3E3E3E'
    />
    <path
      d='M26.7015 18.3448C29.6918 18.3448 31.7555 16.8306 31.9871 14.4386H29.2284C28.9547 15.6017 28.0912 16.1942 26.7224 16.1942C24.9535 16.1942 24.1955 15.2067 24.0902 12.8366H32.1555V11.52C32.1555 8.07463 30.2814 6.05571 26.7435 6.05571C23.1635 6.05571 21.3315 8.1624 21.3315 12.1783C21.3315 16.1942 23.1426 18.3448 26.7015 18.3448ZM24.1323 10.9713C24.3219 8.99631 25.122 8.20629 26.7856 8.20629C28.5124 8.20629 29.3759 9.08408 29.4391 10.9713H24.1323Z'
      fill='#3E3E3E'
    />
    <path
      d='M37.4797 18.3448C40.8069 18.3448 42.5337 17.072 42.5337 14.5922C42.5337 12.7927 41.6281 11.7614 39.6274 11.3224L37.0585 10.7519C36.0477 10.5325 35.6474 10.1594 35.6474 9.50103C35.6474 8.66714 36.3214 8.18434 37.5426 8.18434C38.743 8.18434 39.3326 8.68909 39.459 9.78633H42.1546C42.1546 7.3724 40.4489 6.05571 37.4374 6.05571C34.405 6.05571 32.7414 7.35045 32.7414 9.65464C32.7414 11.4102 33.689 12.4416 35.6686 12.9025L38.0693 13.4292C39.2485 13.6925 39.6486 14.0436 39.6486 14.7678C39.6486 15.7333 38.8905 16.2161 37.3954 16.2161C35.879 16.2161 35.2262 15.6895 35.121 14.3069H32.4045C32.4466 17.0281 34.089 18.3448 37.4797 18.3448Z'
      fill='#3E3E3E'
    />
    <path
      d='M43.2265 18.1252H46.0904V6.27508H43.2265V18.1252ZM43.2265 4.36587H46.0904V1.66667H43.2265V4.36587Z'
      fill='#3E3E3E'
    />
    <path
      d='M52.2159 18.3448C55.2063 18.3448 57.2699 16.8306 57.5016 14.4386H54.743C54.4691 15.6017 53.6059 16.1942 52.2371 16.1942C50.4682 16.1942 49.7099 15.2067 49.6047 12.8366H57.6702V11.52C57.6702 8.07463 55.7959 6.05571 52.258 6.05571C48.6782 6.05571 46.8459 8.1624 46.8459 12.1783C46.8459 16.1942 48.6571 18.3448 52.2159 18.3448ZM49.6467 10.9713C49.8363 8.99631 50.6366 8.20629 52.3002 8.20629C54.027 8.20629 54.8903 9.08408 54.9535 10.9713H49.6467Z'
      fill='#3E3E3E'
    />
    <path
      d='M58.3854 18.1253H61.2493V11.4322C61.2493 9.67659 61.9233 8.51353 63.5446 8.51353C64.9766 8.51353 65.5033 9.39132 65.5033 10.708V18.1253H68.3673V10.3569C68.3673 7.59185 66.9562 6.05571 64.6186 6.05571C63.0605 6.05571 61.839 6.75794 61.1441 8.20629V6.27516H58.3854V18.1253Z'
      fill='#3E3E3E'
    />
  </svg>
);
