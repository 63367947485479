import { AlertType, dayJS } from '../const';
import type { BlindsightIndexResponse, GetAggregatedAlertsResponse } from '../hooks';

export const getAggregatedIndex = (indexData: BlindsightIndexResponse | undefined, count = 7) => {
  const aggregatedIndex = new Array<number | null>(count * 2).fill(null);

  indexData?.results.map(({ periodStartAt, blindsightIndex }) => {
    const dayOffset = count * 2 - dayJS().diff(dayJS({ date: periodStartAt }), 'days');
    aggregatedIndex[dayOffset] = blindsightIndex;
  });

  return aggregatedIndex;
};

export const getPreviousAggregatedIndexAverage = (
  aggregatedIndex: (number | null)[] | undefined
) => {
  if (!aggregatedIndex) return 0;

  const prevIndex = aggregatedIndex
    .slice(0, aggregatedIndex.length / 2)
    .flatMap(index => (index === null ? [] : [index]));
  const indexAvg = Math.round(prevIndex.reduce((a, c) => a + c, 0) / prevIndex.length);
  return Number.isNaN(indexAvg) ? 0 : indexAvg;
};

export const getCurrentAggregatedIndexAverage = (
  aggregatedIndex: (number | null)[] | undefined
) => {
  if (!aggregatedIndex) return 0;

  const currIndex = aggregatedIndex
    .slice(aggregatedIndex.length / 2, aggregatedIndex.length)
    .flatMap(index => (index === null ? [] : [index]));

  const indexAvg = Math.round(currIndex.reduce((a, c) => a + c, 0) / currIndex.length);
  return Number.isNaN(indexAvg) ? 0 : indexAvg;
};

export const getAggregatedIndexChange = (prevIndexAverage: number, currIndexAverage: number) => {
  const change = ((currIndexAverage - prevIndexAverage) / prevIndexAverage) * 100;

  if (Number.isNaN(change)) return 0;

  return change;
};

export const getAggregatedAlerts = (alertsData: GetAggregatedAlertsResponse | undefined) => {
  const aggregatedAlerts = new Array<number>(14).fill(0);
  const aggregatedCriticalAlerts = new Array<number>(14).fill(0);

  alertsData?.results?.map(({ periodStartAt, alertType, ...rest }) => {
    const alertCount = rest.confirmedAlertCount + rest.notReviewedAlertCount;

    const dayOffset = 14 - dayJS().diff(dayJS({ date: periodStartAt }), 'days');
    if (alertType === AlertType.GENERAL) {
      aggregatedAlerts[dayOffset] = alertCount;
    }
    if (alertType === AlertType.CRITICAL) {
      aggregatedCriticalAlerts[dayOffset] = alertCount;
    }
  });

  return { aggregatedAlerts, aggregatedCriticalAlerts };
};

export const getAggregatedCriticalAlertsChange = (aggregatedCriticalAlerts: number[]) => {
  const prevCritAlertsSum = aggregatedCriticalAlerts.slice(0, 7).reduce((a, b) => a + b, 0);
  const currCritAlertsSum = aggregatedCriticalAlerts.slice(7, 14).reduce((a, b) => a + b, 0);

  const change = ((currCritAlertsSum - prevCritAlertsSum) / prevCritAlertsSum) * 100;

  if (Number.isNaN(change)) return 0;
  if (change === Number.POSITIVE_INFINITY) return 100;

  return change;
};

export const getAggregatedAlertsChange = (aggregatedAlerts: number[]) => {
  const prevAlertsSum = aggregatedAlerts.slice(0, 7).reduce((a, b) => a + b, 0);
  const currAlertsSum = aggregatedAlerts.slice(7, 14).reduce((a, b) => a + b, 0);

  const change = ((currAlertsSum - prevAlertsSum) / prevAlertsSum) * 100;

  if (Number.isNaN(change)) return 0;
  if (change === Number.POSITIVE_INFINITY) return 100;

  return change;
};

export const getIndexChangeTooltipText = (
  aggregatedIndex: (number | null)[],
  percentChange: number,
  count = 7
) => {
  const prevIndex = getPreviousAggregatedIndexAverage(aggregatedIndex);
  const currIndex = getCurrentAggregatedIndexAverage(aggregatedIndex);

  const percentDirection = `${percentChange > 0 ? 'Up' : 'Down'} ${Math.round(
    Math.abs(percentChange)
  )}%`;

  return (
    <>
      <p>
        Average {currIndex} this {count === 30 ? 'month' : 'week'}
      </p>
      <p>
        {percentDirection} from {prevIndex} last {count === 30 ? 'month' : 'week'}
      </p>
    </>
  );
};

export const getAlertsChangeTooltipText = (aggregatedAlerts: number[], percentChange: number) => {
  const prevAlertsSum = aggregatedAlerts.slice(0, 7).reduce((a, b) => a + b, 0);
  const currAlertsSum = aggregatedAlerts.slice(7, 14).reduce((a, b) => a + b, 0);

  const percentDirection = `${percentChange > 0 ? 'Up' : 'Down'} ${Math.round(
    Math.abs(percentChange)
  )}%`;

  return (
    <>
      <p>Total {currAlertsSum} this week</p>
      <p>
        {percentDirection} from {prevAlertsSum} last week
      </p>
    </>
  );
};
