import type { AxiosError, AxiosRequestConfig, Method } from 'axios';
import type { Key, SWRConfiguration } from 'swr';
import useSwr from 'swr';
import useSWRInfinite from 'swr/infinite';
import type { SWRMutationConfiguration } from 'swr/mutation';
import useSWRMutation from 'swr/mutation';

import { axiosInstance } from '../../utils';

export const PAGINATED_LIMIT = 20;

export interface OffsetPaginatedQuery {
  offset?: number;
  limit?: number;
}

export interface OffsetPaginatedResponse {
  resultsContinue: boolean;
  limit: number;
  offset: number;
}

export interface ApiError {
  error: string;
  message: string | string[];
  statusCode: number;
}

export type SWRMutationConfig<Response> = SWRMutationConfiguration<Response, AxiosError<ApiError>>;

export const useApi = <Response>(
  method: Method,
  url: string | null,
  config?: AxiosRequestConfig,
  swrConfig?: SWRConfiguration
) => {
  const response = useSwr<Response, AxiosError<ApiError>>(
    url,
    () => axiosInstance<Response>(url ?? '', { method, ...config }).then(res => res.data),
    swrConfig
  );
  return response;
};

export const useOffsetApi = <Response extends OffsetPaginatedResponse>(
  method: Method,
  url: string | null,
  paginatedLimit = PAGINATED_LIMIT,
  config?: AxiosRequestConfig,
  swrConfig?: SWRConfiguration
) => {
  const response = useSWRInfinite<Response, AxiosError<ApiError>>(
    (pageIndex, previousPageData: Response) => {
      if ((previousPageData && !previousPageData.resultsContinue) || !url) return null;
      if (pageIndex === 0) return url;
      return `${url}&offset=${pageIndex * paginatedLimit}`;
    },
    (urlKey: string) =>
      axiosInstance<Response>(urlKey, { method, ...config }).then(res => res.data),
    swrConfig
  );
  return response;
};

export const useMutationApi = <Request, Response = never>(
  method: Method,
  apiUrl: string,
  config?: AxiosRequestConfig,
  swrConfig?: SWRMutationConfig<Response>
) => {
  const response = useSWRMutation<Response, AxiosError<ApiError>, Key, Request>(
    apiUrl,
    (url: string, { arg }: { arg: Request }) =>
      axiosInstance<Response>(url ?? '', { method, data: { ...arg }, ...config })
        .then(res => res.data)
        .catch(error => {
          throw error;
        }),
    swrConfig
  );
  return response;
};
