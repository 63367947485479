import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { FilterLabels } from '../const';
import type { Filters, QueryParams } from '../types';

export const useRetrieveFilters = () => {
  const [filters, setFilters] = useState<Filters>();

  const router = useRouter();
  const query = router.query as QueryParams;
  const isReady = router.isReady;

  useEffect(() => {
    if (!isReady) return;

    const filters = { ...query };

    for (const key of Object.keys(filters)) {
      //delete key if it doesnt exist in the Filters type as it is not a filter
      if (!Object.values(FilterLabels).includes(key as keyof QueryParams)) {
        delete filters[key as keyof QueryParams];
      }
    }

    setFilters(filters);
  }, [query, router, isReady]);

  return filters;
};
