import type { OffsetPaginatedQuery, OffsetPaginatedResponse } from './useApiHooks';
import { useApi } from './useApiHooks';
import { generateURLSearchParams } from '../../utils';

export interface BlindsightIndexAggregate {
  timezone: string;
  organizationId: string;
  projectId: string;
  deviceId: string;
  periodStartAt: string;
  alertCount: number;
  enabledTimeInSecs: number;
  blindsightIndex: number | null;
  blindsightIndex7DayAverage: number;
}

export interface BlindsightIndexQuery extends OffsetPaginatedQuery {
  organizationIds?: string;
  projectIds?: string;
  deviceIds?: string;
  timezone: string;
  startAt: string;
  reportingPeriodSecs: number;
}

export interface BlindsightIndexResponse extends OffsetPaginatedResponse {
  results: BlindsightIndexAggregate[];
}

export const useGetBlindsightIndex = ({
  queryParams,
  disableQuery,
  variant
}: {
  queryParams: BlindsightIndexQuery;
  disableQuery?: boolean;
  variant: 'weekly' | 'daily';
}) => {
  const newQueryParams: BlindsightIndexQuery = { ...queryParams, limit: 10_000 };

  return useApi<BlindsightIndexResponse>(
    'get',
    `/blindsight-index/${variant}${generateURLSearchParams(newQueryParams)}`
  );
};
