import { useEffect, useState } from 'react';

import type { BrandingTheme } from '../const';
import { BRANDING_THEMES } from '../const';

export const useBrandingTheme = () => {
  const [brandingTheme, setBrandingTheme] = useState<BrandingTheme | undefined>();

  useEffect(() => {
    const brandingTheme =
      BRANDING_THEMES.find(({ origins }) => origins.includes(window.location.origin)) ||
      BRANDING_THEMES[0];
    setBrandingTheme(brandingTheme);
  }, []);

  return brandingTheme;
};
