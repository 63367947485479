import type { Map } from 'leaflet';
import { useEffect, useState } from 'react';
import type { MapContainerProps } from 'react-leaflet';

// This hook is needed to import map container dynamically due to unsupported ssr handling
// and the fact that when importing dynamically, the only ref object exposed is the retry fn.
// To access the MapContainer ref object correctly, this import is required
export const useGetMapContainer = () => {
  const [MapContainer, setMapContainer] =
    useState<React.ForwardRefExoticComponent<MapContainerProps & React.RefAttributes<Map>>>();

  useEffect(() => {
    if (MapContainer !== undefined) return;

    import('react-leaflet').then(({ MapContainer: LMapContainer }) => {
      setMapContainer(LMapContainer);
    });
  }, [MapContainer]);

  return MapContainer;
};
