const style: React.CSSProperties = { maskType: 'luminance' };

export const DozerTopSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='282'
    height='500'
    viewBox='0 0 282 500'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_97_3236)'>
      <path
        d='M76.6812 343.005L110.702 343.041'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M110.905 293.185L110.904 259.344'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.556 235.979L114.782 235.936'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M77.4463 129.97L111.639 129.942'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M110.905 335.79L140.556 335.79'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M145.7 350.478L146.782 350.478L146.782 335.79L134.45 335.79L134.45 350.478L135.412 350.478'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M116.773 144.939L115.27 233.078'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M103.391 293.185L140.556 293.185'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M103.391 304.862L103.391 293.185'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.556 304.862L103.391 304.862'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M110.905 335.789L110.904 313.124'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M107.148 313.124L140.556 313.121'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M110.905 335.789L96.0557 335.701'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M96.0557 306.304L107.159 306.304'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M96.0557 259.345L110.903 259.344'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M107.103 259.344L107.103 293.184'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M114.84 335.821L114.844 339.676'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask
        id='mask0_97_3236'
        style={style}
        maskUnits='userSpaceOnUse'
        x='-1'
        y='0'
        width='283'
        height='500'
      >
        <path
          d='M281.112 1.22878e-05L281.112 500L-0.000510137 500L-0.000488281 0L281.112 1.22878e-05Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_97_3236)'>
        <path
          d='M98.3281 283.259C98.3281 284.644 99.4511 285.767 100.836 285.767C102.221 285.767 103.344 284.644 103.344 283.259C103.344 281.873 102.221 280.75 100.836 280.75C99.4511 280.75 98.3281 281.873 98.3281 283.259Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.161 304.862L107.149 313.132'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.744 234.188L103.744 235.934'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.0654 234.188L103.744 234.188'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.0654 235.934L97.0654 234.188'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.16 327.408C101.16 328.861 102.338 330.039 103.79 330.039C105.243 330.039 106.421 328.861 106.421 327.408C106.421 325.955 105.243 324.777 103.79 324.777C102.338 324.777 101.16 325.955 101.16 327.408Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.27 233.078L110.904 259.344'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.928 253.34L111.89 253.397'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.608 231.396L108.928 253.34'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.296 231.452L112.608 231.396'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.3057 99.9268L73.3159 338.864'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.819 129.943L207.819 339.676'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.506 231.396L172.186 253.34'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.843 232.993L170.209 259.343'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.369 234.188L177.369 235.934'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.048 235.934L184.048 234.188'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M173.953 304.862L173.959 313.126'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M173.964 313.126L140.557 313.123'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.692 327.408C174.692 328.861 175.87 330.039 177.323 330.039C178.776 330.039 179.953 328.861 179.953 327.408C179.953 325.955 178.776 324.777 177.323 324.777C175.87 324.777 174.692 325.955 174.692 327.408Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.207 335.79L170.208 313.126'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.208 293.184L170.21 259.344'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.01 259.344L174.01 293.184'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.557 304.862L177.722 304.862'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.722 293.184L140.557 293.184'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.722 304.862L177.722 293.185'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.056 306.304L173.954 306.304'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.769 283.259C177.769 284.644 178.892 285.767 180.277 285.767C181.662 285.767 182.784 284.644 182.784 283.259C182.784 281.873 181.662 280.75 180.277 280.75C178.892 280.75 177.769 281.873 177.769 283.259Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.186 253.34L169.224 253.397'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.057 235.934L170.22 235.934'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.057 259.344L170.209 259.344'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.557 235.979L166.331 235.936'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.253 153.307L166.254 144.948'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.377 144.948L165.843 232.993'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.048 234.188L177.37 234.188'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.817 231.46L168.505 231.396'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.564 129.942L169.473 129.941'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.678 343.042L169.574 343.042'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.207 335.79L185.057 335.702'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.032 343.042L169.574 343.042'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.531 343.042L169.573 343.042'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.207 335.79L140.557 335.79'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.044 121.352L162.069 121.356'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.538 158.704L164.607 158.704'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.966 192.16C136.966 194.143 138.574 195.75 140.557 195.75C142.54 195.75 144.147 194.143 144.147 192.16C144.147 190.176 142.54 188.569 140.557 188.569C138.574 188.569 136.966 190.176 136.966 192.16Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.126 195.392C138.75 195.879 138.375 196.365 138.135 196.972C137.566 198.417 137.769 200.545 139.078 201.31C139.744 201.7 140.698 201.735 141.339 201.373C142.303 200.829 142.559 199.386 142.47 198.166C142.398 197.18 142.099 196.339 141.801 195.499'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.395 195.628C145.395 199.095 148.206 201.906 151.674 201.906C155.141 201.906 157.952 199.095 157.952 195.628C157.952 192.16 155.141 189.349 151.674 189.349C148.206 189.349 145.395 192.16 145.395 195.628Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.288 195.628C149.288 196.946 150.357 198.014 151.674 198.014C152.992 198.014 154.06 196.946 154.06 195.628C154.06 194.31 152.992 193.242 151.674 193.242C150.357 193.242 149.288 194.31 149.288 195.628Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.465 233.078L121.465 226.424L160.127 226.424L160.127 233.078L121.465 233.078Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.782 274.578L127.782 255.22L154.226 255.22L154.226 274.578L127.782 274.578Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M93.5264 235.934L111.846 235.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.865 235.434L114.865 231.784'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.0552 259.344L96.0566 335.701'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.923 337.667L93.5264 337.667'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.934 335.79L110.923 337.667'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.873 337.667L189.873 235.934'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.189 337.667L189.821 337.667'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.872 235.934L169.267 235.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.851 245.192L191.986 245.192'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.985 252.263L189.851 252.263'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.986 245.192L191.986 252.263'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.179 335.79L170.19 337.667'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.057 259.344L185.057 335.701'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.248 235.435L166.248 231.787'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.843 233.078L160.127 233.078'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.27 233.079L121.465 233.078'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M222.548 77.1133L140.557 77.1133'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M58.4463 77.1133L140.556 77.1133'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M222.548 89.5041L222.548 77.1128'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M58.4473 89.5041L58.4473 77.1128'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M222.548 89.5044L58.4473 89.5044'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M219.493 77.1133L219.493 89.5046'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M61.6206 77.1133L61.6206 89.5046'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M228.038 69.0806L222.548 77.1131'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.237 77.113L223.377 63.3667'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M223.377 63.3662L227.869 65.9617'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M227.869 65.9619L228.038 69.0805'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.3425 77.113L57.7363 63.3667'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M57.7357 63.3662L53.2441 65.9617'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M53.2438 65.9614L53.0747 69.08'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M53.0752 69.0806L58.5657 77.1131'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M69.4443 71.7314C116.786 75.2867 164.328 75.2867 211.669 71.7315'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M222.548 99.9273L222.548 89.5049'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M58.4463 99.9273L58.4463 89.5049'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M58.4463 99.9268L222.547 99.9268'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.86 153.308L114.859 144.939'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.349 144.949L120.349 126.69L111.921 126.69L111.921 144.949L120.349 144.949Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.044 104.399L119.044 126.691'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.293 104.399L113.293 126.691'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.12 133.236L125.12 128.144L120.349 128.144L120.349 133.236L125.12 133.236Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.293 104.399L119.044 104.399'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.782 99.9268L117.782 104.399'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.6 99.9268L114.6 104.399'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.069 104.399L162.069 126.691'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.765 144.949L160.765 126.69L169.192 126.69L169.192 144.949L160.765 144.949Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.993 133.309L155.993 128.144L160.764 128.144L160.764 133.309L155.993 133.309Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.82 104.399L167.82 126.691'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.514 99.9268L166.514 104.399'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.332 99.9268L163.332 104.399'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.82 104.399L162.069 104.399'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.349 133.473L160.765 133.473'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.64 123.89L159.64 128.144'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.432 123.89L159.641 123.89'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.432 128.144L121.432 123.89'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.993 130.927L125.12 130.927'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.454 128.342L155.993 128.342'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.32 125.568L159.64 125.568'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M58.4473 95.8018L222.548 95.8018'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.315 154.369L116.613 154.369'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.315 153.281L113.315 154.369'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.63 153.338L113.315 153.281'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.861 166.7L114.86 154.369'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.483 153.338L167.798 153.281'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.798 153.281L167.798 154.369'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.799 154.369L164.501 154.369'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.469 166.704L112.684 218.506'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.684 218.51L115.527 218.505'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.393 166.696L113.469 166.704'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.864 218.038L114.861 167.17'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.865 231.442L114.865 218.505'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.645 166.704L168.43 218.506'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.43 218.51L165.587 218.505'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.081 166.695L167.644 166.705'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.249 218.038L166.252 167.17L166.252 166.7L166.253 154.369'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.249 231.45L166.249 218.507'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M65.1772 278.482L73.3134 278.482'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.819 99.9268L207.819 129.943'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M215.937 278.482L207.819 278.482'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M210.093 166.662L210.093 136.675L214.65 136.675L214.65 166.662L210.093 166.662Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M210.204 108.885L210.204 99.9175L214.626 99.9175L214.626 108.885L210.204 108.885Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M208.098 136.66L208.098 108.874L216.65 108.874L216.65 136.66L208.098 136.66Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M71.0206 166.662L71.0206 136.675L66.4639 136.675L66.4639 166.662L71.0206 166.662Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.0162 136.66L73.0162 108.874L64.4639 108.874L64.4639 136.66L73.0162 136.66Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M70.9095 108.885L70.9095 99.9175L66.4878 99.9175L66.4878 108.885L70.9095 108.885Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.819 164.737L210.093 164.737'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.819 159.822L210.093 159.822'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M71.022 159.822L73.2961 159.822'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M71.022 164.737L73.2961 164.737'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M65.1772 136.66L65.1772 278.483'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M65.1772 99.9268L65.1772 108.874'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M215.936 136.66L215.936 278.483'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M215.912 99.9268L215.912 108.874'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.677 343.043C205.662 342.991 207.363 341.609 207.819 339.677'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.703 343.043C112.688 342.991 114.389 341.609 114.845 339.677'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.287 338.92C166.337 340.874 167.679 342.558 169.574 343.042'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.3159 338.863C73.3675 340.848 74.7497 342.549 76.682 343.005'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4473 129.971C75.4627 130.022 73.7617 131.404 73.3057 133.337'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.823 134.057C207.771 132.073 206.389 130.372 204.457 129.916'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.192 140.538L207.819 140.538'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.763 174.549L207.818 174.55'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.033 192.271L207.819 192.272'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.311 210.662L207.739 210.662'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.192 133.958L207.819 133.958'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.249 229.599L207.819 229.599'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.873 266.078L207.819 266.078'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.873 284.666L207.819 284.666'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.873 303.452L207.819 303.452'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.873 322.034L207.818 322.034'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.873 335.385L207.819 335.385'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.976 340.832L207.062 340.832'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.131 340.832L74.0288 340.832'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.2403 335.385L73.2944 335.385'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.2403 322.034L73.2944 322.034'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.2403 303.452L73.2944 303.452'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.2401 284.666L73.313 284.666'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.2404 266.078L73.3125 266.078'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.128 248.631L73.2949 248.631'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.866 229.599L73.2949 229.599'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.922 140.538L73.2949 140.538'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.922 133.958L73.2949 133.958'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.253 155.853L207.819 155.856'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.3398 155.853L114.906 155.856'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.3398 174.549L113.35 174.549'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.3096 192.271L113.081 192.271'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.3101 210.662L112.802 210.662'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.769 248.631L191.986 248.631'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.2407 337.667L91.2407 235.934'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.2407 235.934L111.847 235.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.0566 235.934L110.894 235.934'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.1279 245.192L89.1279 252.263'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.1279 252.263L91.2626 252.263'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.2621 245.192L89.1274 245.192'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.923 337.667L91.291 337.667'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.542 335.79L117.254 335.79'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.413 367.32L135.413 335.784'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.701 335.785L145.701 367.321'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.327 367.32L147.327 368.721L133.745 368.721L133.745 367.32L147.327 367.32Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.681 368.721L145.681 370.643L135.391 370.643L135.391 368.721L145.681 368.721Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.7 367.319L135.413 367.319'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.595 436.634L143.595 420.576L137.354 420.576L137.354 436.634L143.595 436.634Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.885 436.634L187.885 420.576L181.644 420.576L181.644 436.634L187.885 436.634Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.634 436.634L100.634 420.576L94.3931 420.576L94.3931 436.634L100.634 436.634Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.382 335.79L163.86 335.79'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.732 335.79L117.254 335.79'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.821 420.575L163.859 420.575'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.821 436.634L192.821 420.576'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.3545 436.634L192.821 436.634'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.3545 420.575L89.3545 436.633'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.254 420.575L89.3545 420.575'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.731 436.633L125.731 413.238'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.254 413.239L117.254 436.634'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.382 420.576L125.731 420.576'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.021 420.575L137.021 416.064L143.922 416.064L143.922 420.575L137.021 420.575Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.586 414.46L186.16 420.575'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.096 420.575L184.585 414.46'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.8491 420.575L97.3378 414.46'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.3374 414.46L98.911 420.575'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.098 416.081L138.098 370.644'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.291 416.081L138.098 416.081'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.29 370.643L143.29 416.08'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.254 413.239L125.732 413.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.464 413.239L123.341 413.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.464 413.239L123.341 413.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.464 413.239L123.341 413.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.101 413.239L120.101 335.79L122.903 335.79L122.903 413.239L120.101 413.239Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.105 413.239L161.105 335.79L158.302 335.79L158.302 413.239L161.105 413.239Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.474 436.633L155.474 413.238'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.947 413.239L163.947 436.634'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.741 413.239L157.864 413.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.741 413.239L157.864 413.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.741 413.239L157.864 413.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.952 413.239L155.474 413.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.65 433.945L183.65 423.003L185.808 423.003L185.808 433.945L183.65 433.945Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.437 433.945L139.438 423.003L141.595 423.003L141.595 433.945L139.437 433.945Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.6641 433.945L96.6641 423.003L98.8218 423.003L98.8218 433.945L96.6641 433.945Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.735 432.775L119.735 423.944L123.435 423.944L123.435 432.775L119.735 432.775Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.788 432.775L157.788 423.944L161.487 423.944L161.487 432.775L157.788 432.775Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.664 418.202L115.664 415.385L117.254 415.385L117.254 418.202L115.664 418.202Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.955 418.202L163.955 415.385L165.544 415.385L165.544 418.202L163.955 418.202Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.396 413.239L124.678 413.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.377 413.239L118.377 335.79L124.548 335.79L124.548 413.239L118.377 413.239Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.625 413.239L156.625 335.79L162.796 335.79L162.796 413.239L156.625 413.239Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.906 321.28L170.207 321.28'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.287 338.919L166.272 335.789'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.678 343.042L169.574 343.042'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.253 144.949L169.192 144.949'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.45 127.516L138.45 129.853L143.454 129.853L143.454 127.516L138.45 127.516Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.72 124.936L144.194 124.936'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.72 124.935C137.697 124.934 137.674 124.934 137.651 124.935C136.929 124.954 136.359 125.555 136.378 126.277C136.398 126.999 136.998 127.568 137.72 127.549'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.195 127.55C144.198 127.55 144.201 127.55 144.205 127.55C144.927 127.547 145.51 126.96 145.507 126.238C145.504 125.516 144.917 124.933 144.195 124.936'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.194 127.549L137.72 127.549'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.432 125.568L136.565 125.568'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.12 128.342L138.45 128.342'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_97_3236'>
        <rect width='500' height='281.113' fill='white' transform='translate(281.113) rotate(90)' />
      </clipPath>
    </defs>
  </svg>
);
