export const UserAvatar: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='32' height='32' rx='8' fill='#E9E9E9' />
    <path
      d='M16.0009 22.82L19.5809 9.10001H23.0409V22.9H21.3209V9.18001L17.7809 22.9H14.2209L10.6809 9.18001V22.9H8.96094V9.10001H12.4209L16.0009 22.82Z'
      fill='#3E3E3E'
    />
  </svg>
);
