import { twMerge } from 'tailwind-merge';

import { Spinner } from '../../icons';

interface LoadingProps {
  className?: string;
}

export const Loading: React.FC<LoadingProps> = ({ className }) => (
  <div className={twMerge('flex h-full items-center justify-center', className)}>
    <Spinner />
  </div>
);
