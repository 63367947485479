export const VideoReviewIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='32' height='32' rx='6' className='fill-brand-300' />
    <path
      d='M11.2407 12.7802C10.1677 12.4556 9.51184 12.021 9.51184 11.5435C9.51184 10.5386 12.4167 9.72388 16 9.72388C19.5834 9.72388 22.4882 10.5386 22.4882 11.5435C22.4882 12.021 21.8323 12.4556 20.7593 12.7802'
      className='stroke-system-100'
      strokeWidth='0.7'
      strokeLinecap='round'
    />
    <path
      d='M9.51184 20.4564C9.51184 21.4614 12.4167 22.2761 16 22.2761C19.5834 22.2761 22.4882 21.4614 22.4882 20.4564'
      className='stroke-system-100'
      strokeWidth='0.7'
      strokeLinecap='round'
    />
    <path
      d='M22.4882 20.455V11.5713M9.51184 20.455V11.5616'
      className='stroke-system-100'
      strokeWidth='0.7'
      strokeLinecap='round'
    />
    <path
      d='M22.4882 11.5411C22.4882 12.087 21.6311 12.5768 20.2737 12.9103M9.51184 11.5411C9.51184 12.087 10.369 12.5768 11.7263 12.9103'
      className='stroke-system-100'
      strokeWidth='0.7'
      strokeLinecap='round'
    />
    <path
      d='M22.4882 15.4618C22.4882 16.0077 21.6311 16.4974 20.2737 16.8309M9.51184 15.4618C9.51184 16.0077 10.369 16.4974 11.7263 16.8309'
      className='stroke-system-100'
      strokeWidth='0.7'
      strokeLinecap='round'
    />
    <path
      d='M13.4617 9.93231V11.5616'
      className='stroke-system-100'
      strokeWidth='0.7'
      strokeLinecap='round'
    />
    <path
      d='M18.5383 9.93231V11.5616'
      className='stroke-system-100'
      strokeWidth='0.7'
      strokeLinecap='round'
    />
    <path
      d='M11.7159 12.9103V21.6902'
      className='stroke-system-100'
      strokeWidth='0.7'
      strokeLinecap='round'
    />
    <path
      d='M20.2841 12.9103V21.6902'
      className='stroke-system-100'
      strokeWidth='0.7'
      strokeLinecap='round'
    />
    <path
      d='M14.0764 18.8832V14.7997C14.0768 14.7314 14.0952 14.6643 14.1298 14.6055C14.1644 14.5465 14.2139 14.4978 14.2734 14.4642C14.3329 14.4306 14.4003 14.4134 14.4686 14.4142C14.5369 14.415 14.6038 14.4338 14.6625 14.4688L18.16 16.5011C18.2189 16.5351 18.2677 16.5841 18.3017 16.643C18.3357 16.702 18.3536 16.7688 18.3536 16.8368C18.3536 16.9048 18.3357 16.9715 18.3017 17.0305C18.2677 17.0893 18.2189 17.1383 18.16 17.1723L14.6625 19.2141C14.6038 19.2491 14.5369 19.268 14.4686 19.2687C14.4003 19.2696 14.3329 19.2523 14.2734 19.2187C14.2139 19.1851 14.1644 19.1364 14.1298 19.0775C14.0952 19.0186 14.0768 18.9516 14.0764 18.8832Z'
      className='stroke-system-100'
      strokeWidth='0.7'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
