export const PresienAvatar: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='32' height='32' rx='8' className='fill-brand-300' />
    <path
      d='M20.7033 22.7858V11.2932C20.6359 11.2885 20.5824 11.279 20.5274 11.279C16.7517 11.279 12.9759 11.279 9.2002 11.2822V9.20001H18.1558C18.6474 9.32877 19.0731 9.56117 19.4343 9.93176C20.3123 10.8315 21.2029 11.7187 22.0996 12.5996C22.5755 13.0676 22.8002 13.6234 22.8002 14.2876C22.7955 17.0277 22.7986 19.7678 22.7986 22.508C22.7986 22.6005 22.7986 22.6948 22.7986 22.8L20.7049 22.7858H20.7033Z'
      fill='#3E3E3E'
    />
  </svg>
);
