export const JCBLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='60'
    height='24'
    viewBox='0 0 60 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_783_7216)'>
      <path d='M0.0124512 0H59.9875V24H0.0124512V0Z' fill='#E7B35C' />
      <mask
        id='mask0_783_7216'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='3'
        y='3'
        width='54'
        height='18'
      >
        <path d='M3.36975 3.42798H56.471V20.8063H3.36975V3.42798Z' fill='white' />
      </mask>
      <g mask='url(#mask0_783_7216)'>
        <path
          d='M56.5048 19.8119C56.5048 20.3589 56.1782 20.8063 55.7794 20.8063H4.09017C3.6912 20.8063 3.36475 20.3589 3.36475 19.8119V4.42246C3.36475 3.87537 3.6912 3.42798 4.09017 3.42798H55.7793C56.1782 3.42798 56.5047 3.87537 56.5047 4.42246L56.5048 19.8119Z'
          fill='#231F20'
        />
      </g>
      <path
        d='M16.7583 17.8437C15.8896 18.7464 14.6036 18.9529 12.7093 18.9529H10.0158C6.24486 18.9529 5.04578 17.9939 5.04578 14.6852V12.8799H8.66026C8.66026 13.0492 8.64285 13.2184 8.64285 13.3689C8.64285 15.0231 9.02519 15.5498 10.5372 15.5498H12.275C13.6652 15.5498 13.9084 14.9858 13.9084 13.6134V5.1339H17.7664V14.9483C17.7663 16.1326 17.3664 17.2046 16.7583 17.8437ZM32.3394 9.9281C32.3394 8.83792 31.6961 8.38662 30.3061 8.38662H27.2127C24.7104 8.38662 24.1022 8.74365 24.1022 11.0751V12.9743C24.1022 15.1177 24.9536 15.5498 27.2127 15.5498H29.4546C31.5919 15.5498 32.4434 15.3055 32.4434 13.6696V13.5191H36.3534C36.3708 13.8766 36.3882 14.1962 36.3882 14.5159C36.3882 16.2078 36.0231 17.3361 35.1195 18.0507C34.1638 18.8212 32.6519 18.9718 30.445 18.9718H26.1354C24.1368 18.9718 22.7293 18.8779 21.6345 17.9564C20.5744 17.0542 20.1746 15.8132 20.1746 13.9517V10.2291C20.1746 6.13034 21.8254 4.9646 26.1354 4.9646H30.445C32.6521 4.9646 34.1465 5.07749 35.0676 5.84824C35.8842 6.52533 36.2317 7.65321 36.2317 9.28888C36.2317 9.53329 36.2317 9.79659 36.2143 10.0598H32.3395V9.9281H32.3394ZM38.8708 18.8026V5.1339H50.2532C53.0858 5.1339 54.4587 6.37493 54.4587 8.95064C54.4587 10.4172 53.8679 11.3194 52.5995 11.8462C54.1979 12.1281 54.8934 13.1432 54.8934 15.0988C54.8934 17.7311 53.6421 18.8026 50.8441 18.8026H38.8708ZM50.4965 9.32648C50.4965 8.64983 50.0967 8.19887 49.506 8.19887H42.7287V10.4547H49.506C50.1491 10.4547 50.4965 10.0599 50.4965 9.32648ZM50.9135 14.4407C50.9135 13.7074 50.4965 13.2373 49.8535 13.2373H42.7287V15.7005H49.8535C50.5312 15.7005 50.9135 15.2491 50.9135 14.4407Z'
        fill='white'
      />
      <path d='M5.04578 5.04248H10.2987V10.4917H5.04578V5.04248Z' fill='#E7B35C' />
      <path
        d='M8.8842 7.80477C8.8842 7.80477 8.97065 7.80138 8.99494 7.7996C9.01957 7.79782 9.06562 7.79541 9.09365 7.85369C9.10097 7.86874 9.14449 8.01354 9.15007 8.03047C9.15546 8.04775 9.16826 8.12599 9.11107 8.13989C9.10044 8.14229 8.99885 8.16394 8.99885 8.16394L8.8842 7.80477ZM8.68911 7.13943C8.68911 7.13943 8.80098 7.14834 8.82866 7.15075C8.85617 7.15342 8.87558 7.16697 8.88664 7.19316C8.89369 7.20938 8.93556 7.3425 8.94044 7.35693C8.94523 7.37137 8.96647 7.45121 8.9044 7.45308C8.89491 7.45335 8.78357 7.45308 8.78357 7.45308L8.68911 7.13943ZM8.29467 6.75852C8.29467 6.75852 8.76476 6.83131 8.80855 6.83907C9.00442 6.87292 9.08965 7.04383 9.11359 7.12384C9.12177 7.15004 9.17609 7.33608 9.18306 7.35631C9.23651 7.51304 9.10097 7.59395 9.10097 7.59395C9.10097 7.59395 9.25575 7.62451 9.30816 7.75915C9.30816 7.75915 9.40627 8.06905 9.41602 8.10032C9.42542 8.13196 9.49541 8.37931 9.25697 8.47028C9.22511 8.4824 8.84137 8.59307 8.84137 8.59307L8.29467 6.75852ZM6.39246 6.44995L7.16288 6.57487L7.28728 7.02877L7.1076 7.01228C7.1076 7.01228 7.55027 8.54736 7.56577 8.59503C7.62122 8.76798 7.655 8.94716 7.41255 9.03342C7.38113 9.04464 7.15522 9.11512 7.1278 9.12216C6.98791 9.15763 6.80988 9.15825 6.74093 8.92293L6.45931 7.97371L6.80535 7.94742C6.80535 7.94742 6.94847 8.43455 6.95953 8.47064C6.99113 8.57257 7.0108 8.61169 7.10917 8.58701C7.18151 8.56883 7.17847 8.53729 7.1594 8.4701C7.15139 8.44159 6.73475 6.98056 6.73475 6.98056L6.53766 6.96452L6.39246 6.44995Z'
        fill='#231F20'
      />
      <path
        d='M6.39246 6.44995L7.16288 6.57487L7.28728 7.02877L7.1076 7.01228C7.1076 7.01228 7.55027 8.54736 7.56577 8.59503C7.62122 8.76798 7.655 8.94716 7.41255 9.03342C7.38113 9.04464 7.15522 9.11512 7.1278 9.12216C6.98791 9.15763 6.80988 9.15825 6.74093 8.92293L6.45931 7.97371L6.80535 7.94742C6.80535 7.94742 6.94847 8.43455 6.95953 8.47064C6.99113 8.57257 7.0108 8.61169 7.10917 8.58701C7.18151 8.56883 7.17847 8.53729 7.1594 8.4701C7.15139 8.44159 6.73475 6.98056 6.73475 6.98056L6.53766 6.96452L6.39246 6.44995Z'
        stroke='#231F20'
        strokeWidth='0.326453'
        strokeMiterlimit='3.864'
      />
      <path
        d='M7.54162 6.63079C7.57513 6.63658 7.82297 6.67909 7.86459 6.68648C7.90655 6.69397 8.13707 6.73469 8.19835 6.96457C8.21324 7.02115 8.33503 7.42542 8.33503 7.42542L8.04235 7.42239C8.04235 7.42239 7.98794 7.22689 7.97628 7.1933C7.94807 7.1107 7.94642 7.0895 7.85841 7.07533C7.76952 7.06437 7.77414 7.10456 7.79416 7.17245C7.80417 7.20756 8.07047 8.16088 8.0824 8.20168C8.11365 8.31021 8.12148 8.35307 8.21524 8.33338C8.29664 8.31298 8.28114 8.26593 8.26033 8.18609C8.23051 8.08178 8.2003 7.97759 8.16971 7.87352L8.46038 7.85026C8.46038 7.85026 8.60838 8.35673 8.61621 8.38034C8.62405 8.40395 8.68594 8.60916 8.51001 8.69737C8.48728 8.70868 8.48728 8.71047 8.46404 8.71884C8.4408 8.72713 8.2054 8.79868 8.18303 8.80527C8.16066 8.81151 7.93641 8.886 7.86372 8.64702C7.85649 8.62395 7.38257 6.94408 7.35741 6.86157C7.31475 6.72203 7.36011 6.6029 7.54162 6.63079Z'
        fill='#231F20'
      />
      <path
        d='M7.54162 6.63079C7.57513 6.63658 7.82297 6.67909 7.86459 6.68648C7.90655 6.69397 8.13707 6.73469 8.19835 6.96457C8.21324 7.02115 8.33503 7.42542 8.33503 7.42542L8.04235 7.42239C8.04235 7.42239 7.98794 7.22689 7.97628 7.1933C7.94807 7.1107 7.94642 7.0895 7.85841 7.07533C7.76952 7.06437 7.77414 7.10456 7.79416 7.17245C7.80417 7.20756 8.07047 8.16088 8.0824 8.20168C8.11365 8.31021 8.12148 8.35307 8.21524 8.33338C8.29664 8.31298 8.28114 8.26593 8.26033 8.18609C8.23051 8.08178 8.2003 7.97759 8.16971 7.87352L8.46038 7.85026C8.46038 7.85026 8.60838 8.35673 8.61621 8.38034C8.62405 8.40395 8.68594 8.60916 8.51001 8.69737C8.48728 8.70868 8.48728 8.71047 8.46404 8.71884C8.4408 8.72713 8.2054 8.79868 8.18303 8.80527C8.16066 8.81151 7.93641 8.886 7.86372 8.64702C7.85649 8.62395 7.38257 6.94408 7.35741 6.86157C7.31475 6.72203 7.36011 6.6029 7.54162 6.63079Z'
        stroke='#231F20'
        strokeWidth='0.326453'
        strokeMiterlimit='3.864'
      />
      <path
        d='M6.08125 6.18531C6.22594 6.21088 8.73814 6.65328 8.84966 6.67351C8.96126 6.69373 9.14895 6.72242 9.22756 6.97494C9.24288 7.02422 9.5837 8.12776 9.59754 8.17543C9.61121 8.22328 9.71027 8.47518 9.4304 8.56927C9.3887 8.58344 7.02701 9.38065 6.96973 9.39918C6.91253 9.41816 6.68915 9.51902 6.59748 9.17036C6.59748 9.17036 5.8765 6.69748 5.84334 6.58565C5.81609 6.49512 5.79694 6.42232 5.79276 6.36574C5.77848 6.16294 5.89478 6.15626 5.99028 6.17034C6.02597 6.1756 6.0581 6.18139 6.08125 6.18531ZM6.01561 6.04729C6.07908 6.05745 8.8218 6.54734 8.89179 6.55945C8.96248 6.5722 9.23235 6.60338 9.3403 6.93681C9.36119 7.00096 9.70244 8.11173 9.71881 8.16724C9.77887 8.37262 9.75319 8.58727 9.50317 8.67762C9.42056 8.70729 7.06705 9.51591 7.03798 9.52597C6.9733 9.54825 6.6276 9.71416 6.50181 9.28174C6.50181 9.28174 5.73991 6.68919 5.71737 6.61078C5.68211 6.48924 5.66009 6.38624 5.65991 6.31023C5.65983 5.97324 5.95433 6.04043 6.01561 6.04729Z'
        fill='#231F20'
      />
    </g>
    <defs>
      <clipPath id='clip0_783_7216'>
        <rect width='60' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
