import { ArrowUpCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';

import { Button } from '@fleet/components';

export enum NotificationType {
  Success = 'Success',
  Warning = 'Warning'
}

type NotificationProps = {
  description: string;
  buttonLabel: string;
  onButtonClick: () => void;
  type?: NotificationType;
  showCloseButton?: boolean;
  onCloseClick?: () => void;
};

export const Notification: React.FC<NotificationProps> = ({
  description,
  buttonLabel,
  onButtonClick,
  type,
  showCloseButton = false,
  onCloseClick
}) => {
  let IconToRender;

  switch (type) {
    case NotificationType.Success:
      IconToRender = <ArrowUpCircleIcon className='mr-2 h-6 w-6 text-green-400' />;
      break;
    case NotificationType.Warning:
      IconToRender = <ExclamationCircleIcon className='mr-2 h-6 w-6 text-yellow-400' />;
      break;
    default:
      IconToRender = null;
      break;
  }

  return (
    <>
      <div className='mr-2 flex items-center'>
        {IconToRender && IconToRender}
        <div className='flex flex-col'>{description}</div>
      </div>
      <Button className='h-[26px]' variant='solid' onClick={onButtonClick}>
        {buttonLabel}
      </Button>
      {showCloseButton && (
        <div className='ml-2 flex cursor-pointer items-center font-bold' onClick={onCloseClick}>
          X
        </div>
      )}
    </>
  );
};
