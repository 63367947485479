const style: React.CSSProperties = { maskType: 'luminance' };

export const ContainerForkliftTopSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='282'
    height='500'
    viewBox='0 0 282 500'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_175_575)'>
      <path
        d='M192.369 217.241L199.856 208.503'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M192.169 213.58L199.856 204.799'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M192.131 209.92L199.856 201.096'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M199.856 229.294L199.856 187.568'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M189.158 205.908L199.856 193.688'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M185.928 205.894L199.856 189.984'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M192.087 206.267L199.857 197.392'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask
        id='mask0_175_575'
        style={style}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='282'
        height='501'
      >
        <path
          d='M281.113 0.000500569L281.113 500.001L-2.18557e-05 500.001L0 0.000488281L281.113 0.000500569Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_175_575)'>
        <path
          d='M199.856 187.568C199.58 187.456 199.302 187.351 199.022 187.252'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.406 187.047C197.763 186.844 197.112 186.677 196.455 186.546'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.023 187.252L198.571 187.78'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.454 186.546L195.965 187.117'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.838 202.453L199.005 187.252'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.838 198.895L196.381 186.546'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.143 224.927L199.856 215.911'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.234 228.588L199.717 219.841'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.406 220.901L199.856 212.207'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.222 231.125L199.856 223.318'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.34 230.797L199.857 226.692'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.572 230.614C198.343 230.43 199.312 230.025 199.857 229.294'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.308 231.126L193.222 231.126C194.293 231.104 195.544 231.019 196.601 230.821L197.572 230.615'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.779 186.128L185.664 195.162'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M190.479 186.337L185.664 191.738'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.856 187.568L199.023 187.252L198.406 187.047L196.455 186.546L195.768 186.422C194.984 186.296 194.698 186.152 193.907 186.128L192.993 186.128C191.884 186.15 190.431 186.349 189.336 186.547L188.421 186.756L186.053 187.957L185.664 188.051L185.664 205.952'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M186.312 402.061L186.312 390.348'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.69 401.355L186.173 392.608'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.679 403.893L186.313 396.086'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.797 403.564L186.314 399.46'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.028 403.382C184.8 403.197 185.768 402.792 186.313 402.062'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.713 403.917L179.678 403.893C180.749 403.871 182 403.786 183.058 403.588L184.028 403.382'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.061 205.894L183.019 204.799'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.818 205.894L183.019 201.096'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.02 205.908L183.02 187.568'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.321 205.908L183.019 193.688'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.091 205.893L183.019 189.984'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.576 205.894L183.019 197.392'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.019 187.568C182.743 187.456 182.465 187.351 182.186 187.252'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.568 187.047C180.925 186.845 180.274 186.678 179.617 186.546'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.186 187.253L181.734 187.78'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.617 186.546L179.128 187.117'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.001 202.453L182.168 187.252'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.001 198.895L179.544 186.546'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.942 186.128L168.827 195.163'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M173.642 186.337L168.827 191.738'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.018 187.568L182.185 187.253L181.568 187.047L179.617 186.546L178.93 186.422C178.146 186.296 177.86 186.153 177.07 186.128L176.155 186.128C175.047 186.15 173.593 186.35 172.498 186.547L171.583 186.757L169.215 187.958L168.826 188.052L168.826 205.953'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.129 402.087L129.129 152.087L151.823 152.087L151.823 402.087L129.129 402.087Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.509 152.087L131.509 132.688L149.444 132.688L149.444 152.087L131.509 152.087Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.823 299.599L129.129 299.599'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.823 315.104L142.672 349.013L138.792 349.013L129.129 315.104'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.823 284.957L142.672 251.048L138.792 251.048L129.129 284.957'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.823 322.292L151.823 264.459L174.883 288.251L174.883 312.043L151.823 322.292Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.823 306.553L151.823 293.375L174.883 293.375L174.883 306.553L151.823 306.553Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.823 390.74L151.823 358.529L163.902 363.654L163.902 385.982L151.823 390.74Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.902 381.589L163.902 368.046L166.465 368.046L166.465 381.589L163.902 381.589Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.465 376.831L166.465 372.805L167.929 372.805L167.929 376.831L166.465 376.831Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.483 389.276L155.483 415.631L125.469 415.631L125.469 389.276'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.483 411.604L125.469 411.604'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.472 340.228L181.472 243.961L186.962 243.961L186.962 340.228L181.472 340.228Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M186.962 340.229L186.962 389.277C186.962 389.277 180.008 401.034 174.883 405.587C169.759 410.141 155.483 415.631 155.483 415.631'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M186.962 383.756C186.962 383.756 178.909 398.06 172.321 403.917C165.732 409.773 155.483 413.434 155.483 413.434L125.469 413.434'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.196 351.575L167.196 321.194L181.472 321.194L181.472 351.575L167.196 351.575Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.196 315.337L167.196 365.483'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M186.963 305.455L174.884 305.455'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.472 301.429L174.884 301.429'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.981 305.455L175.981 321.194'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.857 313.874L170.857 321.195'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.472 324.123L167.196 324.123'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M186.962 321.193L180.373 321.193'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.992 318.217L160.992 334.122L151.823 334.122'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.51 318.998L159.51 334.005'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M186.962 365.484L163.902 365.484'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.472 243.961L181.472 236.787L170.857 236.787L170.857 284.097'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M186.962 243.961L192.116 243.961L192.116 231.118L192.116 205.953L167.196 205.953L167.196 212.117L170.857 212.117L170.857 236.787'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.087 231.516L161.706 231.516'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.706 274.708L161.706 231.516'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.17 231.516L163.17 218.705L169.026 218.705L169.026 231.516L163.17 231.516Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.196 218.705L167.196 231.516'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165 218.705L165 231.516'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.197 205.952L167.197 202.526L151.823 202.526'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.444 132.687L158.411 122.438L158.411 97.8994L123.067 97.8994'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.593 119.905L130.593 119.847C130.593 116.426 133.365 113.653 136.786 113.653L144.531 113.653C147.952 113.653 150.725 116.426 150.725 119.847L150.725 119.905C150.725 123.326 147.952 126.098 144.531 126.098L136.786 126.098C133.365 126.098 130.593 123.326 130.593 119.905Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.411 115.118L148.528 115.118'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.789 115.118L123.272 115.118'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.476 152.087L140.476 126.099'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.412 105.601L123.272 105.601'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.476 113.654L140.476 105.601'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.411 119.876L151.091 119.876'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.593 119.876L123.272 119.876'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.175 105.601C148.053 105.601 146.332 103.881 146.332 101.758C146.332 99.6353 148.053 97.9146 150.175 97.9146C152.298 97.9146 154.019 99.6353 154.019 101.758C154.019 103.881 152.298 105.601 150.175 105.601Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.776 105.601C128.653 105.601 126.933 103.88 126.933 101.757C126.933 99.6348 128.653 97.9141 130.776 97.9141C132.899 97.9141 134.619 99.6348 134.619 101.757C134.619 103.88 132.899 105.601 130.776 105.601Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.698 99.7441L134.253 99.7441'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.706 134.152L161.706 119.876L216.977 119.876L216.977 134.152L161.706 134.152Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M216.977 135.615L216.977 118.046L221.369 118.046L221.369 135.615L216.977 135.615Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M221.369 154.649L221.369 84.3711L227.592 84.3711L227.592 154.649L221.369 154.649Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M221.368 86.4795L218.03 86.4795L218.03 88.3682L218.821 88.3682L218.821 89.1296L220.051 89.1296L220.051 88.3975L221.368 88.3975L221.368 86.4795Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M221.368 152.82L218.03 152.82L218.03 150.931L218.821 150.931L218.821 150.169L220.051 150.169L220.051 150.901L221.368 150.901L221.368 152.82Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M223.199 96.4499L223.199 86.9331L226.494 86.9331L226.494 94.9858L223.199 96.4499Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M227.592 112.987C227.592 112.987 225.762 120.242 223.565 120.608L223.565 132.687C223.565 132.687 226.128 133.331 227.592 140.33'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M226.494 150.169L226.494 142.373C226.494 142.373 225.396 140.843 223.565 140.792L223.565 150.169L226.494 150.169Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M227.592 150.257L221.369 150.257'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M226.494 90.2275L223.199 90.2275'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M226.494 148.427L223.565 148.427'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.17 134.152L163.17 116.582C163.17 114.965 164.481 113.654 166.098 113.654C167.715 113.654 169.026 114.965 169.026 116.582L169.026 134.152L163.17 134.152Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.747 139.276L195.747 135.616L208.924 135.616L208.924 139.276L195.747 139.276Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.746 139.276L166.42 139.276L164.268 141.106L149.443 141.106L149.443 135.615L154.019 135.615L158.411 137.445L195.746 137.445L195.746 139.276Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.46 134.151L207.46 135.615'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.747 135.616L161.706 135.616'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.608 119.876L160.608 104.137L204.166 104.137L204.166 119.876L160.608 119.876Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.165 105.601L160.607 105.601'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.335 104.137L200.403 102.204L198.675 100.476L165.718 100.476L163.521 97.9141L161.34 97.8994L161.34 102.204'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M158.412 102.306L158.412 94.9858L161.34 94.9858L161.34 102.306L158.412 102.306Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M161.339 104.137L158.411 104.137'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M160.975 102.307L200.506 102.307'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.113 108.895L196.113 105.601L200.14 105.601L200.14 108.895L196.113 108.895Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.415 114.752L214.415 108.163L219.539 108.163L219.539 114.752L214.415 114.752Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.415 113.654L214.415 110.359L204.166 110.359L204.166 113.654L214.415 113.654Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M219.539 114.751L219.539 117.68'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.414 114.751L216.976 118.046'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.166 113.654L209.657 119.876'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.215 125.001L156.215 136.348'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.706 134.151L161.706 137.446'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M223.565 120.608L221.369 120.608'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M223.565 132.688L221.369 132.688'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.823 152.087L151.823 142.833L149.443 142.833'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M88.744 217.241L81.2568 208.503'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M88.9442 213.58L81.2568 204.799'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M88.9823 209.92L81.2568 201.096'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.2568 229.294L81.2568 187.568'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.9549 205.908L81.2568 193.688'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.1851 205.894L81.2568 189.984'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.0266 206.267L81.2568 197.392'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.2568 187.568C81.5328 187.456 81.8103 187.351 82.0899 187.252'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.707 187.047C83.3505 186.844 84.0013 186.677 84.658 186.546'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.0898 187.252L82.5419 187.78'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.6582 186.546L85.1476 187.117'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.2743 202.453L82.1074 187.253'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.2743 198.895L84.7314 186.546'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M88.9702 224.927L81.2568 215.911'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M88.8783 228.588L81.3955 219.841'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M88.7067 220.901L81.2568 212.207'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.8915 231.125L81.2568 223.318'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.7737 230.797L81.2568 226.692'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.5416 230.614C82.7704 230.43 81.8022 230.025 81.2568 229.294'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M88.8053 231.125L87.8909 231.125C86.8199 231.104 85.5692 231.018 84.5114 230.821L83.541 230.614'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.335 186.128L95.4495 195.162'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M90.6338 186.337L95.4486 191.738'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.2568 187.568L82.0899 187.252L82.7071 187.047L84.658 186.546L85.3451 186.422C86.1287 186.296 86.415 186.152 87.2056 186.128L88.1199 186.128C89.2287 186.15 90.6822 186.349 91.7773 186.547L92.6917 186.756L95.0599 187.957L95.449 188.051L95.449 205.952'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.8008 402.061L94.8008 390.348'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.422 401.355L94.9395 392.608'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.435 403.893L94.7998 396.086'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.3166 403.564L94.7998 399.46'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.0856 403.382C96.3143 403.197 95.3462 402.792 94.8008 402.062'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.4 403.917L101.435 403.893C100.364 403.871 99.1131 403.786 98.0553 403.588L97.085 403.382'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.052 205.894L98.0938 204.799'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.295 205.894L98.0947 201.096'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.0937 205.908L98.0938 187.568'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.792 205.908L98.0938 193.688'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.022 205.893L98.0938 189.984'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.537 205.894L98.0938 197.392'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.0937 187.568C98.3697 187.456 98.6472 187.351 98.9268 187.252'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.5449 187.047C100.188 186.844 100.839 186.677 101.496 186.546'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.9277 187.252L99.3798 187.78'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.495 186.546L101.985 187.117'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.112 202.453L98.9453 187.252'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.113 198.895L101.57 186.546'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.173 186.128L112.287 195.163'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.472 186.337L112.286 191.738'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.0937 187.568L98.9268 187.253L99.544 187.047L101.495 186.546L102.182 186.422C102.966 186.296 103.252 186.153 104.043 186.128L104.957 186.128C106.066 186.15 107.519 186.35 108.614 186.547L109.529 186.757L111.897 187.958L112.286 188.052L112.286 205.953'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.29 299.598L151.823 299.598'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.128 322.292L129.128 264.459L106.229 288.251L106.229 312.043L129.128 322.292Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.129 306.553L129.129 293.375L106.23 293.375L106.23 306.553L129.129 306.553Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.129 390.74L129.129 358.529L117.211 363.654L117.211 385.982L129.129 390.74Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.212 381.589L117.212 368.046L114.649 368.046L114.649 381.589L117.212 381.589Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.649 376.831L114.649 372.805L113.185 372.805L113.185 376.831L114.649 376.831Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.63 411.604L155.483 411.604'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.6419 340.228L99.6419 243.961L94.1514 243.961L94.1514 340.228L99.6419 340.228Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.1504 340.228L94.1504 389.276C94.1504 389.276 101.105 401.033 106.229 405.586C111.354 410.14 125.629 415.63 125.629 415.63'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.1504 383.756C94.1504 383.756 102.203 398.06 108.792 403.917C115.38 409.773 125.629 413.434 125.629 413.434L155.644 413.434'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.917 351.575L113.917 321.194L99.6416 321.194L99.6416 351.575L113.917 351.575Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.917 315.337L113.917 365.483'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.1504 305.455L106.229 305.455'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.6416 301.429L106.23 301.429'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.132 305.455L105.132 321.194'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.256 313.874L110.256 321.195'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.6416 324.122L113.917 324.122'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.1504 321.193L100.739 321.193'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.121 318.217L120.121 334.005L129.128 334.005'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.604 318.998L121.604 334.005'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.1504 365.483L117.21 365.483'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.6416 243.961L99.6416 236.787L110.257 236.787L110.257 284.097'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.1508 243.961L88.9971 243.961L88.9971 231.118L88.9971 205.953L113.917 205.953L113.917 212.117L110.256 212.117L110.256 236.787'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.0264 231.516L119.407 231.516'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.407 274.708L119.407 231.516'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.943 231.516L117.943 218.705L112.087 218.705L112.087 231.516L117.943 231.516Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.917 218.705L113.917 231.516'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.112 218.705L116.112 231.516'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.917 205.952L113.917 202.6L129.129 202.6'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.67 132.688L122.702 122.439L122.702 97.8999L158.046 97.8999'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.702 115.118L132.585 115.118'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.528 115.118L157.84 115.118'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.702 105.601L157.841 105.601'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.702 119.876L130.023 119.876'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.091 119.876L157.84 119.876'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.414 99.7441L146.859 99.7441'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.408 104.869L119.408 119.144L64.1367 119.144L64.1367 104.869L119.408 104.869Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M64.1365 103.405L64.1365 120.974L59.7441 120.974L59.7441 103.405L64.1365 103.405Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M59.744 84.3711L59.744 154.649L53.5215 154.649L53.5215 84.3711L59.744 84.3711Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M59.7441 152.541L63.0824 152.541L63.0824 150.652L62.2917 150.652L62.2917 149.891L61.0619 149.891L61.0619 150.623L59.7441 150.623L59.7441 152.541Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M59.7441 86.2012L63.0824 86.2012L63.0824 88.0899L62.2917 88.0899L62.2917 88.8512L61.0619 88.8512L61.0619 88.1192L59.7441 88.1192L59.7441 86.2012Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M57.9144 142.57L57.9144 152.087L54.6201 152.087L54.6201 144.034L57.9144 142.57Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M53.5215 126.033C53.5215 126.033 55.3516 118.778 57.5478 118.412L57.5478 106.333C57.5478 106.333 54.9856 105.689 53.5215 98.6904'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M54.6201 88.8511L54.6201 96.6476C54.6201 96.6476 55.7182 98.1776 57.5484 98.2288L57.5484 88.8511L54.6201 88.8511Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M53.5215 88.7637L59.744 88.7637'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M54.6201 148.792L57.9144 148.792'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M54.6201 90.5933L57.5484 90.5933'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.942 104.869L117.942 122.439C117.942 124.056 116.631 125.367 115.014 125.367C113.397 125.367 112.086 124.056 112.086 122.439L112.086 104.869L117.942 104.869Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85.3666 99.7446L85.3666 103.405L72.1895 103.405L72.1895 99.7446L85.3666 99.7446Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.702 101.574L85.3662 101.574L85.3662 99.7442L114.693 99.7442L116.845 97.9141L131.303 97.9141'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.6533 104.869L73.6533 103.405'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85.3662 103.405L122.54 103.405'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.505 119.144L120.505 134.883L76.9473 134.883L76.9473 119.144L120.505 119.144Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.9473 133.419L120.505 133.419'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.773 134.883L131.325 134.883'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M131.325 136.713L80.6074 136.713'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85 130.125L85 133.419L80.9736 133.419L80.9736 130.125L85 130.125Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M66.6987 124.269L66.6987 130.857L61.5742 130.857L61.5742 124.269L66.6987 124.269Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M66.6982 125.367L66.6982 128.661L76.9472 128.661L76.9472 125.367L66.6982 125.367Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M61.5742 124.269L61.5742 121.34'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M66.698 124.268L64.1357 120.974'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.9475 125.367L71.457 119.144'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.898 125.001L124.898 136.348'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.407 104.869L119.407 101.575'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M57.5479 118.412L59.744 118.412'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M57.5479 106.333L59.744 106.333'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.129 152.087L129.129 142.834L131.508 142.834'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.326 141.106L117.783 141.106L117.592 141.106L115.396 138.544L82.4386 138.544L80.711 136.816L78.7783 134.883'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.711 141.106L120.711 136.714L122.907 136.714L122.907 141.106L120.711 141.106Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_175_575'>
        <rect width='500' height='281.113' fill='white' transform='translate(281.113) rotate(90)' />
      </clipPath>
    </defs>
  </svg>
);
