import { useEffect, useRef } from 'react';

export const useIntersectionObserver = (
  callback?: () => void,
  options?: IntersectionObserverInit
) => {
  options = { threshold: 0.1, ...options };
  const containerRef = useRef(null);

  useEffect(() => {
    if (!callback) return;

    const containerElement = containerRef.current;
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) callback();
      },
      { ...options }
    );

    if (containerElement) observer.observe(containerElement);

    return () => {
      if (containerElement) observer.unobserve(containerElement);
    };
  }, [callback, containerRef, options]);

  return containerRef;
};
