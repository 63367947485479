export const FiltersIconSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    stroke='black'
    {...props}
  >
    <path
      d='M3.9585 6.66602H6.04183'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.625 6.66602H16.0417'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.9585 13.334H10.2085'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.7915 13.334H16.0415'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.2085 6.66602C10.2085 7.70155 9.36903 8.54102 8.3335 8.54102C7.29796 8.54102 6.4585 7.70155 6.4585 6.66602C6.4585 5.63048 7.29796 4.79102 8.3335 4.79102C9.36903 4.79102 10.2085 5.63048 10.2085 6.66602Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.375 13.334C14.375 14.3695 13.5355 15.209 12.5 15.209C11.4645 15.209 10.625 14.3695 10.625 13.334C10.625 12.2985 11.4645 11.459 12.5 11.459C13.5355 11.459 14.375 12.2985 14.375 13.334Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
