const style: React.CSSProperties = { maskType: 'luminance' };

export const MoxyTopSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='282'
    height='500'
    viewBox='0 0 282 500'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_173_3418)'>
      <path
        d='M86.3828 468.155L86.3828 237.555L215.958 237.555L215.958 468.155L86.3828 468.155Z'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M215.958 463.031L215.958 237.555L221.082 237.555L221.082 463.031L215.958 463.031Z'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask
        id='mask0_173_3418'
        style={style}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='282'
        height='501'
      >
        <path
          d='M281.113 0.00098885L281.113 500.001L-2.18557e-05 500.001L0 0.000976562L281.113 0.00098885Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_173_3418)'>
        <path
          d='M221.083 456.442L215.958 456.442C215.958 456.442 210.805 466.325 200.57 468.155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.3676 463.031L86.3676 237.556L81.2432 237.556L81.2432 463.031L86.3676 463.031Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.2432 456.442L86.3676 456.442C86.3676 456.442 91.5213 466.325 101.756 468.155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.849 369.693L130.849 260.982L171.478 260.982L171.478 369.693L130.849 369.693Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M171.302 369.693L200.584 468.156'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.672 369.693L101.756 468.156'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.551 404.101L120.79 404.101'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.672 260.982L125.182 251.099L177.158 251.099L171.477 260.982L130.672 260.982Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M215.958 242.314L86.3828 242.314'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M215.958 251.099L171.302 260.982'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M215.958 242.314L177.158 251.099'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.182 251.099L86.3828 242.314'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.673 260.982L86.3828 251.099'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.371 237.556L112.371 216.326L189.238 216.326L189.238 237.556L112.371 237.556Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M221.083 237.556L189.238 216.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.2578 237.556L112.371 216.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.96 176.428L118.96 116.765L182.65 116.765L182.65 176.428L118.96 176.428Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.548 176.428L125.548 122.988L176.792 122.988L176.792 176.428L125.548 176.428Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.673 208.639L130.673 183.017L171.302 183.017L171.302 208.639L130.673 208.639Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.804 183.017L150.804 208.639'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M171.302 183.017L156.661 208.639C156.661 208.639 156.412 211.202 161.478 212.666'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.092 216.326L139.092 212.666L162.884 212.666L162.884 216.326L139.092 216.326Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.876 212.666L147.876 208.639L153.732 208.639L153.732 212.666L147.876 212.666Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.671 183.017L145.312 208.639C145.312 208.639 145.561 211.202 140.495 212.666'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.661 208.639L156.661 212.299'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.314 208.639L145.314 212.299'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.804 212.666L150.804 216.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.959 43.8444L118.959 101.758L182.649 101.758L182.649 43.8444C182.649 39.0373 178.751 35.1401 173.944 35.1401L127.663 35.1401C122.856 35.1401 118.959 39.0373 118.959 43.8444Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.234 101.758L135.826 35.1401L164.318 35.1401L166.909 101.758L133.234 101.758Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.649 164.716L182.649 110.177C189.725 110.177 195.461 115.912 195.461 122.988L195.461 164.716L182.649 164.716Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.46 151.904L195.46 134.334L203.147 134.334L203.147 151.904L195.46 151.904Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.559 173.866L200.585 173.866'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.611 173.866L214.494 173.866L214.494 192.168L182.649 192.168L182.649 173.866L192.532 173.866'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.585 192.168L200.585 173.866L200.585 170.572L204.611 170.572L204.611 173.866L204.611 192.168L200.585 192.168Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.532 192.168L192.532 170.572L196.559 170.572L196.559 192.168L192.532 192.168Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M211.932 192.168L211.932 173.866L214.494 173.866L214.494 192.168L211.932 192.168Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.649 192.168L182.649 173.866L185.212 173.866L185.212 192.168L182.649 192.168Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M211.932 179.356L204.611 179.356'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.533 183.749L185.212 183.749'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.996 164.715L214.494 164.715'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.254 35.14L122.254 27.8193L179.355 27.8193L179.355 35.14L122.254 35.14Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.495 95.9018L204.246 44.1156C204.246 44.1156 203.148 39.5329 202.049 39.5329L179.355 27.8198'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.925 70.645C192.477 70.645 188.872 67.0395 188.872 62.5923C188.872 58.145 192.477 54.5396 196.925 54.5396C201.372 54.5396 204.977 58.145 204.977 62.5923C204.977 67.0395 201.372 70.645 196.925 70.645Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M206.99 83.8221C204.665 83.8221 202.78 81.9374 202.78 79.6127C202.78 77.288 204.665 75.4033 206.99 75.4033C209.314 75.4033 211.199 77.288 211.199 79.6127C211.199 81.9374 209.314 83.8221 206.99 83.8221Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.63 116.033L193.63 95.9014L207.905 95.9014L207.905 116.033L193.63 116.033Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.906 105.784L193.631 105.784'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.905 118.961L207.905 95.9014L214.494 95.9014L214.494 118.961L207.905 118.961Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.956 54.7983L198.022 50.5136C198.022 50.5136 196.192 45.0231 191.104 44.291'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.769 76.9047L202.012 68.8345'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.905 95.9016L205.187 83.4155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.891 73.7856C202.891 73.7856 201.317 76.2091 196.925 76.9046L182.649 76.9046'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.649 110.909L182.649 101.758'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.914 97.3655L125.914 92.9731L130.306 92.9731L130.306 97.3655L125.914 97.3655Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.833 87.4824C186.775 87.4824 189.971 90.6779 189.971 94.62C189.971 98.5622 186.775 101.758 182.833 101.758'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M171.302 204.247L171.302 176.428L179.354 176.428L179.354 195.096L171.302 204.247Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.718 183.017L123.718 180.089L171.302 180.089L171.302 183.017L123.718 183.017Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.024 186.677L101.024 163.617L118.96 163.617L118.96 186.677L101.024 186.677Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.718 195.828L123.718 184.847L130.672 184.847L130.672 195.828L123.718 195.828Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.1143 192.534L87.1143 163.617L93.3368 163.617L93.3368 186.677L87.1143 192.534Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.024 163.617L93.3369 163.617'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.024 186.677L93.3369 178.991'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.024 181.919L93.3369 174.232'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.024 177.16L93.3369 169.474'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.717 192.534L87.1143 192.534'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.05 152.27L105.05 132.504L118.959 132.504L118.959 152.27L105.05 152.27Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.759 44.2909L197.759 39.1665C197.759 39.1665 193.264 35.1401 182.649 35.1401L182.649 44.2909L204.245 44.2909'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.1143 95.9018L97.3632 44.1156C97.3632 44.1156 98.4613 39.5329 99.5593 39.5329L122.253 27.8198'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.85 44.2909L103.85 39.1665C103.85 39.1665 108.345 35.1401 118.96 35.1401L118.96 44.2909L97.3643 44.2909'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M90.7803 77.8419L96.9197 46.7603L117.841 51.1644L111.702 82.246L90.7803 77.8419Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.5332 116.399L95.5332 97.3657L114.567 97.3657L114.567 116.399L95.5332 116.399Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.1152 118.961L87.1152 95.9014L95.534 95.9014L95.534 118.961L87.1152 118.961Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.568 105.418L95.5342 105.418'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.3635 79.2275C97.3635 79.2275 95.1673 80.8941 95.5333 95.9014'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.8733 78.3315C91.8733 78.3315 89.6771 80.0874 90.0431 95.9011'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.567 110.543L114.567 92.2412L118.96 88.2148L118.96 110.543L114.567 110.543Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.472 35.1398L169.472 31.4795L175.328 31.4795L175.328 35.1398L169.472 35.1398Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.28 35.1398L126.28 31.4795L132.137 31.4795L132.137 35.1398L126.28 35.1398Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.472 31.4795L132.137 31.4795'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.355 36.9702L179.355 101.758'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.254 36.9702L122.254 101.758'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M90.043 95.9014L90.043 118.961'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.595 156.716L220.318 140.191'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.595 149.196L220.249 132.871'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.595 141.676L220.307 125.184'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M220.351 182.089L220.351 101.75'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.595 126.636L220.35 110.02'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.595 119.116L220.35 102.5'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.595 134.156L220.35 117.539'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M219.89 98.9228C219.664 98.6958 219.436 98.4824 219.206 98.2822'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M218.701 97.865C218.173 97.4543 217.638 97.115 217.1 96.8481'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M220.35 101.75L219.89 98.9229'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M219.207 98.2822L218.836 99.3529'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M219.889 98.9228L219.206 98.2823L218.699 97.8646L217.098 96.8478L216.534 96.596C215.891 96.3394 215.244 96.186 214.595 96.1362'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M217.099 96.8477L216.697 98.0069'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.595 111.596L219.206 98.2822'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.595 104.076L217.098 96.8477'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.595 179.276L220.35 162.659'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.595 171.756L220.329 155.198'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.595 164.236L220.35 147.619'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.51 187.044L220.341 170.206'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M218.015 186.322L220.3 179.723'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M218.016 186.322C218.648 185.947 219.274 185.478 219.89 184.916'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M218.625 184.563L218.016 186.322'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M219.889 184.916L218.015 186.322L217.218 186.741C216.35 187.142 215.474 187.366 214.595 187.409'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M219.891 184.916L220.351 182.089'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.494 96.2676L214.494 187.41'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.0143 156.716L81.291 140.191'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.0135 149.196L81.3594 132.871'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.0137 141.676L81.3018 125.184'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.2578 182.089L81.2578 101.75'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.0129 126.636L81.2578 110.02'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.0129 119.116L81.2578 102.5'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.0129 134.156L81.2578 117.539'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.7187 98.9228C81.945 98.6958 82.173 98.4824 82.4025 98.2822'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.9082 97.865C83.436 97.4543 83.9704 97.115 84.5092 96.8481'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.2578 101.75L81.7183 98.9229'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.4023 98.2827L82.7731 99.3534'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.7187 98.9228L82.4025 98.2823L82.9087 97.8646L84.5101 96.8478L85.0738 96.596C85.7169 96.3394 86.3644 96.186 87.0134 96.1362'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.5107 96.8481L84.9123 98.0074'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.0136 111.596L82.4023 98.2827'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.0134 104.076L84.5098 96.8477'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.0129 179.276L81.2578 162.659'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.0129 171.756L81.2783 155.198'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.0129 164.236L81.2578 147.619'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.0992 187.043L81.2676 170.206'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.5931 186.322L81.3076 179.723'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.5936 186.322C82.9607 185.947 82.3348 185.478 81.7188 184.916'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.9844 184.562L83.5938 186.322'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.7188 184.916L83.5936 186.322L84.3901 186.741C85.2579 187.142 86.1346 187.366 87.0134 187.409'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.7193 184.916L81.2588 182.089'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.1143 96.2676L87.1143 187.41'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_173_3418'>
        <rect width='500' height='281.113' fill='white' transform='translate(281.112) rotate(90)' />
      </clipPath>
    </defs>
  </svg>
);
