const style: React.CSSProperties = { maskType: 'luminance' };

export const CompactorTopSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='282'
    height='500'
    viewBox='0 0 282 500'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_168_575)'>
      <path
        d='M82.4951 317.466L82.4951 217.699L196.982 217.699L196.982 317.466L82.4951 317.466Z'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M194.529 217.699L194.529 317.466'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M84.9482 217.699L84.9482 317.466'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M196.312 217.699L195.756 209.521L182.672 206.659L167.952 209.521L166.726 217.699L196.312 217.699Z'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M112.9 217.699L112.344 209.521L99.26 206.659L84.5401 209.521L83.3135 217.699L112.9 217.699Z'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask
        id='mask0_168_575'
        style={style}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='282'
        height='501'
      >
        <path
          d='M281.113 0.000500569L281.113 500.001L-2.18557e-05 500.001L0 0.000488281L281.113 0.000500569Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_168_575)'>
        <path
          d='M166.725 215.065C166.725 215.065 140.493 208.752 112.721 215.065'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.877 211.974L136.877 204.206L146.281 204.206L146.281 211.974'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.951 209.521L146.28 209.521'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.877 209.521L112.344 209.521'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.982 250.197L204.462 250.197L204.462 274.632L202.119 276.103L196.982 276.103L196.982 250.197Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.298 250.409L202.298 275.76'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.437 250.409L199.437 275.76'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.206 250.196L75.1357 250.196L75.1357 274.631L77.0695 276.103L82.206 276.103'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.8906 250.409L76.8906 275.76'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.752 250.409L79.752 275.76'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M217.427 95.4424C217.427 95.4424 221.516 99.9402 221.516 123.655'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M217.427 199.298C217.427 199.298 221.516 194.801 221.516 171.085'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M221.516 123.655L221.516 171.086'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M64.505 95.4424C64.505 95.4424 60.416 99.9402 60.416 123.655'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M64.505 199.298C64.505 199.298 60.416 194.801 60.416 171.085'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M60.415 123.655L60.415 171.086'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M217.426 95.4424L64.5039 95.4424'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M217.426 199.299L64.5039 199.299'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.292 68.0474L223.151 68.0474L223.151 85.6294L57.9619 85.6294L57.9619 68.0474L76.7705 68.0474'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.9824 68.0474L183.08 68.0474'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.982 217.699L219.078 217.699L219.471 217.699L223.151 215.654L223.151 199.299L212.013 199.299L212.013 217.699'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M223.151 221.378L223.151 63.1406L225.196 63.1406L225.196 221.378L223.151 221.378Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M223.151 63.1406L218.244 68.0473'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.081 68.3456L183.081 66.2515C183.081 65.4367 183.741 64.7764 184.556 64.7764L200.823 64.7764C201.638 64.7764 202.298 65.4367 202.298 66.2515L202.298 68.3456C202.298 70.8906 200.235 72.9539 197.69 72.9539L187.689 72.9539C185.144 72.9539 183.081 70.8906 183.081 68.3456Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.7715 68.3456L76.7715 66.2515C76.7715 65.4367 77.4318 64.7764 78.2466 64.7764L94.5138 64.7764C95.3286 64.7764 95.9889 65.4367 95.9889 66.2515L95.9889 68.3456C95.9889 70.8906 93.9256 72.9539 91.3806 72.9539L81.3798 72.9539C78.8348 72.9539 76.7715 70.8906 76.7715 68.3456Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M211.702 95.4423L211.702 72.9536L214.564 72.9536L214.564 95.4423L211.702 95.4423Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.686 95.4423L155.686 72.9536L158.548 72.9536L158.548 95.4423L155.686 95.4423Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.61 95.4423L124.61 72.9536L127.472 72.9536L127.472 95.4423L124.61 95.4423Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M67.3672 95.4423L67.3672 72.9536L70.2292 72.9536L70.2292 95.4423L67.3672 95.4423Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.1313 217.699L62.0354 217.699L61.6426 217.699L57.9629 215.654L57.9629 199.299L69.1009 199.299L69.1009 217.699'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M57.9623 221.378L57.9623 63.1406L55.918 63.1406L55.918 221.378L57.9623 221.378Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M57.9629 63.1406L62.8696 68.0473'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M211.702 204.205L69.4102 204.205'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M57.9629 221.378L82.4958 245.911'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M223.151 221.378L196.982 247.547'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.9492 317.875L87.6315 411.918L89.7669 425.002L99.5909 436.86L137.177 436.86L142.302 436.86L179.889 436.86L189.712 425.002L191.848 411.918L194.53 317.875'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.761 417.642C135.454 417.642 131.152 413.34 131.152 408.034C131.152 402.727 135.454 398.425 140.761 398.425C146.068 398.425 150.37 402.727 150.37 408.034C150.37 413.34 146.068 417.642 140.761 417.642Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.762 412.327C138.391 412.327 136.469 410.405 136.469 408.033C136.469 405.662 138.391 403.74 140.762 403.74C143.133 403.74 145.055 405.662 145.055 408.033C145.055 410.405 143.133 412.327 140.762 412.327Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.743 429.5L130.743 434.407L149.552 434.407L149.552 429.5L130.743 429.5Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.108 420.913L129.108 429.499L152.006 429.499L152.006 420.913L129.108 420.913Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.006 427.455L129.108 427.455'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.006 425.206L129.108 425.206'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.006 422.958L129.108 422.958'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.139 425.82L158.139 420.913L176.947 420.913L176.947 425.82L158.139 425.82Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.503 434.407L156.503 425.82L179.4 425.82L179.4 434.407L156.503 434.407Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.4 427.864L156.503 427.864'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.4 430.113L156.503 430.113'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.4 432.362L156.503 432.362'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.531 425.82L102.531 420.913L121.34 420.913L121.34 425.82L102.531 425.82Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.895 434.407L100.895 425.82L123.792 425.82L123.792 434.407L100.895 434.407Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.793 427.864L100.896 427.864'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.793 430.113L100.896 430.113'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.793 432.362L100.896 432.362'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.72 384.298L210.819 383.188'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 362.436L215.939 344.19'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 368.886L215.939 350.641'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 371.467L215.939 353.222'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 377.917L215.939 359.672'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 380.499L215.939 362.253'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.377 383.147L215.94 368.703'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.145 382.779L215.938 371.283'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 323.731L215.939 305.485'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 326.312L215.939 308.066'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 332.762L215.939 314.517'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 335.343L215.939 317.098'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 341.793L215.939 323.548'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 317.28L211.474 303.79'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M211.475 303.79L209.115 306.303'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 359.855L215.939 341.609'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 344.374L215.939 326.128'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 350.825L215.939 332.579'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 353.405L215.939 335.159'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 362.436L198.807 368.887'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 362.436L198.807 368.887'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 362.436L198.807 368.887'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 362.436L198.807 368.887'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 371.467L193.405 365.714'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 368.887L193.302 363.024'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.032 304.498L197.032 302.337'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.141 302.568L202.717 304.085'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.717 304.085L200.926 303.413'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 302.678L198.807 305.669'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 308.249L204.14 302.568'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 314.7L209.461 303.353'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 305.668L201.881 302.395'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 305.669L197.031 303.778'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M206.987 305.988L209.462 303.353'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M209.462 303.352L204.141 302.568'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.926 303.413L201.881 302.395'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.882 302.395L197.032 302.337'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 335.343L194.308 330.551'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 326.312L198.807 332.762'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 317.28L198.807 323.731'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 308.25L198.807 314.7'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.799 316.225L199.799 313.645'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.798 325.256L199.798 322.676'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.798 334.287L199.798 331.707'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 308.25L198.807 314.7'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 317.28L198.807 323.731'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 326.312L198.807 332.762'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 326.312L198.807 332.762'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 317.28L198.807 323.731'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 308.25L198.807 314.7'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.816 334.287L197.816 331.707'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.816 325.256L197.816 322.676'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.816 316.225L197.816 313.645'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 308.25L198.807 314.7'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 317.28L198.807 323.731'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 326.312L198.807 332.762'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 332.762L194.427 328.097'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 326.311L194.529 321.755'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 323.731L194.529 319.175'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.808 317.28L197.062 315.421'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 314.7L196.982 312.757'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M209.114 306.303L206.986 305.988'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.798 307.193L199.798 304.613'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.816 307.193L197.816 304.613'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 308.249L197.096 306.426'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 353.404L198.807 359.855'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 353.404L198.807 359.855'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 353.404L198.807 359.855'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 353.404L198.807 359.855'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 362.436L193.438 356.719'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 359.856L193.559 354.266'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 344.374L198.807 350.825'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.798 352.35L199.798 349.77'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 344.374L198.807 350.825'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 344.374L198.807 350.825'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.816 352.35L197.816 349.77'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 344.374L198.807 350.825'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 353.404L193.712 347.978'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.808 350.824L193.977 345.679'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.798 361.381L199.798 358.801'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.816 361.381L197.816 358.801'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 335.343L198.807 341.794'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.798 343.318L199.798 340.738'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 335.343L198.807 341.794'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 335.343L198.807 341.794'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.816 343.318L197.816 340.738'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 335.343L198.807 341.794'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.808 341.793L194.01 336.684'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.808 344.373L194.121 339.382'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.146 382.779L202.377 383.148'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.694 384.177L193.895 384.298'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.895 384.298L192.694 382.809'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.694 382.81L195.238 383.148'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.237 383.147L196.478 384.469'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.479 384.47L201.136 384.47'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.136 384.469L202.377 383.147'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.377 383.149L205.146 382.78'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.145 382.779L203.719 384.298'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 371.467L198.807 377.918'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.799 379.442L199.799 376.862'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.798 370.412L199.798 367.832'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 371.467L198.807 377.918'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 371.467L198.807 377.918'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.816 370.411L197.816 367.831'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.816 379.442L197.816 376.862'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 371.467L198.807 377.918'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 380.498L192.988 374.301'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 377.918L193.302 372.056'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 380.499L198.807 384.52'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 380.499L198.807 384.52'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 380.499L198.807 384.52'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.807 380.499L198.807 384.52'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.238 383.148L192.894 380.651'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M215.938 380.315L215.938 305.167'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M217.018 379.166L217.018 305.41'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M217.017 305.41L211.474 303.79'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M210.818 383.189L211.655 382.298'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.407 381.946L217.018 379.166'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M211.654 382.299L215.939 377.735'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M211.654 382.298L214.407 381.946'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.712 326.889L183.797 328.915L168.039 326.053L166.726 317.875'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.901 317.875L112.244 326.053L95.9885 328.915L85.3574 327.033'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.725 320.509C166.725 320.509 140.493 326.822 112.721 320.509'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.952 326.053L145.872 326.053'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.059 326.053L112.344 326.053'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.464 326.053L145.464 317.467'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.06 317.467L136.06 326.053'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.464 325.848C145.464 328.445 143.359 330.55 140.762 330.55C138.165 330.55 136.06 328.445 136.06 325.848'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.894 390.656L194.644 390.656C194.644 390.656 196.983 393.518 196.983 397.198C196.983 397.198 198.03 406.177 194.644 411.501L192.088 411.501'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.123 411.501L192.076 424.593L182.672 436.042L180.867 436.042'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.5679 390.656L84.8179 390.656C84.8179 390.656 82.4789 393.518 82.4789 397.198C82.4789 397.198 81.4324 406.177 84.8179 411.501L87.3742 411.501'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.3389 411.501L87.3857 424.593L96.7898 436.042L98.5947 436.042'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.898 328.915L183.898 420.914'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.9893 420.913L183.899 420.913'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.9883 328.915L95.9883 420.914'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.13 327.688L176.13 392.291L104.984 392.291L104.984 327.688'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M223.15 70.9097L201.479 70.9097'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.307 70.9097L95.1699 70.9097'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.5885 70.9097L58.3711 70.9097'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.649 384.298L68.6084 383.188'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 362.436L63.5303 344.19'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 368.886L63.5303 350.641'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 371.467L63.5303 353.222'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 377.917L63.5303 359.672'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 380.499L63.5303 362.253'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.9816 383.147L63.5303 368.703'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M74.2356 382.779L63.5303 371.283'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 323.731L63.5303 305.485'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 326.312L63.5303 308.066'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 332.762L63.5303 314.517'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 335.343L63.5303 317.098'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 341.793L63.5303 323.548'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5213 317.28L67.958 303.79'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M67.958 303.79L70.2984 306.303'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 359.855L63.5303 341.609'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 344.374L63.5303 326.128'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 350.825L63.5303 332.579'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5219 353.405L63.5303 335.159'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 362.436L80.5215 368.887'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 362.436L80.5215 368.887'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 362.436L80.5215 368.887'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 362.436L80.5215 368.887'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 371.466L86.2598 365.305'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 368.886L86.1415 362.852'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.2822 304.498L82.2822 302.337'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.2314 302.568L76.644 304.085'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.6445 304.085L78.4209 303.413'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 302.678L80.5215 305.669'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5213 308.249L75.2314 302.568'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 314.7L69.9541 303.353'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5213 305.669L77.4727 302.395'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 305.669L82.2825 303.778'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M72.4083 305.988L69.9541 303.353'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M69.9541 303.352L75.2312 302.568'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.4203 303.413L77.4727 302.395'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4717 302.395L82.2813 302.337'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 335.343L84.9842 330.551'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 326.312L80.5215 332.762'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 317.28L80.5215 323.731'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 308.25L80.5215 314.7'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5391 316.225L79.5391 313.645'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5391 325.256L79.5391 322.676'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5391 334.287L79.5391 331.707'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 308.25L80.5215 314.7'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 317.28L80.5215 323.731'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 326.312L80.5215 332.762'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 326.312L80.5215 332.762'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 317.28L80.5215 323.731'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 308.25L80.5215 314.7'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.5039 334.287L81.5039 331.707'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.5039 325.256L81.5039 322.676'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.5049 316.225L81.5049 313.645'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 308.25L80.5215 314.7'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 317.28L80.5215 323.731'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 326.312L80.5215 332.762'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 332.762L85.1697 327.771'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 326.311L84.9508 321.555'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 323.731L84.7642 319.175'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 317.28L82.2525 315.421'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 314.699L82.3311 312.756'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M70.2979 306.303L72.4077 305.988'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5391 307.193L79.5391 304.613'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.5039 307.193L81.5039 304.613'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 308.249L82.2191 306.426'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 353.405L80.5215 359.856'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 353.405L80.5215 359.856'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 353.405L80.5215 359.856'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 353.405L80.5215 359.856'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 362.436L85.8458 356.719'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 359.856L85.7272 354.266'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 344.374L80.5215 350.825'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5391 352.35L79.5391 349.77'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 344.374L80.5215 350.825'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 344.374L80.5215 350.825'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.5039 352.35L81.5039 349.77'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 344.374L80.5215 350.825'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 353.404L85.5753 347.978'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 350.824L85.6939 345.27'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5391 361.38L79.5391 358.8'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.5039 361.381L81.5039 358.801'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 335.343L80.5215 341.794'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5391 343.318L79.5391 340.738'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 335.343L80.5215 341.794'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 335.343L80.5215 341.794'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.5039 343.318L81.5039 340.738'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 335.343L80.5215 341.794'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 341.793L85.2799 336.684'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 344.374L85.3985 339.137'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M74.2354 382.779L76.9813 383.148'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.5846 384.177L85.3936 384.298'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85.3936 384.298L86.5846 382.809'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.5842 382.81L84.0615 383.148'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.0607 383.148L82.8301 384.47'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.8309 384.47L78.2119 384.47'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.2111 384.47L76.9805 383.148'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.9813 383.149L74.2354 382.78'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M74.2354 382.779L75.6497 384.298'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 371.468L80.5215 377.919'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5391 379.442L79.5391 376.862'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5391 370.411L79.5391 367.831'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 371.468L80.5215 377.919'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 371.468L80.5215 377.919'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.5049 370.411L81.5049 367.831'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.5039 379.442L81.5039 376.862'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 371.468L80.5215 377.919'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 380.498L86.5844 373.987'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 377.918L86.3864 371.62'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 380.499L80.5215 384.52'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 380.499L80.5215 384.52'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 380.499L80.5215 384.52'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5215 380.499L80.5215 384.52'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.0605 383.147L86.5832 380.438'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M63.5303 380.315L63.5303 305.167'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M62.46 379.166L62.46 305.41'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M62.46 305.41L67.9581 303.79'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M68.6097 383.189L67.7803 382.298'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M65.0493 381.946L62.46 379.166'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M67.7799 382.299L63.5303 377.735'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M67.78 382.298L65.0498 381.946'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_168_575'>
        <rect width='500' height='281.113' fill='white' transform='translate(281.113) rotate(90)' />
      </clipPath>
    </defs>
  </svg>
);
