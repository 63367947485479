import type { SWRMutationConfig } from './api/useApiHooks';
import { useMutationApi } from './api/useApiHooks';

interface SageChatRequest {
  promptText: string;
  videoDescription: string;
  orgGrounding: string[];
}

export interface SageChatResponse {
  status: number;
  data: {
    response: string;
  };
}

export const useSageChat = (swrConfig?: SWRMutationConfig<SageChatResponse>) =>
  useMutationApi<SageChatRequest, SageChatResponse>('post', '/sage/chat', undefined, swrConfig);
