import React, { useMemo } from 'react';
import type { ControllerRenderProps } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { SelectOption } from '../../atoms';
import { Select } from '../../atoms';

interface ControlledSelectProps<ValueType = string> {
  name: string;
  label: string;
  options: SelectOption<ValueType>[];
  defaultValue?: ValueType;
  disabled?: boolean;
  control?: any;
}

export const ControlledSelect = <ValueType extends string>({
  name,
  label,
  options,
  defaultValue,
  disabled,
  control
}: ControlledSelectProps<ValueType>) => {
  const render = useMemo(
    () =>
      ({ field }: { field: ControllerRenderProps }) => (
        <Select label={label} options={options} disabled={disabled} {...field} />
      ),
    [label, options, disabled]
  );

  return <Controller name={name} control={control} defaultValue={defaultValue} render={render} />;
};
