import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { InputLabel } from '../InputLabel/InputLabel';

const getNodeText = (node: string | React.ReactNode): string => {
  if (node == null) return '';

  switch (typeof node) {
    case 'string':
    case 'number':
      return node.toString();

    case 'boolean':
      return '';

    case 'object': {
      if (Array.isArray(node)) return node.map(element => getNodeText(element)).join('');

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if ('props' in node) return getNodeText(node.props.children);

      console.warn('Unresolved `node` of type:', typeof node, node);
      return '';
    }

    default:
      console.warn('Unresolved `node` of type:', typeof node, node);
      return '';
  }
};

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string | React.ReactNode;
  error?: string;
  dataPw?: string;
};

export const Input: React.FC<InputProps> = ({
  className,
  label,
  type,
  error,
  value,
  dataPw,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  return (
    <div className='w-full'>
      {label && <InputLabel label={label} />}
      <div className='relative w-full'>
        <input
          type={showPassword ? 'text' : type}
          className={twMerge(
            'mt-3 rounded-xl border border-neutral-300 px-4 py-3 text-lg transition-[border,box-shadow] ease-out focus:border-brand/75 focus:outline-none focus:ring-1 focus:ring-brand/75',
            error && 'border-red-500 focus:border-red-500/75 focus:ring-red-500/75',
            className
          )}
          value={value ?? ''}
          name={rest.name ?? getNodeText(label)}
          data-pw={dataPw}
          {...rest}
        />
        {type === 'password' ? (
          <button
            onClick={toggleShowPassword}
            type='button'
            className='absolute right-4 top-1/2 mt-1 -translate-y-1/2 transform'
          >
            {showPassword ? <EyeSlashIcon className='size-6' /> : <EyeIcon className='size-6' />}
          </button>
        ) : null}
      </div>
      {error ? <p className='absolute mt-1 text-sm text-red-500'>{error}</p> : null}
    </div>
  );
};
