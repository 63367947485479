import type { SWRMutationConfig } from './api/useApiHooks';
import { useMutationApi } from './api/useApiHooks';

interface SageDescriptionRequest {
  videoUrl: string;
}

interface SageDescriptionResponse {
  status: number;
  data: {
    user_description: string;
    hidden_description: string;
  };
}

export const useSageDescription = (swrConfig?: SWRMutationConfig<SageDescriptionResponse>) =>
  useMutationApi<SageDescriptionRequest, SageDescriptionResponse>(
    'post',
    '/sage/video-description',
    undefined,
    swrConfig
  );
