import { twMerge } from 'tailwind-merge';

interface SpinnerProps {
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ className }) => (
  <span
    className={twMerge(
      'h-8 w-8 animate-spin rounded-full border-4 border-solid border-brand !border-r-transparent',
      className
    )}
  />
);
