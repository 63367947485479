import type { SWRMutationConfig } from './useApiHooks';
import { useMutationApi } from './useApiHooks';
import type { ReviewStatus } from '../../const';

interface UpdateAlertReviewRequest {
  alertId: string;
  newReviewStatus: ReviewStatus;
  message?: string;
}

export const useUpdateAlertReview = (swrConfig?: SWRMutationConfig<never>) =>
  useMutationApi<UpdateAlertReviewRequest>('post', '/alerts-review', undefined, swrConfig);
