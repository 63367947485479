import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

export interface DropdownOption {
  icon?: React.ReactNode;
  label: string;
  onClick: () => void;
}

interface DropdownProps {
  children: React.ReactElement | (({ open }: { open?: boolean }) => React.ReactElement);
  options: DropdownOption[];
  className?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({ className, children, options }) => {
  return (
    <div className='relative'>
      <Menu as={Fragment}>
        {({ open }) => (
          <>
            <MenuButton className='block w-full focus:outline-none'>
              {typeof children === 'function' ? children({ open }) : children}
            </MenuButton>
            <Transition
              show={open}
              enter='ease-out'
              enterFrom='opacity-0 scale-90'
              enterTo='opacity-100 scale-100'
              leave='ease-in'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-90'
            >
              <MenuItems
                className={twMerge(
                  'scroll-shadow absolute z-[999] max-h-[50vh] overflow-auto rounded-md bg-white shadow-md transition-[opacity,transform] duration-150 focus:outline-none active:outline-none',
                  className
                )}
              >
                {options.map(({ icon, label, onClick }) => (
                  <MenuItem
                    as='button'
                    id={label}
                    type='button'
                    onClick={onClick}
                    key={label}
                    className='flex w-full items-center gap-x-2 whitespace-nowrap px-4 py-1 hover:bg-brand/15 active:bg-brand/25 ui-active:bg-brand/15'
                  >
                    {icon}
                    <p>{label}</p>
                  </MenuItem>
                ))}
              </MenuItems>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};
