import { useEffect, useState } from 'react';

import { SAGE_BRANDING_THEMES, type SageBrandingTheme } from '../const';

export const useSageBrandingTheme = () => {
  const [brandingTheme, setBrandingTheme] = useState<SageBrandingTheme>();

  useEffect(() => {
    const brandingTheme = SAGE_BRANDING_THEMES.find(({ origins }) =>
      origins.includes(window.location.origin)
    );
    setBrandingTheme(brandingTheme);
  }, []);

  return brandingTheme;
};
