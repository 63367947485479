type InputLabelProps = {
  label: string | React.ReactNode;
};

export const InputLabel: React.FC<InputLabelProps> = ({ label }) => {
  return (
    <label className='block text-start text-base font-medium leading-4 text-gray-500'>
      {label}
    </label>
  );
};
