import type { LocalStorageKeys } from '../const';

export const getFromLocalStorage = <T>(key: LocalStorageKeys) => {
  if (typeof window === 'undefined') return;

  const value = window.localStorage.getItem(key);
  if (!value) return;

  return JSON.parse(value) as T;
};

export const setToLocalStorage = <T>(key: LocalStorageKeys, value: T) => {
  if (typeof window === 'undefined') return;

  window.localStorage.setItem(key, JSON.stringify(value));
};
