type DisplayFieldProps = {
  label: string | React.ReactNode;
  value: string | React.ReactNode;
};

export const DisplayField: React.FC<DisplayFieldProps> = ({ label, value }) => {
  return (
    <div className='w-full'>
      {label && (
        <label className='block text-start text-base font-medium leading-4 text-gray-500'>
          {label}
        </label>
      )}
      <div className='relative mt-4 w-full pb-3 text-lg'>{value ?? ''}</div>
    </div>
  );
};
