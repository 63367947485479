const style: React.CSSProperties = { maskType: 'luminance' };

export const ForkliftTopSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='282'
    height='500'
    viewBox='0 0 282 500'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_116_575)'>
      <path
        d='M92.9722 179.955L92.9722 177.759L187.409 177.759L187.409 179.955L92.9722 179.955Z'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask
        id='mask0_116_575'
        style={style}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='282'
        height='500'
      >
        <path
          d='M281.113 1.22878e-05L281.113 500L-2.18557e-05 500L0 0L281.113 1.22878e-05Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_116_575)'>
        <path
          d='M185.578 177.393L185.578 89.472C185.578 89.472 185.578 82.2246 182.284 81.1265L177.27 81.1265C177.27 81.1265 174.231 83.3227 174.231 89.5452L174.231 177.759'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.231 177.759L174.231 174.465L185.578 174.465L185.578 177.759L174.231 177.759Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.784 177.393L105.784 89.472C105.784 89.472 105.784 82.2246 102.489 81.1265L97.4746 81.1265C97.4746 81.1265 94.4365 83.3227 94.4365 89.5452L94.4365 177.759'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.4365 177.759L94.4365 174.465L105.784 174.465L105.784 177.759L94.4365 177.759Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.4365 183.981L94.4365 179.955L185.579 179.955L185.579 183.981L94.4365 183.981Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.579 182.883L185.579 179.955L188.507 179.955L188.507 182.883L185.579 182.883Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M188.507 182.151L188.507 180.321L190.337 180.321L190.337 182.151L188.507 182.151Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.4366 182.883L94.4366 179.955L91.5083 179.955L91.5083 182.883L94.4366 182.883Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.5084 182.151L91.5084 180.321L89.6782 180.321L89.6782 182.151L91.5084 182.151Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.715 183.982L162.885 179.956L174.232 179.956L173.134 183.982L164.715 183.982Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.812 183.981L165.812 179.955L171.303 179.955L171.303 183.981L165.812 183.981Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.614 183.982L105.784 179.956L117.131 179.956L116.033 183.982L107.614 183.982Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.712 183.981L108.712 179.955L114.202 179.955L114.202 183.981L108.712 183.981Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.765 206.675L116.765 198.989L163.617 198.989L163.617 206.675L116.765 206.675Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.179 197.159L166.179 183.981L169.473 183.981L169.473 197.159L166.179 197.159Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.885 198.989L162.885 197.159L164.729 197.159L164.729 192.32L158.492 192.4L158.492 190.08L164.729 190.08L164.715 187.276L162.226 187.276L162.226 183.981'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.224 183.982L159.224 189.838'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.198 194.963L155.198 192.4L159.224 192.4L159.224 194.963L155.198 194.963Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.76 198.989L157.76 194.962L161.055 194.962L161.055 198.989L157.76 198.989Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.937 184.348L170.937 199.589L166.179 199.589L166.179 197.159'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.836 197.159L113.836 183.981L110.542 183.981L110.542 197.159L113.836 197.159Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.13 198.989L117.13 197.159L115.286 197.159L115.286 192.32L121.523 192.4L121.523 190.08L115.286 190.08L115.3 187.276L117.789 187.276L117.789 183.981'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.791 183.981L120.791 189.838'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.817 194.963L124.817 192.4L120.791 192.4L120.791 194.963L124.817 194.963Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.255 198.989L122.255 194.962L118.961 194.962L118.961 198.989L122.255 198.989Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.078 184.348L109.078 199.589L113.836 199.589L113.836 197.159'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.621 209.238L122.621 206.676L158.126 206.676L158.126 209.238L122.621 209.238Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.749 199.589L168.749 206.222C168.749 206.222 168.039 209.238 164.73 209.238L158.126 209.238'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.715 206.31L164.715 201.185L167.277 201.185L167.277 204.816C167.277 205.641 166.608 206.31 165.784 206.31L164.715 206.31Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.641 199.589L111.641 206.222C111.641 206.222 112.351 209.238 115.66 209.238L122.622 209.238'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.674 206.31L115.674 201.185L113.112 201.185L113.112 204.816C113.112 205.641 113.781 206.31 114.605 206.31L115.674 206.31Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.205 212.166C169.195 212.166 168.375 211.347 168.375 210.336C168.375 209.325 169.195 208.506 170.205 208.506C171.216 208.506 172.035 209.325 172.035 210.336C172.035 211.347 171.216 212.166 170.205 212.166Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.963 250.6C174.155 250.6 173.499 249.945 173.499 249.136C173.499 248.327 174.155 247.672 174.963 247.672C175.772 247.672 176.427 248.327 176.427 249.136C176.427 249.945 175.772 250.6 174.963 250.6Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.232 242.913C172.412 242.913 170.937 241.438 170.937 239.619C170.937 237.799 172.412 236.324 174.232 236.324C176.051 236.324 177.526 237.799 177.526 239.619C177.526 241.438 176.051 242.913 174.232 242.913Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.232 244.743C171.402 244.743 169.107 242.449 169.107 239.619C169.107 236.788 171.402 234.494 174.232 234.494C177.062 234.494 179.356 236.788 179.356 239.619C179.356 242.449 177.062 244.743 174.232 244.743Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.869 267.071C131.05 267.071 129.575 265.596 129.575 263.777C129.575 261.957 131.05 260.482 132.869 260.482C134.689 260.482 136.164 261.957 136.164 263.777C136.164 265.596 134.689 267.071 132.869 267.071Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.87 268.901C130.04 268.901 127.746 266.607 127.746 263.777C127.746 260.947 130.04 258.652 132.87 258.652C135.7 258.652 137.995 260.947 137.995 263.777C137.995 266.607 135.7 268.901 132.87 268.901Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.747 390.424C154.029 390.424 152.636 389.031 152.636 387.312C152.636 385.594 154.029 384.201 155.747 384.201C157.465 384.201 158.858 385.594 158.858 387.312C158.858 389.031 157.465 390.424 155.747 390.424Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.366 390.424C123.648 390.424 122.255 389.031 122.255 387.312C122.255 385.594 123.648 384.201 125.366 384.201C127.084 384.201 128.477 385.594 128.477 387.312C128.477 389.031 127.084 390.424 125.366 390.424Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.542 212.166C109.531 212.166 108.712 211.347 108.712 210.336C108.712 209.325 109.531 208.506 110.542 208.506C111.553 208.506 112.372 209.325 112.372 210.336C112.372 211.347 111.553 212.166 110.542 212.166Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.937 189.355C170.937 189.355 175.695 193.865 175.695 197.525L175.695 206.947C175.695 206.947 175.71 213.44 168.748 216.281L135.798 216.281L111.64 216.281C111.64 216.281 105.417 212.533 105.417 207.042L105.417 197.701C105.417 197.701 106.149 192.65 109.077 189.106'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.695 222.166L175.695 206.507C175.695 205.387 176.603 204.479 177.723 204.479L194.531 204.479C195.651 204.479 196.559 205.387 196.559 206.507L196.559 259.186C196.559 260.306 195.651 261.214 194.531 261.214L189.971 261.214'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.696 204.48L175.696 189.666C175.696 188.751 176.439 188.008 177.354 188.008L193.438 188.008C194.354 188.008 195.096 188.751 195.096 189.666L195.096 204.48'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.774 340.98L187.774 254.992L189.97 254.992L189.97 394.45L187.042 394.45'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.454 343.206L180.454 245.841L184.114 245.841L184.114 343.206L180.454 343.206Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.356 245.841L179.356 239.619L185.945 239.619L185.945 245.841L179.356 245.841Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.527 402.395C182.527 402.395 173.133 412.021 161.786 415.681C150.541 419.308 142.752 419.341 133.602 418.243C124.451 417.145 109.809 414.949 98.0964 402.504C98.0964 402.504 94.0701 399.575 92.606 386.135'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.114 332.985L188.507 342.839L188.507 386.133C188.507 386.133 189.179 394.383 182.527 402.394'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M188.506 351.17L182.284 351.17L179.722 382.004L185.578 384.567C185.578 384.567 188.506 378.535 188.506 351.17Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.025 349.794L101.025 345.768L180.088 345.768L180.088 349.794L101.025 349.794Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.025 352.063L101.025 349.794L180.088 349.794L180.088 352.063C180.088 355.864 177.007 358.945 173.207 358.945L107.906 358.945C104.106 358.945 101.025 355.864 101.025 352.063Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.341 358.945L169.341 361.874C169.341 361.874 176.214 368.374 180.896 367.869'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.115 343.206L189.972 353.455'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.454 343.206L182.284 351.259'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.258 343.206L178.258 341.01L180.454 341.01L180.454 343.206L178.258 343.206Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.355 343.206L179.355 345.768'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.972 390.791L189.972 345.769C190.982 345.769 191.802 346.588 191.802 347.599L191.802 388.96C191.802 389.971 190.982 390.791 189.972 390.791Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.115 254.993L184.115 249.868L186.586 249.868C188.052 249.868 189.24 251.056 189.24 252.522L189.24 254.993L184.115 254.993Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.453 254.627L180.453 249.868L179.813 249.868C178.347 249.868 177.159 251.056 177.159 252.522L177.159 254.627L180.453 254.627Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.659 254.627L100.659 249.868L101.3 249.868C102.765 249.868 103.953 251.056 103.953 252.522L103.953 254.627L100.659 254.627Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.559 249.868L187.042 249.868'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.559 251.331L189.238 251.331'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.394 226.441L196.559 226.441L196.559 229.37L187.042 229.37'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.65 235.226L184.481 222.049L187.775 222.049L185.945 235.226L182.65 235.226Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M171.978 214.362L173.5 214.362L173.5 224.611L166.18 224.611L166.18 216.28'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M173.499 222.397C173.499 222.397 169.839 223.843 166.179 222.397'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M173.499 220.566C173.499 220.566 169.839 222.012 166.179 220.566'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M173.499 218.736C173.499 218.736 169.839 220.182 166.179 218.736'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M173.499 216.906C173.499 216.906 169.839 218.352 166.179 216.906'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M173.5 224.245L173.5 222.049L175.696 222.049L175.696 224.245L173.5 224.245Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.48 222.049L174.963 222.049'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.257 367.73L174.963 397.745'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.114 268.535L180.454 268.535'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.945 241.449L185.945 235.226L188.142 235.226L188.142 239.34C188.142 240.505 187.198 241.449 186.033 241.449L185.945 241.449Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.418 222.166L105.418 206.507C105.418 205.387 104.51 204.479 103.39 204.479L86.5815 204.479C85.4615 204.479 84.5537 205.387 84.5537 206.507L84.5537 259.187C84.5537 260.307 85.4615 261.215 86.5815 261.215L91.1423 261.215'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.418 204.479L105.418 189.666C105.418 188.75 104.675 188.008 103.76 188.008L87.6762 188.008C86.7604 188.008 86.0181 188.75 86.0181 189.666L86.0181 204.479'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M93.3383 340.98L93.3383 254.992L91.1421 254.992L91.1421 394.45L94.0703 394.45'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.659 343.206L100.659 245.841L96.999 245.841L96.999 343.206L100.659 343.206Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.757 245.841L101.757 239.619L95.1685 239.619L95.1685 245.841L101.757 245.841Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.9988 332.985L92.6064 342.839L92.6064 386.133'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M92.6064 351.17L98.829 351.17L101.391 382.004L95.5347 384.567C95.5347 384.567 92.6064 378.535 92.6064 351.17Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.088 349.794L180.088 345.768L101.025 345.768L101.025 349.794L180.088 349.794Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.088 352.063L180.088 349.794L101.025 349.794L101.025 352.063C101.025 355.864 104.106 358.945 107.906 358.945L173.207 358.945C177.007 358.945 180.088 355.864 180.088 352.063Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.772 358.945L111.772 361.874C111.772 361.874 104.898 368.374 100.216 367.869'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.9986 343.206L91.1421 353.455'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.659 343.206L98.8286 351.259'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.855 343.206L102.855 341.01L100.659 341.01L100.659 343.206L102.855 343.206Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.757 343.206L101.757 345.768'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.1422 390.791L91.1422 345.769C90.1316 345.769 89.312 346.588 89.312 347.599L89.312 388.96C89.312 389.971 90.1316 390.791 91.1422 390.791Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.999 254.993L96.999 249.868L94.5282 249.868C93.0627 249.868 91.8745 251.056 91.8745 252.522L91.8745 254.993L96.999 254.993Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.5537 249.868L94.0706 249.868'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.5537 251.331L91.8744 251.331'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M93.7181 226.441L84.5537 226.441L84.5537 229.37L94.0706 229.37'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.4623 235.226L96.6322 222.049L93.3379 222.049L95.1681 235.226L98.4623 235.226Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.136 214.362L107.614 214.362L107.614 224.611L114.934 224.611L114.934 216.28'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.614 222.397C107.614 222.397 111.275 223.843 114.935 222.397'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.614 220.566C107.614 220.566 111.275 222.012 114.935 220.566'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.614 218.736C107.614 218.736 111.275 220.182 114.935 218.736'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.614 216.906C107.614 216.906 111.275 218.352 114.935 216.906'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.614 224.245L107.614 222.049L105.417 222.049L105.417 224.245L107.614 224.245Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.6328 222.049L106.15 222.049'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.855 367.73L106.149 398.477'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.999 268.535L100.659 268.535'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.1684 241.449L95.1684 235.226L92.9722 235.226L92.9722 239.34C92.9722 240.505 93.9162 241.449 95.0805 241.449L95.1684 241.449Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.813 222.049L114.934 222.049'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.806 222.049L150.806 219.121L154.1 219.121L154.1 222.049L150.806 222.049Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.896 216.559L136.896 222.049L134.7 222.049L134.7 216.559'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.179 220.585L163.251 220.585L163.251 216.559'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.1 219.12L163.251 219.12'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.806 220.219L136.896 220.219'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.935 220.585L114.935 217.657L117.131 217.657L117.131 220.585L114.935 220.585Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.7 219.12L117.13 219.12'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.55 216.559L125.55 219.121'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.353 216.559L123.353 218.755'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.156 216.559L121.156 218.755'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.961 216.559L118.961 218.755'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.787 216.559L161.787 218.755'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.591 216.559L159.591 219.121'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.394 216.559L157.394 218.755'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.197 216.559L155.197 219.121'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.578 384.567C185.578 384.567 182.05 394.816 164.597 405.431C147.145 416.046 123.719 409.458 116.032 405.431C109.348 401.93 98.4624 392.986 95.5342 384.567'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.527 402.394L182.527 396.5L180.897 396.5C180.897 396.5 165.813 412.752 147.877 415.314C147.877 415.314 140.022 415.654 135.871 415.314C131.406 414.948 121.396 411.956 116.398 409.458C111.274 406.895 102.336 401.449 100.217 396.851L98.3604 396.851L98.3604 402.503'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.44 393.722C175.44 393.722 166.413 402.136 154.583 405.431C142.753 408.725 128.844 406.069 128.844 406.069C128.844 406.069 111.847 401.238 105.521 392.902'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.454 254.626L100.659 254.626'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.383 229.37L97.7314 229.37'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.017 230.834L98.0972 230.834'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.322 234.494L160.322 230.834L174.231 230.834L174.231 234.494L160.322 234.494Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.322 241.449L160.322 237.422L167.277 237.422L167.277 241.449L160.322 241.449Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.832 250.233L154.832 244.377L165.813 244.377L165.813 250.233L154.832 250.233Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.492 244.377L158.492 234.494L168.375 234.494L168.375 244.377L158.492 244.377Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.357 244.377C179.357 244.377 174.598 249.501 165.813 250.233'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.817 241.449L124.817 230.834L138.361 230.834L138.361 241.449L124.817 241.449Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.817 238.887L124.817 234.494L138.361 234.494L138.361 238.887L124.817 238.887Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.413 241.449L146.413 234.494L157.394 234.494L157.394 241.449L146.413 241.449Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.197 234.494L155.197 241.449'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.123 234.86L153.123 241.082'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.806 234.86L150.806 241.083'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.609 234.494L148.609 241.449'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.064 254.626L140.63 242.191L141.665 241.156L155.124 254.615'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.091 254.626L144.657 242.191L145.692 241.156L159.151 254.615'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.643 250.233L167.643 254.626'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.368 241.449L154.832 244.377'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.992 250.553L143.119 250.553C143.119 250.553 140.922 250.234 140.922 248.403L140.922 242.591'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.047 250.6L146.047 254.626'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.138 241.449L132.138 254.626'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.36 241.449L138.36 254.626'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.047 252.796L138.36 252.796'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.078 252.796L109.078 236.324L111.838 236.324C112.941 236.324 113.836 237.219 113.836 238.323L113.836 252.796L109.078 252.796Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.078 236.324L109.078 234.494L111.64 234.494L111.64 236.324L109.078 236.324Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.836 249.868L113.836 238.521L121.889 238.521L121.889 249.868L113.836 249.868Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.1685 239.618L95.1685 235.226L102.855 235.226L102.855 239.618L95.1685 239.618Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.855 238.886L102.855 237.056L109.078 237.056L109.078 238.886L102.855 238.886Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.078 249.868C109.078 249.868 103.587 250.234 101.757 245.841'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.719 245.475L123.719 241.449L126.281 241.449L126.281 245.475L123.719 245.475Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.987 252.796L122.987 245.475L125.55 245.475L125.55 252.796L122.987 252.796Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.014 254.626L127.014 252.43L136.531 252.43L136.531 254.626L127.014 254.626Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.889 241.449L114.203 241.449'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.817 238.52C124.817 238.52 124.085 237.422 121.157 237.422L113.836 237.422'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.836 254.626L113.836 251.698L118.595 251.698L118.595 254.626L113.836 254.626Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.899 241.449C127.899 241.449 128.112 248.404 125 254.626'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.454 237.422L180.454 235.226L185.945 235.226L185.945 237.422L180.454 237.422Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.454 237.422L180.454 239.619L185.945 239.619L185.945 237.422L180.454 237.422Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.16 234.128L177.16 230.834L179.356 230.834L179.356 234.128L177.16 234.128Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.987 233.03L122.987 230.834L124.817 230.834L124.817 233.03L122.987 233.03Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.149 233.03L106.149 230.834L107.98 230.834L107.98 233.03L106.149 233.03Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.659 287.203L100.659 284.641L180.454 284.641L180.454 287.203L100.659 287.203Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.659 302.576L100.659 300.014L180.454 300.014L180.454 302.576L100.659 302.576Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.659 317.95L100.659 315.388L180.454 315.388L180.454 317.95L100.659 317.95Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.659 341.01L100.659 330.761L180.454 330.761L180.454 341.01L100.659 341.01Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.523 345.768L121.523 341.01L150.44 341.01L150.44 345.768L121.523 345.768Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.545 283.176L166.545 256.456L168.009 256.456L168.009 283.176L166.545 283.176Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.368 283.176L153.368 256.456L154.832 256.456L154.832 283.176L153.368 283.176Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.825 283.176L139.825 256.456L141.289 256.456L141.289 283.176L139.825 283.176Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.281 283.176L126.281 256.456L127.745 256.456L127.745 283.176L126.281 283.176Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.738 283.176L112.738 256.456L114.202 256.456L114.202 283.176L112.738 283.176Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.455 258.652L168.01 258.652'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.179 258.652L154.832 258.652'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.368 258.652L141.289 258.652'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.825 258.652L127.746 258.652'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.281 258.652L114.202 258.652'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.738 258.652L100.659 258.652'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.84 281.712L169.84 258.652L172.036 258.652L172.036 281.712L169.84 281.712Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.346 281.712L108.346 258.652L110.542 258.652L110.542 281.712L108.346 281.712Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.84 281.713L168.01 281.713'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.545 281.713L154.832 281.713'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.368 281.713L141.289 281.713'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.825 281.713L127.746 281.713'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.282 281.713L114.203 281.713'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.738 281.713L109.443 281.713'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.87 287.203C152.87 287.203 155.447 289.399 155.506 300.014'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.563 302.576L155.563 315.387'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.564 317.95L155.564 322.665C155.564 322.665 155.813 326.369 150.015 330.761'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.406 287.203C151.406 287.203 153.983 289.399 154.042 300.014'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.1 302.576L154.1 315.387'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.1 317.95L154.1 322.665C154.1 322.665 153.676 326.369 147.878 330.761'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.968 287.203C113.968 287.203 111.391 289.399 111.333 300.014'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.274 302.576L111.274 315.387'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.274 317.95L111.274 322.665C111.274 322.665 111.025 326.369 116.823 330.761'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.432 287.203C115.432 287.203 112.855 289.399 112.796 300.014'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.738 302.576L112.738 315.387'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.738 317.95L112.738 322.665C112.738 322.665 113.162 326.369 118.96 330.761'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.308 294.465L130.308 287.203L136.897 287.203L136.897 294.465C136.897 296.114 135.56 297.452 133.91 297.452L133.295 297.452C131.645 297.452 130.308 296.114 130.308 294.465Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.987 287.203C142.987 287.203 143.324 295.987 141.757 300.014'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.556 302.576C140.556 302.576 138.36 308.521 133.602 311.771'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.116 287.203C124.116 287.203 123.779 295.987 125.346 300.014'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.546 302.576C126.546 302.576 128.742 308.521 133.501 311.771'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.602 287.203L133.602 300.014'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.602 302.576L133.602 315.387'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.602 317.95L133.602 330.761'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.281 274.326C121.932 272.59 118.96 269.091 118.96 265.058C118.96 262.626 120.041 260.389 121.852 258.615'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.825 273.835C137.714 274.882 135.201 275.49 132.504 275.49C130.829 275.49 129.226 275.255 127.745 274.827'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.193 258.651C144.982 260.419 146.047 262.643 146.047 265.058C146.047 268.237 144.201 271.084 141.29 272.998'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.28 272.17C123.194 270.641 121.156 268.006 121.156 265.013C121.156 262.512 122.579 260.262 124.848 258.697'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.826 271.552C137.849 272.812 135.294 273.572 132.504 273.572C130.804 273.572 129.192 273.29 127.745 272.785'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141.26 259.57C142.878 261.049 143.851 262.946 143.851 265.014C143.851 267.069 142.89 268.955 141.289 270.43'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.455 283.087L172.036 281.713'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.972 284.641L184.115 283.685'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.9986 283.644L91.1421 284.641'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.346 281.713L100.659 283.021'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.839 268.901L168.375 268.901'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.546 268.901L165.813 268.901'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.813 268.901L154.832 268.901'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.367 268.901L145.314 268.901'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.387 268.901L141.655 268.901'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.825 268.901L127.746 268.901'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.281 268.901L122.621 268.901'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.693 268.901L114.202 268.901'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.738 268.901L110.542 268.901'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.322 258.652L160.322 268.901'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.976 258.652L148.976 268.901'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.164 263.777L129.575 263.777'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.87 260.482L132.87 267.071'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_116_575'>
        <rect width='500' height='281.113' fill='white' transform='translate(281.113) rotate(90)' />
      </clipPath>
    </defs>
  </svg>
);
