import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

import type { User } from './api/useGetOrganizations';
import { getFromEnvConfig } from '../utils';

export const useTrackers = ({ user }: { user?: User }) => {
  const [trackersInitialized, setTrackersInitialized] = useState(false);
  useEffect(() => {
    if (!user || trackersInitialized) return;

    if (process.env.NODE_ENV === 'production') {
      window.pendo.initialize({
        visitor: {
          email: user.email,
          id: user.userId
        },
        account: {
          email: user.email,
          id: user.userId
        }
      });

      window.Intercom('boot', {
        app_id: getFromEnvConfig('INTERCOM_ID'),
        email: user.email,
        user_id: user.userId
      });
      // Only runs on built version, not when running yarn dev
      Sentry.init({
        dsn: getFromEnvConfig('SENTRY_DSN'),
        environment: getFromEnvConfig('ENV'),
        tracesSampleRate: 1,
        release: getFromEnvConfig('SENTRY_RELEASE')
      });

      Sentry.setUser({
        email: user.email,
        id: user.userId
      });
    }
    setTrackersInitialized(true);
  }, [user, trackersInitialized]);
};
