const style: React.CSSProperties = { maskType: 'luminance' };

export const TruckTopSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='282'
    height='500'
    viewBox='0 0 282 500'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_120_3875)'>
      <path
        d='M149.521 209.925L149.521 223.475'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M145.357 209.925L145.357 223.384'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M145.375 218.728L149.522 218.728'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M136.74 216.464L136.74 223.37'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M127.61 221.972L128.516 222'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M128.516 220.521L130.607 220.521'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M128.516 222.015L130.607 222.015'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M127.61 220.564L127.61 221.971'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M127.61 220.565L128.516 220.537'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M130.607 218.336L130.607 223.505'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M167.409 149.38L176.466 150.977'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M206.976 65.0431L212.482 64.355'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M145.358 210.613L139.181 210.613'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M145.375 220.25L143.907 220.25'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M145.375 216.12L145.375 220.25'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M143.225 219.659L142.706 216.022'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M142.024 215.431L139.18 215.431'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M137.888 219.658L138.344 216.464'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M136.74 220.25L137.207 220.25'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M177.724 222.796L180.199 222.796'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M181.232 221.419L181.232 223.14'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M174.971 225.243L174.971 221.419'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M177.724 225.56L177.724 221.419'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M181.232 221.419L181.232 225.311'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M174.97 222.796L172.494 222.796'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M171.462 221.419L171.462 223.14'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M181.232 221.419L181.232 225.999'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M172.218 213.505L176.003 212.816L176.691 212.816L180.477 213.505'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M180.477 218.322L180.477 213.504'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M172.218 218.322L172.218 213.504'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M184.607 218.322L169.465 218.322'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M184.607 221.42L169.465 221.42'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M184.606 223.335L184.606 218.322'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M184.605 219.355L191.144 219.355'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M184.606 220.731L191.145 220.731'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M182.197 209.925L182.197 218.322'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M200.092 221.046L200.092 222.712'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M200.092 209.926L200.092 219.04'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M200.093 219.355L191.146 219.355'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M200.093 220.731L191.146 220.731'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M207.664 235.599L207.664 239.728'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M100.293 162.778L97.8838 162.778'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M97.8838 158.97L97.8838 162.779'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.648 173.191L100.932 173.191'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M100.637 159.337L100.565 158.612'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M100.637 159.337L100.637 164.155'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M108.896 159.337L108.896 172.816'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.512 159.337L100.637 159.337'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M78.2676 157.383L75.5146 157.383'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M78.5977 153.941L75.5146 153.941'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M75.5146 153.941L75.5146 157.383'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M102.357 152.399L102.357 156.566'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M78.2676 155.061L78.2676 159.227'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M100.02 150.353L80.0596 153.014'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M100.566 158.612L80.6055 161.274'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M86.1826 165.531L86.1826 168.285'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M94.4414 165.532L94.4414 168.285'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M85.8379 160.576L85.8379 164.929'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M94.7861 159.383L94.7861 164.929'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.648 175.944L100.932 175.944'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M100.658 158.648L100.658 177.32'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M68.6318 158.648L68.6318 177.32'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M100.566 177.321L68.6318 177.321'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M72.7607 147.636L92.9123 151.3'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M101.619 150.817L102.211 148.937'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M172.218 209.926L172.218 213.505'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M178.902 148.938L181.853 152.454'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M181.853 152.454L180.661 154.519'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M208.351 147.636L181.853 152.454'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M105.015 56.7236L82.1787 60.2108'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M176.099 56.7236L185.697 58.1892L189.362 58.7489L198.935 60.2108'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 56.4395L172.358 56.4395'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 154.519L180.661 154.519'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M199.81 147.636L208.352 147.636'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M176.598 53.458L193.622 56.0576'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M174.971 88.1182L195.224 90.0958'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M200.806 90.4848L205.519 65.4756'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M206.351 132.821L207.664 88.4658'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M198.605 87.2917L202.208 68.1753'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 53.1362L172.358 53.1362'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M202.158 90.7392L205.435 73.3501'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M206.282 73.3901L207.663 88.4655'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M202.502 142.13L208.352 142.13'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M200.437 94.436L200.437 140.065'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M169.465 162.261L169.465 174.232'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M164.303 162.778L164.303 175.856'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.649 162.261L111.649 175.856'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M116.812 162.778L116.812 175.856'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M206.976 61.7803L206.976 65.0435'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 46.8037L172.358 46.8037'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 154.519L102.357 154.519'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M113.704 149.38L104.647 150.977'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M104.077 151.655L104.077 153.142'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M65.8789 144.157L65.8789 156.584'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M78.2677 158.649L67.9434 158.649'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 56.4399L124.566 56.4399L118.635 56.4399L108.756 56.4399'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M104.517 53.458L87.4922 56.0576'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 53.1362L108.756 53.1362'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M106.143 88.1182L85.8896 90.0958'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M82.5068 87.2917L78.9043 68.1753'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 88.1182L106.144 88.1182'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M81.3046 147.636L72.7617 147.636'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M79.9883 141.604L79.9883 145.712'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M80.3078 90.4848L75.5947 65.4756'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M80.6768 94.436L80.6768 140.065'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M78.611 142.13L72.7607 142.13'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M74.7632 132.821L73.4502 88.4658'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M74.8315 73.3906L73.4512 88.466'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M78.9548 90.7396L75.6777 73.3501'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M75.4453 133.698L77.9837 134.423'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M79.2998 94.436L79.2998 133.431'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M72.7607 88.4445L74.1052 65.2021'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M71.342 65.0405L67.2549 88.4446'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M72.7607 88.4448L72.7607 147.637'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M72.7618 88.4448L65.8789 88.4448'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M74.1381 65.0431L68.6318 64.355'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M104.184 51.2808L83.6842 54.4111L82.316 54.6201L79.9814 54.9766'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 50.9336L108.756 50.9336'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M74.1387 61.7803L74.1387 65.0435'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M103.56 47.1982L82.6301 50.3944L81.2615 50.6031L80.3193 50.7473'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.511 154.519L111.511 161.746'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M169.602 154.519L169.602 161.746'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M65.8789 88.4443L65.8789 138'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 46.8037L108.756 46.8037'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M205.668 133.697L203.13 134.423'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M208.352 88.4443L206.986 64.8359'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M108.896 218.336L108.896 221.748'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 88.1182L174.97 88.1182'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M201.813 94.436L201.813 133.431'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M201.125 141.604L201.125 145.712'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M215.234 88.4443L215.234 138'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M199.386 158.648L213.17 158.648'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 50.9336L172.358 50.9336'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M209.771 65.04L213.859 88.4442'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M208.352 88.4448L208.352 147.637'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M208.352 88.4448L215.234 88.4448'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M177.035 151.655L177.035 153.142'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M176.93 51.2808L201.132 54.9766'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M105.455 218.369L70.1855 218.369'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M215.234 144.157L215.234 156.584'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M177.553 47.1982L200.794 50.7473'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M94.0898 213.229L70.5518 213.229'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M82.5831 189.139L70.1855 189.139'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M82.5832 194.646L70.5518 194.646'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M112.544 162.778L168.57 162.778'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.8916 416.753L76.8916 417.477'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.223 416.753L204.223 417.477'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6846 416.753L204.429 416.753'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 419.681L140.557 273.594'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6846 232.295L204.429 232.295'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 235.219L140.557 232.294'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.8916 232.294L76.8916 231.57'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.223 232.294L204.223 231.57'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.429 416.752L204.429 232.294'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M141.727 263.267L141.727 268.511'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6846 416.752L76.6846 232.294'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M122.834 235.219L158.28 235.219'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M122.834 270.666L158.28 270.666'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M158.28 270.665L158.28 235.219'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M122.834 270.665L122.834 235.219'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M139.386 263.267L139.386 268.511'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M151.913 243.307L151.913 251.566'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M148.127 239.521L141.933 239.521'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M129.2 243.307L129.2 251.566'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M148.128 255.352L132.986 255.352'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M141.933 237.112L139.18 237.112'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M148.128 256.935L143.998 256.935'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M137.116 256.935L132.986 256.935'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M137.115 255.352L137.115 256.935'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M132.985 255.352L132.985 256.935'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M143.998 255.352L143.998 256.935'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M148.128 255.352L148.128 256.935'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M141.933 237.112L141.933 246.06'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M139.18 237.112L139.18 246.06'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M141.933 246.06L139.18 246.06'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M139.181 239.521L132.986 239.521'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M109.24 435.715L108.689 435.715'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.429 318.162L210.761 318.162'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M169.877 416.723L173.318 416.723'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.429 308.349L214.202 308.349'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.429 366.675L210.761 366.675'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M67.3242 376.488L67.3242 380.136'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M66.9111 308.349L66.9111 376.488'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M70.3525 362.784L70.3525 366.674'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6845 364.794L70.3525 364.794'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.235 416.723L107.794 416.723'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6845 318.162L70.3525 318.162'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M70.3525 322.052L70.3525 318.162'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.429 320.043L210.761 320.043'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.429 322.052L210.761 322.052'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.429 362.784L210.761 362.784'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.429 364.794L210.761 364.794'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.429 376.488L214.202 376.488'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.429 380.137L213.789 380.137'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M210.761 322.052L210.761 318.162'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M214.202 308.349L214.202 376.488'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M213.789 376.488L213.789 380.136'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M210.761 362.785L210.761 366.675'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6845 362.785L70.3525 362.785'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6845 366.675L70.3525 366.675'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6845 320.043L70.3525 320.043'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6845 322.052L70.3525 322.052'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6846 308.349L66.9111 308.349'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6846 376.488L66.9111 376.488'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6838 380.137L67.3232 380.137'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M78.6123 447.69L78.6123 453.197'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M202.502 447.69L202.502 453.197'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M123.694 450.788L125.65 450.788'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M129.889 449.756L137.116 449.756'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M119.393 442.253L134.191 442.253'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M78.6123 451.132L104.767 451.132'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M78.6123 447.69L104.767 447.69'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M104.766 447.69L104.766 451.132'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M137.17 449.755L176.347 449.755'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M202.501 451.132L176.347 451.132'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M202.501 447.69L176.347 447.69'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M176.347 447.69L176.347 451.132'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M164.302 442.219L154.322 442.219'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M164.302 444.283L154.322 444.283'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M154.322 442.218L154.322 444.283'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M96.7529 443.291L96.914 442.787'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M95.4418 444.249L94.7471 444.249'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M101.973 446.925L102.271 447.079'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M101.604 446.381L101.546 445.788'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M103.046 451.132L103.046 451.6'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M92.0332 451.132L92.0332 451.6'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M200.781 451.132L200.781 451.6'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M189.769 451.132L189.769 451.6'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M193.176 444.249L192.481 444.249'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M194.488 443.292L194.649 442.788'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M199.34 446.382L199.281 445.789'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M199.708 446.925L200.006 447.079'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M184.361 443.292L184.2 442.788'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M185.672 444.249L186.367 444.249'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M179.142 446.925L178.844 447.079'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M179.509 446.382L179.567 445.789'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M178.067 451.132L178.067 451.6'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M189.08 451.132L189.08 451.6'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M147.061 448.723L147.061 449.755'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M137.544 448.723L137.544 449.755'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M137.536 448.723L147.061 448.723'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M146.062 449.755L145.759 451.476'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M138.492 449.755L138.796 451.476'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M141.226 447.002L143.33 447.002'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M142.966 444.778L143.33 447.003'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M143.329 447.002L146.062 448.723'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M141.589 444.778L141.225 447.003'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M141.225 447.002L138.492 448.723'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M80.332 451.132L80.332 451.6'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M91.3447 451.132L91.3447 451.6'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M87.9365 444.249L88.6313 444.249'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M86.6259 443.292L86.4648 442.788'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M81.7744 446.382L81.833 445.789'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M81.4063 446.925L81.1084 447.079'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M123.97 448.791L129.614 448.791'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M122.145 442.253L122.592 447.759'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M131.437 442.253L130.989 447.759'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M130.989 447.76L130.827 448.064'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M122.593 447.76L122.755 448.064'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M118.703 442.941L118.703 445.006'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M134.878 442.941L134.878 445.006'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M119.393 445.694L122.425 445.694'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M131.157 445.694L134.19 445.694'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M123.694 448.764L123.694 450.787'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M129.889 448.764L129.889 450.787'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M128.167 448.791L128.025 450.413'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M125.415 448.791L125.557 450.413'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M127.933 450.788L129.888 450.788'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M78.6123 453.196L202.502 453.196'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M104.766 449.755L123.693 449.755'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M134.947 444.454L146.166 444.454'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M134.947 440.29L138.475 440.29'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M144.927 440.29L144.927 444.454'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M136.187 440.29L136.187 444.454'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M138.475 435.386L138.475 442.373'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M142.639 435.386L142.639 442.373'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M142.639 440.29L146.166 440.29'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M138.475 437.128L142.639 437.128'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M148.933 440.7L146.166 440.29'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M148.933 444.046L146.166 444.455'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M150.172 440.7L150.172 444.045'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M192.996 441.483L192.996 444.249'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M189.541 444.645L191.342 444.645'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M180.021 444.389L172.905 444.389'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M190.986 444.644L190.986 441.483'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M187.855 445.4L189.542 445.4'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M187.332 444.645L187.855 444.645'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M189.541 445.402L189.541 440.464'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M187.855 445.402L187.855 440.464'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M186.409 444.249L186.409 440.464'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M205.894 443.722L199.761 440.182'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M148.933 444.046L154.322 444.046'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M148.933 440.7L164.302 440.7'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M169.465 439.275L169.465 446.158'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M172.905 439.275L172.905 446.158'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M172.905 439.275L169.464 439.275'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M172.905 446.158L169.464 446.158'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M132.181 440.7L134.948 440.29'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M130.941 440.7L130.941 442.253'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M95.1435 441.059L94.7031 441.073'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M89.6855 441.059L90.1259 441.073'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M88.1172 441.045L88.1172 444.25'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M91.5724 444.645L90.127 444.645'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M88.1172 441.045L89.6864 441.045'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M88.1164 441.045L85.4941 441.045'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M101.093 444.389L108.208 444.389'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M95.1445 441.045L108.208 441.045'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M90.126 444.643L90.126 440.789'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M91.5724 440.789L90.127 440.789'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M93.2576 445.4L91.5713 445.4'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M94.7043 440.789L93.2588 440.789'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M93.7818 444.645L93.2588 444.645'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M91.5713 445.402L91.5713 440.033'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M93.2588 445.402L93.2588 440.033'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M94.7041 444.249L94.7041 440.789'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M93.2576 440.033L91.5713 440.033'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M75.2197 443.721L83.3614 439.021'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M87.6279 439.021L90.6909 440.789'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M118.704 444.046L116.812 444.046'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M132.181 440.7L116.812 440.7'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.649 438.931L111.649 445.814'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M108.208 439.275L108.208 446.158'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M108.208 439.275L111.649 439.275'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M108.208 446.158L111.649 446.158'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M135.395 297.305L135.395 293.551'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M145.719 297.305L145.719 293.551'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M135.395 293.551L145.719 293.551'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M135.395 297.305L145.719 297.305'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 293.551L140.557 297.305'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke-dasharray='3.27 1.09 1.09 1.09'
      />
      <path
        d='M135.395 366.851L135.395 363.097'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M145.719 366.851L145.719 363.097'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M135.395 363.098L145.719 363.098'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M135.395 366.851L145.719 366.851'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.557 363.098L140.557 366.852'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke-dasharray='3.27 1.09 1.09 1.09'
      />
      <path
        d='M169.465 209.926L169.465 224.684'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M169.465 424.363L169.465 449.755'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M164.303 424.798L164.303 432.961'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M155.01 220.25L155.01 223.68'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M160.517 220.25L160.517 223.983'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M120.597 220.25L120.597 223.983'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M126.103 220.25L126.103 223.68'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M120.597 220.25L126.103 220.25'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M155.01 220.25L160.516 220.25'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M168.336 436.154L166.464 436.154'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M168.336 436.154L168.336 441.908'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M166.464 436.154L166.464 441.908'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M112.777 436.154L114.65 436.154'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M112.777 436.154L112.777 441.908'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M114.65 436.154L114.65 441.908'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M175.039 423.796L175.039 440.019'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M173.041 440.119L206.15 440.119'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M70.1602 285.485L70.1602 277.969'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M68.2881 285.484L68.2881 180.481'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6845 234.607L69.2236 234.607'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6845 232.735L69.2236 232.735'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.235 179.738L69.2227 179.738'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M75.3223 181.61L75.3223 179.738'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M70.1602 276.096L70.1602 234.607'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M212.826 285.485L212.826 180.88'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M210.953 285.485L210.953 277.969'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M70.1602 232.735L70.1602 181.609'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M116.812 424.798L116.812 432.961'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.649 424.363L111.649 449.755'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.429 276.097L211.89 276.097'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6845 276.097L69.2236 276.097'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M76.6845 277.969L69.2236 277.969'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M139 181.61L69.2236 181.61'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M210.953 276.096L210.953 183.435'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.429 277.969L211.89 277.969'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M137.46 191.514L137.46 212.506'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M139.18 181.6L139.18 216.464'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M94.0977 191.514L94.0977 212.506'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M95.4746 213.883L136.083 213.883'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M99.9551 218.336L134.362 218.336'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M82.7412 202.699L97.8832 216.464'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M82.7412 181.601L82.7412 202.699'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M97.8838 216.464L139.18 216.464'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M116.812 218.336L116.812 224.248'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.649 218.336L111.649 224.684'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M164.303 175.855L164.303 178.587'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M95.4746 190.137L136.083 190.137'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M116.812 435.025L116.812 449.536'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M101.325 431.481L96.3691 431.481'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M101.325 433.685L96.3691 433.685'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M94.166 426.526L94.166 438.64'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M96.3691 426.526L96.3691 438.64'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M94.1667 431.481L89.2109 431.481'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M94.1667 433.684L89.2109 433.684'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M164.303 435.025L164.303 449.536'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M133.674 425.624L133.674 432.961'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M147.439 425.624L147.439 432.961'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M101.324 431.279L101.157 431.482'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M101.157 433.684L101.324 433.886'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M96.3691 426.693L96.5716 426.526'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M114.401 432.962L114.401 435.027'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.649 430.861L101.531 430.861'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.649 434.303L101.531 434.303'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M114.401 435.026L166.71 435.026'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M166.711 432.962L166.711 435.027'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M114.401 432.962L166.71 432.962'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M206.149 404.467L206.149 440.119'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.649 175.855L116.812 175.855'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.649 175.855L111.649 181.6'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M116.812 175.855L116.812 181.6'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M145.03 209.926L145.03 180.33'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M206.218 209.925L145.03 209.925'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M205.598 202.01L145.03 202.01'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M206.045 178.87L205.694 179.087'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M145.031 183.771L139.181 183.771'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M202.624 180.33L145.03 180.33'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M163.461 179.297L116.812 179.297'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M209.544 182.391L207.767 182.48'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M206.218 200.634L206.218 187.091'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M208.455 175.167L206.528 175.167'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M206.218 173.309L206.218 171.038'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M182.473 210.035L182.473 209.925'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M205.819 182.726L202.069 184.091'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M207.767 183.908L207.767 175.374'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M206.218 187.04L206.218 173.182'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M206.219 171.038L173.25 171.038'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M206.218 209.926L206.218 200.462'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M204.675 179.583L200.926 180.947'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M206.046 178.73L205.235 179.343'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M208.455 184.115L206.528 184.115'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M202.07 184.091L200.926 180.948'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M173.25 171.038L162.237 180.329'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M164.303 209.926L164.303 224.248'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M163.91 178.918L163.924 178.918'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
        stroke-dasharray='2.73 1.36'
      />
      <path
        d='M204.429 404.467L206.149 404.467'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M189.541 441.174L195.368 441.174'
        stroke='black'
        strokeWidth='0.549049'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask
        id='mask0_120_3875'
        style={style}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='282'
        height='500'
      >
        <path
          d='M281.113 1.22878e-05L281.113 500L-2.18557e-05 500L0 0L281.113 1.22878e-05Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_120_3875)'>
        <path
          d='M210.953 229.088C207.358 224.42 200.659 223.551 195.992 227.146C195.611 227.439 195.251 227.758 194.913 228.099'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.439 215.672C148.166 215.672 148.754 215.084 148.754 214.358C148.754 213.632 148.166 213.043 147.439 213.043C146.713 213.043 146.125 213.632 146.125 214.358C146.125 215.084 146.713 215.672 147.439 215.672Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.706 216.022C142.657 215.683 142.367 215.432 142.024 215.432'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.225 219.658C143.273 219.997 143.564 220.249 143.906 220.249'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.206 220.249C137.549 220.249 137.839 219.998 137.887 219.659'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.152 198.225C173.003 198.225 175.315 195.914 175.315 193.063C175.315 190.212 173.003 187.9 170.152 187.9C167.301 187.9 164.99 190.212 164.99 193.063C164.99 195.914 167.301 198.225 170.152 198.225Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.664 239.728C208.757 239.837 209.856 239.882 210.954 239.861'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.888 223.484C181.078 223.484 181.232 223.33 181.232 223.14'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.544 223.14C180.544 223.33 180.698 223.484 180.888 223.484'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.543 223.14C180.543 222.95 180.389 222.796 180.199 222.796'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.494 222.796C172.304 222.796 172.15 222.95 172.15 223.14'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M171.807 223.485C171.997 223.485 172.151 223.331 172.151 223.141'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M171.462 223.14C171.462 223.33 171.616 223.484 171.806 223.484'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.606 223.335C184.606 223.833 184.81 224.31 185.17 224.655'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.17 224.655C187.106 225.248 189.12 225.549 191.145 225.549'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.341 220.686C200.191 220.743 200.093 220.886 200.093 221.046'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.092 219.04C200.092 219.2 200.19 219.343 200.34 219.401'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.37 223.819C199.809 223.626 200.093 223.191 200.093 222.711'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.145 225.549C193.977 225.549 196.777 224.96 199.369 223.819'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.092 220.731C200.472 220.731 200.78 220.423 200.78 220.043C200.78 219.663 200.472 219.354 200.092 219.354'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.663 235.597C207.663 233.408 206.281 231.456 204.216 230.729'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.636 164.155C100.636 166.436 102.485 168.284 104.765 168.284C107.046 168.284 108.895 166.436 108.895 164.155'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.2676 159.227C78.2676 160.367 79.1922 161.292 80.3324 161.292C80.4235 161.292 80.515 161.286 80.6054 161.274'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.0597 153.014C79.0337 153.151 78.2676 154.026 78.2676 155.061'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.565 158.612C101.591 158.475 102.358 157.601 102.358 156.565'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.356 152.4C102.356 151.259 101.432 150.335 100.292 150.335C100.2 150.335 100.109 150.341 100.019 150.353'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85.8379 164.929C85.8379 165.142 85.9367 165.343 86.1055 165.473'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.5186 165.473C94.6873 165.343 94.7861 165.142 94.7861 164.929'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.1055 165.473C88.5831 167.386 92.04 167.386 94.5176 165.473'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.1826 168.285C86.1826 170.565 88.0314 172.414 90.3122 172.414C92.5929 172.414 94.4418 170.565 94.4418 168.285'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.257 153.487C186.702 156.861 192.98 158.649 199.385 158.649'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M206.35 58.6138C205.618 56.4176 203.1 55.2771 201.132 54.9766'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.564 57.5155C196.1 56.4174 194.344 56.1682 193.621 56.0576'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.0952 57.1494C78.9707 60.8097 78.2368 64.6293 78.9052 68.1754'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.591 62.2874C201.129 61.1791 200.122 60.3921 198.935 60.2109'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.036 151.655C177.036 151.321 176.796 151.035 176.467 150.977'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.208 68.1751C202.877 64.629 202.323 61.5415 197.564 57.5151'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.597 53.4575C175.194 53.2434 173.778 53.1357 172.358 53.1357'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.098 56.7235C174.86 56.5346 173.609 56.4395 172.357 56.4395'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M206.87 64.355C206.209 64.355 205.641 64.8257 205.519 65.4754'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.437 140.065C200.437 141.206 201.361 142.13 202.501 142.13'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.805 90.4844C200.56 91.7871 200.437 93.1099 200.437 94.4357'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.667 133.698C206.061 133.585 206.337 133.231 206.349 132.821'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.659 154.519C176.419 154.519 177.036 153.902 177.036 153.142'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.158 90.7397C201.928 91.9586 201.812 93.1962 201.812 94.4363'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.812 133.431C201.812 134.001 202.275 134.463 202.845 134.463'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M206.282 73.3901C206.261 73.1547 206.052 72.9812 205.817 73.0028C205.627 73.0204 205.471 73.162 205.436 73.3498'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.225 90.0962C196.879 90.0962 198.3 88.9183 198.606 87.292'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M208.352 144.883C212.153 144.883 215.234 141.802 215.234 138'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.557 149.807C160.123 149.807 179.684 149.128 199.203 147.772'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M206.975 61.7798C206.975 61.0514 207.082 60.4434 206.35 58.6133'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.929 51.2806C175.416 51.0496 173.888 50.9336 172.357 50.9336'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.552 47.1984C175.833 46.936 174.096 46.8042 172.357 46.8042'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.203 147.772C200.285 147.697 201.125 146.797 201.125 145.712'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M213.169 158.649C214.309 158.649 215.234 157.724 215.234 156.584'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M212.481 64.3548C212.481 63.1026 212.31 61.8559 211.973 60.6498L211.46 59.1513C209.603 54.6033 205.463 51.3896 200.597 50.7183'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M215.234 144.157C215.234 143.397 214.617 142.78 213.857 142.78C213.519 142.78 213.192 142.905 212.939 143.131'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.077 153.143C104.077 153.903 104.694 154.519 105.454 154.519'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.646 150.977C104.317 151.035 104.077 151.321 104.077 151.655'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.9883 145.712C79.9883 146.797 80.828 147.697 81.91 147.772'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.9102 147.772C101.43 149.129 120.99 149.807 140.557 149.807'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M65.8779 156.584C65.8779 157.725 66.8025 158.649 67.9427 158.649'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M68.1722 143.13C67.6056 142.623 66.7352 142.672 66.2286 143.239C66.0027 143.491 65.8779 143.818 65.8779 144.156'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.1788 60.2109C80.9917 60.3921 79.9848 61.1791 79.5225 62.2874'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.4919 56.0571C86.7594 56.1691 85.5589 56.4169 84.0947 57.149'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.756 56.4399C107.504 56.4399 106.253 56.5347 105.016 56.724'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.755 53.1357C107.336 53.1357 105.919 53.2434 104.516 53.4575'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.5078 87.2915C82.8142 88.9178 84.2348 90.0957 85.8896 90.0957'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.6123 142.13C79.7525 142.13 80.6771 141.205 80.6771 140.065'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.5942 65.4754C75.4716 64.8257 74.9042 64.355 74.2432 64.355'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.6766 94.4357C80.6766 93.1099 80.5529 91.7871 80.3076 90.4844'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M74.7627 132.821C74.7748 133.23 75.0508 133.585 75.4446 133.697'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.6777 73.3501C75.6338 73.1177 75.4101 72.9651 75.1781 73.009C74.9899 73.0441 74.8483 73.2001 74.8311 73.3904'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.3002 94.4358C79.3002 93.1957 79.1846 91.9581 78.9551 90.7393'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.2676 134.463C78.8379 134.463 79.3002 134 79.3002 133.43'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M65.8779 138C65.8779 141.802 68.9596 144.883 72.7608 144.883'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.9824 54.9761C76.6215 55.4893 74.1387 58.3798 74.1387 61.7799'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.755 50.9336C107.225 50.9336 105.696 51.0496 104.184 51.2806'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.755 46.8037C107.016 46.8037 105.28 46.9355 103.561 47.1979'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5156 50.7188C73.7044 51.6584 68.6309 57.4794 68.6309 64.3553'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.512 161.746C111.512 162.316 111.974 162.778 112.544 162.778'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.06 225.037C108.609 224.028 108.896 222.897 108.896 221.748'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M72.0421 181.61C70.9524 182.862 70.1855 185.431 70.1855 187.091'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.746 179.737C94.8579 176.948 84.3915 176.948 74.5039 179.737'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.569 162.778C169.14 162.778 169.602 162.316 169.602 161.746'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.5469 419.501C119.473 427.767 161.64 427.767 202.566 419.501'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.8906 417.477C76.8906 418.459 77.5832 419.306 78.5466 419.5'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.566 419.5C203.53 419.306 204.222 418.459 204.222 417.477'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.566 229.546C161.64 221.28 119.473 221.28 78.5469 229.546'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.5475 229.546C77.5841 229.741 76.8916 230.587 76.8916 231.57'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.221 231.571C204.221 230.588 203.529 229.741 202.565 229.547'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.912 243.307C151.912 241.216 150.218 239.521 148.127 239.521'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.986 239.521C130.895 239.521 129.2 241.216 129.2 243.307'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.127 255.351C150.218 255.351 151.912 253.657 151.912 251.566'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.2 251.566C129.2 253.657 130.895 255.352 132.986 255.352'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141.726 263.266C141.726 262.62 141.202 262.096 140.556 262.096C139.91 262.096 139.386 262.62 139.386 263.266'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.386 268.511C139.386 269.158 139.91 269.681 140.556 269.681C141.202 269.681 141.726 269.158 141.726 268.511'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.239 435.715C110.041 435.715 110.71 435.103 110.782 434.304'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.146 434.303C106.222 436.012 107.669 437.335 109.378 437.259C110.264 437.22 111.091 436.802 111.649 436.112'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.146 434.303C107.218 435.101 107.887 435.714 108.689 435.714'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.5756 442.308C97.2736 442.305 97.005 442.499 96.9131 442.786'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.4404 444.25C96.0393 444.25 96.5696 443.862 96.7516 443.292'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.7459 444.249C94.2722 444.249 93.8319 444.492 93.5801 444.894'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.617 447.51C102.566 447.324 102.44 447.168 102.27 447.079'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.604 446.381C101.627 446.613 101.766 446.817 101.973 446.924'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.546 445.789C101.47 445.008 101.127 444.276 100.577 443.716'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M92.0332 451.6C95.6467 453.729 99.4322 453.729 103.046 451.6'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.536 443.715C179.986 444.275 179.644 445.007 179.566 445.788'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.067 451.6C181.681 453.729 185.466 453.729 189.08 451.6'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.768 451.6C193.381 453.729 197.167 453.729 200.78 451.6'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.176 444.25C193.775 444.25 194.305 443.862 194.487 443.292'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.482 444.249C192.009 444.249 191.568 444.492 191.316 444.894'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.311 442.308C195.009 442.305 194.74 442.499 194.648 442.786'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.34 446.381C199.363 446.613 199.501 446.817 199.708 446.924'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.282 445.789C199.205 445.008 198.863 444.276 198.312 443.716'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.352 447.51C200.302 447.324 200.176 447.168 200.005 447.079'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.201 442.786C184.109 442.499 183.84 442.305 183.538 442.308'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.36 443.291C184.543 443.862 185.073 444.249 185.671 444.249'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.533 444.894C187.281 444.492 186.841 444.249 186.367 444.249'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.843 447.079C178.673 447.168 178.547 447.324 178.496 447.51'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.142 446.925C179.348 446.818 179.487 446.614 179.51 446.382'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.795 451.477C141.026 452.394 143.528 452.394 145.759 451.477'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.7976 444.894C89.5458 444.492 89.1055 444.249 88.6318 444.249'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.1071 447.079C80.9362 447.168 80.8103 447.324 80.7598 447.51'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.8003 443.715C82.2502 444.275 81.9083 445.007 81.8311 445.788'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.4062 446.925C81.6131 446.818 81.7514 446.614 81.7745 446.382'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.4653 442.786C86.3734 442.499 86.1047 442.305 85.8027 442.308'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.333 451.6C83.9465 453.729 87.732 453.729 91.3455 451.6'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.625 443.291C86.8073 443.862 87.3373 444.249 87.9361 444.249'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.612 448.792C130.121 448.792 130.588 448.512 130.827 448.063'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.755 448.063C122.994 448.512 123.461 448.792 123.969 448.792'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.703 445.006C118.703 445.386 119.011 445.694 119.391 445.694'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.189 445.694C134.569 445.694 134.878 445.386 134.878 445.006'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.878 442.941C134.878 442.561 134.569 442.253 134.189 442.253'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.391 442.253C119.011 442.253 118.703 442.561 118.703 442.941'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.556 450.413C125.615 451.095 126.216 451.599 126.898 451.54C127.497 451.487 127.973 451.012 128.025 450.413'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.474 442.372C138.474 443.522 139.406 444.454 140.556 444.454C141.706 444.454 142.638 443.522 142.638 442.372'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.282 442.891C197.332 442.41 196.418 441.418 195.436 441.173'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.502 450.412C204.432 448.686 205.642 446.298 205.894 443.722'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199 444.727C199.79 443.398 199.728 441.731 198.843 440.464'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85.4937 441.045C84.5698 441.045 83.8213 441.794 83.8213 442.717C83.8213 442.776 83.8242 442.834 83.8304 442.892'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.2197 443.722C75.4716 446.298 76.6824 448.686 78.6121 450.412'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.0553 440.679C88.1314 438.713 85.7888 437.867 83.8225 438.791C81.8562 439.715 81.011 442.058 81.9349 444.024C81.989 444.139 82.0487 444.251 82.1135 444.361'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.6276 439.021C86.3073 438.259 84.6806 438.259 83.3604 439.021'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.778 441.908C112.778 442.425 113.197 442.844 113.714 442.844C114.231 442.844 114.651 442.425 114.651 441.908'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.464 441.908C166.464 442.425 166.883 442.844 167.4 442.844C167.917 442.844 168.336 442.425 168.336 441.908'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M212.035 176.844C210.933 174.429 208.793 172.644 206.219 171.993'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M69.224 276.097C68.7072 276.097 68.2881 276.516 68.2881 277.033C68.2881 277.55 68.7072 277.969 69.224 277.969'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M211.89 277.969C212.406 277.969 212.826 277.55 212.826 277.033C212.826 276.516 212.406 276.097 211.89 276.097'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.429 292.358C208.086 292.167 210.954 289.147 210.954 285.484'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.429 294.232C209.121 294.04 212.826 290.18 212.826 285.484'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M70.1602 285.484C70.1602 289.147 73.0277 292.167 76.6851 292.358'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M70.9273 177.321C70.5378 178.074 70.2907 178.894 70.1992 179.738'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M69.224 179.737C68.7072 179.737 68.2881 180.156 68.2881 180.673C68.2881 181.19 68.7072 181.61 69.224 181.61'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M68.2881 285.484C68.2881 290.18 71.9931 294.04 76.6852 294.232'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M68.8781 177.321C68.4879 178.329 68.2881 179.4 68.2881 180.481'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M69.224 232.735C68.7072 232.735 68.2881 233.154 68.2881 233.671C68.2881 234.189 68.7072 234.608 69.224 234.608'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.0986 212.506C94.0986 213.267 94.715 213.883 95.4753 213.883'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.459 191.514C137.459 190.754 136.842 190.138 136.082 190.138'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.4743 190.137C94.7141 190.137 94.0977 190.754 94.0977 191.514'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.082 213.883C136.842 213.883 137.459 213.267 137.459 212.506'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.362 218.336C134.879 218.336 135.298 217.917 135.298 217.4C135.298 216.883 134.879 216.464 134.362 216.464'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.9555 216.464C99.4386 216.464 99.0195 216.883 99.0195 217.4C99.0195 217.917 99.4386 218.336 99.9555 218.336'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.7494 426.341C91.3021 426.627 88.7395 429.654 89.0257 433.101C89.3116 436.548 92.3383 439.111 95.7856 438.824C99.2329 438.539 101.796 435.512 101.509 432.065C101.24 428.818 98.5257 426.319 95.2673 426.319L94.7494 426.341Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M206.046 182.649C205.97 182.673 205.894 182.699 205.819 182.726'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M209.544 182.391C211.063 182.314 212.231 181.02 212.154 179.502C212.078 177.984 210.784 176.814 209.266 176.891C208.714 176.919 208.184 177.112 207.744 177.445'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M204.675 179.583C205.031 179.453 205.373 179.287 205.695 179.087'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M208.455 184.115C210.926 184.115 212.929 182.112 212.929 179.641C212.929 177.17 210.926 175.167 208.455 175.167'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.8369 204.97C74.3113 204.969 74.7795 204.862 75.207 204.657C75.6349 204.451 76.0156 204.159 76.3256 203.799C76.6356 203.44 76.8768 203.026 77.0364 202.579C77.1968 202.131 77.2787 201.66 77.2784 201.184C77.2787 200.71 77.1971 200.239 77.0372 199.793C76.8779 199.345 76.6375 198.932 76.3278 198.572C76.0174 198.212 75.6364 197.919 75.2081 197.712C74.7802 197.507 74.3117 197.4 73.8369 197.399C73.3626 197.4 72.8944 197.507 72.4669 197.712C72.039 197.918 71.6583 198.21 71.3483 198.57C71.0383 198.929 70.797 199.343 70.6375 199.79C70.4771 200.238 70.3951 200.709 70.3955 201.184C70.3951 201.659 70.4768 202.13 70.6367 202.576C70.7959 203.023 71.0364 203.437 71.3461 203.797C71.6565 204.157 72.0375 204.45 72.4658 204.657C72.8937 204.862 73.3622 204.969 73.8369 204.97Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.901 227.845C137.961 228.096 138.147 228.299 138.391 228.381C138.623 228.489 138.865 228.571 139.115 228.624C139.366 228.681 139.62 228.722 139.876 228.746C140.132 228.772 140.389 228.784 140.647 228.784C140.902 228.784 141.156 228.772 141.411 228.747C141.666 228.723 141.92 228.682 142.17 228.626C142.421 228.572 142.666 228.49 142.898 228.382C143.145 228.301 143.331 228.098 143.392 227.845C143.332 227.594 143.147 227.392 142.902 227.31C142.67 227.202 142.428 227.12 142.178 227.066C141.927 227.009 141.673 226.969 141.417 226.944C141.161 226.919 140.904 226.906 140.647 226.906C140.391 226.906 140.137 226.919 139.883 226.944C139.627 226.968 139.374 227.008 139.123 227.064C138.872 227.118 138.628 227.2 138.395 227.308C138.148 227.389 137.962 227.593 137.901 227.845Z'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.5742 442.308C97.6445 442.308 97.7148 442.311 97.7851 442.316C97.8553 442.321 97.9256 442.328 97.9952 442.337C98.0651 442.346 98.1346 442.357 98.2042 442.369C98.2733 442.383 98.3422 442.397 98.4106 442.414C98.4783 442.431 98.5453 442.45 98.6119 442.47C98.6785 442.49 98.7448 442.513 98.8103 442.536C98.8758 442.561 98.941 442.586 99.0054 442.614C99.0698 442.641 99.1335 442.67 99.1965 442.701C99.2594 442.731 99.3217 442.764 99.3832 442.797C99.445 442.831 99.5058 442.866 99.5658 442.903C99.6258 442.939 99.6851 442.977 99.7433 443.017C99.8019 443.056 99.8594 443.097 99.9161 443.139C99.9728 443.18 100.029 443.223 100.084 443.267C100.139 443.312 100.193 443.357 100.246 443.404C100.3 443.45 100.352 443.497 100.404 443.546C100.455 443.593 100.504 443.642 100.553 443.691'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M92.4131 447.69C92.4288 447.629 92.4449 447.567 92.4614 447.506C92.4782 447.445 92.4954 447.384 92.513 447.323C92.5309 447.263 92.5489 447.202 92.5676 447.141C92.5866 447.081 92.6056 447.02 92.6254 446.96C92.6452 446.9 92.6656 446.84 92.6865 446.78C92.7074 446.72 92.7286 446.66 92.7506 446.601C92.7725 446.541 92.7949 446.482 92.8179 446.423C92.841 446.364 92.8648 446.305 92.8886 446.247C92.9127 446.188 92.9376 446.13 92.9629 446.071C92.9881 446.013 93.0141 445.955 93.0405 445.898C93.0668 445.84 93.0939 445.783 93.1214 445.726C93.1488 445.669 93.177 445.612 93.2059 445.555C93.2345 445.499 93.2641 445.443 93.2941 445.387C93.3238 445.331 93.3545 445.275 93.3856 445.22C93.4171 445.165 93.4486 445.11 93.4812 445.056C93.5137 445.001 93.5467 444.947 93.5804 444.894'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.617 447.509C102.618 447.512 102.619 447.516 102.62 447.52C102.621 447.524 102.622 447.528 102.623 447.532C102.625 447.536 102.626 447.54 102.627 447.544C102.627 447.547 102.629 447.551 102.63 447.555C102.631 447.559 102.632 447.563 102.633 447.567C102.634 447.57 102.635 447.574 102.636 447.578C102.637 447.582 102.638 447.586 102.639 447.59C102.64 447.594 102.641 447.597 102.642 447.601C102.643 447.605 102.644 447.609 102.645 447.613C102.646 447.617 102.647 447.621 102.648 447.624C102.649 447.628 102.65 447.632 102.651 447.636C102.652 447.64 102.653 447.644 102.654 447.648C102.655 447.652 102.656 447.656 102.657 447.659C102.658 447.663 102.659 447.667 102.66 447.671C102.661 447.675 102.662 447.679 102.663 447.683C102.664 447.686 102.665 447.69 102.666 447.694'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.31 442.309C195.38 442.309 195.45 442.312 195.52 442.317C195.591 442.322 195.661 442.329 195.731 442.338C195.8 442.347 195.87 442.358 195.94 442.37C196.009 442.384 196.078 442.398 196.146 442.415C196.214 442.432 196.281 442.451 196.347 442.471C196.414 442.491 196.48 442.514 196.546 442.537C196.611 442.562 196.676 442.587 196.741 442.615C196.805 442.642 196.869 442.671 196.932 442.702C196.995 442.732 197.057 442.765 197.119 442.798C197.18 442.832 197.241 442.867 197.301 442.904C197.361 442.94 197.42 442.978 197.479 443.018C197.537 443.057 197.595 443.098 197.651 443.139C197.708 443.181 197.764 443.224 197.819 443.268C197.874 443.313 197.929 443.358 197.982 443.404C198.035 443.451 198.087 443.498 198.139 443.547C198.19 443.594 198.24 443.643 198.289 443.692'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M188.699 447.69C188.683 447.629 188.667 447.567 188.65 447.506C188.633 447.445 188.616 447.384 188.599 447.323C188.581 447.263 188.563 447.202 188.544 447.141C188.525 447.081 188.506 447.02 188.486 446.96C188.466 446.9 188.446 446.84 188.425 446.78C188.404 446.72 188.383 446.66 188.361 446.601C188.339 446.541 188.317 446.482 188.294 446.423C188.271 446.364 188.247 446.305 188.223 446.247C188.199 446.188 188.174 446.13 188.149 446.071C188.123 446.013 188.098 445.955 188.071 445.898C188.045 445.84 188.018 445.783 187.99 445.726C187.963 445.669 187.935 445.612 187.906 445.555C187.877 445.499 187.848 445.443 187.817 445.387C187.788 445.331 187.757 445.275 187.726 445.22C187.695 445.165 187.663 445.11 187.63 445.056C187.598 445.001 187.565 444.947 187.531 444.894'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.496 447.509C178.495 447.512 178.494 447.516 178.493 447.52C178.492 447.524 178.491 447.528 178.49 447.532C178.489 447.536 178.488 447.54 178.486 447.544C178.486 447.547 178.485 447.551 178.484 447.555C178.482 447.559 178.481 447.563 178.48 447.567C178.479 447.57 178.478 447.574 178.477 447.578C178.476 447.582 178.475 447.586 178.474 447.59C178.473 447.594 178.472 447.597 178.471 447.601C178.47 447.605 178.469 447.609 178.468 447.613C178.467 447.617 178.466 447.621 178.465 447.624C178.464 447.628 178.463 447.632 178.462 447.636C178.461 447.64 178.46 447.644 178.459 447.648C178.458 447.652 178.457 447.656 178.456 447.659C178.455 447.663 178.454 447.667 178.453 447.671C178.452 447.675 178.451 447.679 178.45 447.683C178.449 447.686 178.448 447.69 178.447 447.694'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M190.149 447.69C190.165 447.629 190.181 447.567 190.198 447.506C190.215 447.445 190.232 447.384 190.249 447.323C190.267 447.263 190.285 447.202 190.304 447.141C190.323 447.081 190.342 447.02 190.362 446.96C190.381 446.9 190.402 446.84 190.423 446.78C190.444 446.72 190.465 446.66 190.487 446.601C190.509 446.541 190.531 446.482 190.554 446.423C190.577 446.364 190.601 446.305 190.625 446.247C190.649 446.188 190.674 446.13 190.699 446.071C190.724 446.013 190.75 445.955 190.777 445.898C190.803 445.84 190.83 445.783 190.858 445.726C190.885 445.669 190.913 445.612 190.942 445.555C190.971 445.499 191 445.443 191.03 445.387C191.06 445.331 191.091 445.275 191.122 445.22C191.153 445.165 191.185 445.11 191.217 445.056C191.25 445.001 191.283 444.947 191.317 444.894'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.539 442.309C183.468 442.309 183.398 442.312 183.328 442.317C183.258 442.322 183.187 442.329 183.118 442.338C183.048 442.347 182.978 442.358 182.909 442.37C182.84 442.384 182.771 442.398 182.702 442.415C182.635 442.432 182.568 442.451 182.501 442.471C182.434 442.491 182.368 442.514 182.303 442.537C182.237 442.562 182.172 442.587 182.108 442.615C182.043 442.642 181.979 442.671 181.916 442.702C181.853 442.732 181.791 442.765 181.73 442.798C181.668 442.832 181.607 442.867 181.547 442.904C181.487 442.94 181.428 442.978 181.37 443.018C181.311 443.057 181.254 443.098 181.197 443.139C181.14 443.181 181.084 443.224 181.029 443.268C180.974 443.313 180.92 443.358 180.867 443.404C180.813 443.451 180.761 443.498 180.709 443.547C180.658 443.594 180.609 443.643 180.56 443.692'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.352 447.509C200.353 447.512 200.354 447.516 200.355 447.52C200.356 447.524 200.357 447.528 200.358 447.532C200.359 447.536 200.36 447.54 200.361 447.544C200.362 447.547 200.363 447.551 200.364 447.555C200.365 447.559 200.366 447.563 200.367 447.567C200.368 447.57 200.369 447.574 200.37 447.578C200.371 447.582 200.372 447.586 200.373 447.59C200.374 447.594 200.375 447.597 200.376 447.601C200.377 447.605 200.378 447.609 200.379 447.613C200.38 447.617 200.382 447.621 200.382 447.624C200.383 447.628 200.385 447.632 200.386 447.636C200.386 447.64 200.387 447.644 200.389 447.648C200.39 447.652 200.39 447.656 200.391 447.659C200.393 447.663 200.393 447.667 200.394 447.671C200.395 447.675 200.397 447.679 200.397 447.683C200.398 447.686 200.4 447.69 200.4 447.694'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.7606 447.509C80.7595 447.512 80.7584 447.516 80.7573 447.52C80.7566 447.524 80.7555 447.528 80.7544 447.532C80.7533 447.536 80.7522 447.54 80.7511 447.544C80.7503 447.547 80.7492 447.551 80.7482 447.555C80.7471 447.559 80.746 447.563 80.7449 447.567C80.7441 447.57 80.743 447.574 80.7419 447.578C80.7408 447.582 80.7397 447.586 80.739 447.59C80.7379 447.594 80.7368 447.597 80.7357 447.601C80.7346 447.605 80.7339 447.609 80.7328 447.613C80.7317 447.617 80.7306 447.621 80.7298 447.624C80.7288 447.628 80.7277 447.632 80.7266 447.636C80.7258 447.64 80.7247 447.644 80.7236 447.648C80.7225 447.652 80.7218 447.656 80.7207 447.659C80.7196 447.663 80.7189 447.667 80.7178 447.671C80.7167 447.675 80.7156 447.679 80.7148 447.683C80.7137 447.686 80.7126 447.69 80.7119 447.694'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85.8034 442.308C85.7331 442.308 85.6628 442.311 85.5925 442.316C85.5222 442.321 85.452 442.328 85.3824 442.337C85.3125 442.346 85.243 442.357 85.1734 442.369C85.1042 442.383 85.0354 442.397 84.967 442.414C84.8993 442.431 84.8323 442.45 84.7657 442.47C84.699 442.491 84.6328 442.513 84.5673 442.536C84.5017 442.561 84.4366 442.586 84.3722 442.614C84.3077 442.641 84.2441 442.67 84.1811 442.701C84.1181 442.731 84.0559 442.764 83.9941 442.797C83.9326 442.831 83.8718 442.866 83.8118 442.903C83.7517 442.939 83.6924 442.977 83.6342 443.017C83.5757 443.056 83.5182 443.097 83.4615 443.139C83.4047 443.18 83.3487 443.223 83.2938 443.267C83.2386 443.312 83.1844 443.357 83.131 443.404C83.0779 443.45 83.0255 443.497 82.9739 443.546C82.923 443.593 82.8733 443.642 82.8242 443.691'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M90.9642 447.69C90.9484 447.629 90.9323 447.567 90.9158 447.506C90.899 447.445 90.8818 447.384 90.8642 447.323C90.8463 447.263 90.8284 447.202 90.8097 447.141C90.7907 447.081 90.7716 447.02 90.7519 446.96C90.7321 446.9 90.7116 446.84 90.6907 446.78C90.6699 446.72 90.6486 446.66 90.6267 446.601C90.6047 446.541 90.5824 446.482 90.5593 446.423C90.5363 446.364 90.5125 446.305 90.4887 446.247C90.4645 446.188 90.4396 446.13 90.4144 446.071C90.3891 446.013 90.3631 445.955 90.3368 445.898C90.3104 445.84 90.2833 445.783 90.2559 445.726C90.2284 445.669 90.2002 445.612 90.1713 445.555C90.1428 445.499 90.1131 445.443 90.0831 445.387C90.0535 445.331 90.0227 445.275 89.9916 445.22C89.9601 445.165 89.9286 445.11 89.8961 445.056C89.8635 445.001 89.8305 444.947 89.7969 444.894'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.732 178.034C207.844 177.994 207.961 177.973 208.079 177.971C208.197 177.969 208.314 177.985 208.428 178.016C208.541 178.048 208.65 178.092 208.753 178.149C208.857 178.206 208.954 178.272 209.045 178.346C209.136 178.421 209.221 178.502 209.299 178.59C209.377 178.678 209.449 178.771 209.514 178.868C209.579 178.965 209.638 179.066 209.691 179.171C209.744 179.275 209.79 179.383 209.83 179.493C209.87 179.603 209.903 179.716 209.929 179.83C209.956 179.944 209.975 180.06 209.987 180.177C209.999 180.293 210.003 180.411 209.999 180.528C209.995 180.645 209.982 180.761 209.959 180.876C209.937 180.991 209.904 181.104 209.861 181.213C209.818 181.321 209.763 181.425 209.697 181.521C209.63 181.617 209.55 181.703 209.459 181.776C209.369 181.85 209.266 181.907 209.157 181.947L208.869 182.052C208.513 182.182 208.144 182.274 207.769 182.328'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M175.211 171.038L174.041 171.038'
          stroke='black'
          strokeWidth='0.549049'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_120_3875'>
        <rect width='500' height='281.113' fill='white' transform='translate(281.113) rotate(90)' />
      </clipPath>
    </defs>
  </svg>
);
