export const ConeSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width='56' height='60' viewBox='0 0 56 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M30.4498 1.96793C29.4884 -0.655976 25.7774 -0.655976 24.816 1.96793L19.3573 16.8667H35.9085L30.4498 1.96793Z'
      fill='#808080'
    />
    <path d='M38.6115 24.2439H16.6544L13.2606 33.5067H42.0052L38.6115 24.2439Z' fill='#808080' />
    <path
      d='M6.55135 51.8187L10.5577 40.8839H44.7081L48.7145 51.8187H55.714V60.0014H0V51.8187H6.55135Z'
      fill='#808080'
    />
  </svg>
);
