import { Checkbox as HeadlessUICheckbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/16/solid';

type CheckboxProps = {
  checked: boolean;
  onChange: (isChecked: boolean) => void;
};

export const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange }) => (
  <HeadlessUICheckbox
    checked={checked}
    onChange={onChange}
    onClick={event => event.stopPropagation()}
    className='group relative flex h-4 w-4 rounded border-2 border-system-500 bg-white transition data-[checked]:border-brand data-[checked]:bg-brand'
  >
    <CheckIcon className='h5 absolute -left-1 -top-1 hidden w-5 fill-white group-data-[checked]:block' />
  </HeadlessUICheckbox>
);
