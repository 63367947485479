import { useApi } from './useApiHooks';
import type { OrganizationUserRole, ProjectUserRole, UserTypes } from '../../const';

export interface CurrentUser {
  userId: string;
  email: string;
  organizationIds: string[];
  roles: {
    organizations: { [key: string]: OrganizationUserRole };
    projects: { [key: string]: ProjectUserRole };
  };
  type: UserTypes;
  givenName?: string;
  familyName?: string;
  jobTitle?: string;
  phoneNumber?: string;
  timezone?: string;
}

export interface GetUserResponse {
  results: CurrentUser;
}

export const useGetUser = (disableQuery?: boolean) =>
  useApi<GetUserResponse>('get', disableQuery ? null : `/users/current`);
