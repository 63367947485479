import { createContext } from 'react';

import type { Filters } from '../types';

interface SelectedFiltersContextType {
  selectedFilters: Filters;
  setSelectedFilters: (filters: Filters) => void;
}

export const SelectedFiltersContext = createContext<SelectedFiltersContextType>({
  selectedFilters: {},
  setSelectedFilters: () => void 0
});
