const style: React.CSSProperties = { maskType: 'luminance' };

export const BackhoeTopSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='282'
    height='500'
    viewBox='0 0 282 500'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_95_559)'>
      <path
        d='M123.083 110.815L123.083 110.214'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M128.693 110.815L128.693 110.214'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M152.64 110.214L152.64 110.815'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M158.25 110.815L158.25 110.214'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M164.403 200.589L164.403 79.9585'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M116.93 200.589L116.93 79.9585'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M111.828 87.8101L111.828 137.089'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M169.505 137.089L169.505 87.8105'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M202.966 78.6729L140.667 78.6729'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M171.318 79.9585L202.966 79.9585'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M202.966 74.7241L140.667 74.7241'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M184.043 33.0166L185.975 33.0166'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M187.906 33.0166L184.043 33.0166'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M200.7 62.2808L80.6333 62.2808'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M185.319 20.498L184.043 22.5174'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M80.6333 74.7241L80.6333 28.4858'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M78.3677 28.4858L78.3677 79.9588'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask
        id='mask0_95_559'
        style={style}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='282'
        height='500'
      >
        <path
          d='M281.223 1.22878e-05L281.223 500L0.11033 500L0.110352 0L281.223 1.22878e-05Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_95_559)'>
        <path
          d='M140.666 31.2289C141.214 31.2241 141.654 30.7761 141.65 30.2282C141.645 29.6868 141.207 29.249 140.666 29.2446'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.262 33.0166L146.33 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.398 33.0166L146.33 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.321 31.2341C146.869 31.2341 147.314 30.7898 147.314 30.2418C147.314 29.6939 146.869 29.2495 146.321 29.2495C145.773 29.2495 145.329 29.6939 145.329 30.2418C145.329 30.7898 145.773 31.2341 146.321 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.925 33.0161L151.993 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.062 33.0166L151.993 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.985 31.2341C152.533 31.2341 152.977 30.7898 152.977 30.2418C152.977 29.6939 152.533 29.2495 151.985 29.2495C151.437 29.2495 150.993 29.6939 150.993 30.2418C150.993 30.7898 151.437 31.2341 151.985 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.925 33.0161L150.062 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.261 33.0166L144.398 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.598 33.0112L140.667 33.0112'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.734 33.0112L140.666 33.0112'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.071 33.0166L136.935 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.407 33.0161L131.271 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.744 33.0166L125.607 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.081 33.0161L119.944 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.417 33.0161L114.28 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.753 33.0161L108.617 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.0898 33.0166L102.953 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M93.4268 33.0161L97.2902 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.7028 31.2341C90.2507 31.2341 90.6951 30.7898 90.6951 30.2418C90.6951 29.6939 90.2507 29.2495 89.7028 29.2495C89.1548 29.2495 88.7104 29.6939 88.7104 30.2418C88.7104 30.7898 89.1548 31.2341 89.7028 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.6267 33.0161L89.6948 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.3663 31.2341C95.9143 31.2341 96.3586 30.7898 96.3586 30.2418C96.3586 29.6939 95.9143 29.2495 95.3663 29.2495C94.8184 29.2495 94.374 29.6939 94.374 30.2418C94.374 30.7898 94.8184 31.2341 95.3663 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.2898 33.0166L95.3579 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M93.4268 33.0161L95.3587 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.03 31.2341C101.578 31.2341 102.023 30.7898 102.023 30.2418C102.023 29.6939 101.578 29.2495 101.03 29.2495C100.482 29.2495 100.038 29.6939 100.038 30.2418C100.038 30.7898 100.482 31.2341 101.03 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.953 33.0166L101.021 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.0898 33.0166L101.022 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.694 31.2341C107.242 31.2341 107.686 30.7898 107.686 30.2418C107.686 29.6939 107.242 29.2495 106.694 29.2495C106.146 29.2495 105.702 29.6939 105.702 30.2418C105.702 30.7898 106.146 31.2341 106.694 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.617 33.0161L106.685 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.753 33.0161L106.685 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.358 31.2341C112.905 31.2341 113.35 30.7898 113.35 30.2418C113.35 29.6939 112.905 29.2495 112.358 29.2495C111.81 29.2495 111.365 29.6939 111.365 30.2418C111.365 30.7898 111.81 31.2341 112.358 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.281 33.0166L112.349 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.417 33.0161L112.349 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.021 31.2336C118.569 31.2336 119.013 30.7893 119.013 30.2413C119.013 29.6934 118.569 29.249 118.021 29.249C117.473 29.249 117.028 29.6934 117.028 30.2413C117.028 30.7893 117.473 31.2336 118.021 31.2336Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.944 33.0161L118.012 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.081 33.0161L118.012 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.684 31.2336C124.232 31.2336 124.677 30.7893 124.677 30.2413C124.677 29.6934 124.232 29.249 123.684 29.249C123.136 29.249 122.692 29.6934 122.692 30.2413C122.692 30.7893 123.136 31.2336 123.684 31.2336Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.608 33.0166L123.676 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.744 33.0166L123.676 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.348 31.2341C129.896 31.2341 130.34 30.7898 130.34 30.2418C130.34 29.6939 129.896 29.2495 129.348 29.2495C128.8 29.2495 128.355 29.6939 128.355 30.2418C128.355 30.7898 128.8 31.2341 129.348 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.271 33.0161L129.339 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.407 33.0161L129.339 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.011 31.2336C135.559 31.2336 136.004 30.7893 136.004 30.2413C136.004 29.6934 135.559 29.249 135.011 29.249C134.463 29.249 134.019 29.6934 134.019 30.2413C134.019 30.7893 134.463 31.2336 135.011 31.2336Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.934 33.0161L135.002 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.071 33.0166L135.003 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 29.2446C140.118 29.2494 139.678 29.6974 139.682 30.2454C139.687 30.7867 140.125 31.2245 140.666 31.2289'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.589 33.0161L157.657 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.725 33.0161L157.657 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.649 31.2341C158.197 31.2341 158.641 30.7898 158.641 30.2418C158.641 29.6939 158.197 29.2495 157.649 29.2495C157.101 29.2495 156.656 29.6939 156.656 30.2418C156.656 30.7898 157.101 31.2341 157.649 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.252 33.0166L163.32 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.389 33.0161L163.321 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.312 31.2341C163.86 31.2341 164.304 30.7898 164.304 30.2418C164.304 29.6939 163.86 29.2495 163.312 29.2495C162.764 29.2495 162.319 29.6939 162.319 30.2418C162.319 30.7898 162.764 31.2341 163.312 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.916 33.0166L168.984 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.052 33.0166L168.984 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.976 31.2336C169.524 31.2336 169.968 30.7893 169.968 30.2413C169.968 29.6934 169.524 29.249 168.976 29.249C168.428 29.249 167.983 29.6934 167.983 30.2413C167.983 30.7893 168.428 31.2336 168.976 31.2336Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.579 33.0166L174.647 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.716 33.0161L174.648 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.639 31.2336C175.187 31.2336 175.632 30.7893 175.632 30.2413C175.632 29.6934 175.187 29.249 174.639 29.249C174.091 29.249 173.647 29.6934 173.647 30.2413C173.647 30.7893 174.091 31.2336 174.639 31.2336Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.242 33.0166L180.311 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.379 33.0161L180.311 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.303 31.2341C180.851 31.2341 181.295 30.7898 181.295 30.2418C181.295 29.6939 180.851 29.2495 180.303 29.2495C179.755 29.2495 179.311 29.6939 179.311 30.2418C179.311 30.7898 179.755 31.2341 180.303 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.243 33.0161L178.379 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.579 33.0161L172.716 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.916 33.0166L167.052 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.252 33.0161L161.389 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.589 33.0161L155.726 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.0397 31.2341C84.5876 31.2341 85.032 30.7898 85.032 30.2418C85.032 29.6939 84.5876 29.2495 84.0397 29.2495C83.4917 29.2495 83.0474 29.6939 83.0474 30.2418C83.0474 30.7898 83.4917 31.2341 84.0397 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85.9632 33.0161L84.0312 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.0996 33.0166L84.0315 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.0996 33.0166L85.9631 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.7632 33.0161L91.6267 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.7632 33.0161L89.6951 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.725 28.4858L153.925 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 20.4976L138.734 22.5118'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.734 22.5122L138.734 28.4859'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.734 28.4858L136.934 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.935 28.4861L136.935 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.935 22.5174L135.658 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.658 20.498L134.347 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.348 20.4976L133.071 22.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.071 22.5171L133.071 28.4856'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.071 28.4858L131.271 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.271 28.4856L131.271 22.5171'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.271 22.5174L129.995 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.994 20.4976L128.684 20.4976'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.684 20.498L127.407 22.5174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.407 22.5176L127.407 28.4861'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.408 28.4858L125.607 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.608 28.4856L125.608 22.5171'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.608 22.5174L124.332 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.331 20.498L123.021 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.02 20.498L121.744 22.5174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.744 22.5176L121.744 28.4861'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.744 28.4858L119.944 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.944 28.4856L119.944 22.5171'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.944 22.5174L118.668 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.668 20.498L117.357 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.357 20.498L116.081 22.5174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.081 22.5176L116.081 28.4861'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.081 28.4858L114.281 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.28 28.4861L114.28 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.28 22.5174L113.004 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.004 20.498L111.693 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.693 20.498L110.417 22.5174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.417 22.5176L110.417 28.4861'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.417 28.4858L108.617 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.617 28.4861L108.617 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.617 22.5174L107.34 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.341 20.498L106.03 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.03 20.498L104.753 22.5174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.753 22.5176L104.753 28.4861'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.754 28.4858L102.954 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.954 28.4861L102.954 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.954 22.5174L101.677 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.677 20.4976L100.367 20.4976'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.366 20.498L99.0898 22.5174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.0898 22.5176L99.0898 28.4861'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.0897 28.4858L97.2896 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.29 28.4861L97.29 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.29 22.5174L96.0137 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.0139 20.4976L94.7031 20.4976'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.7026 20.498L93.4263 22.5174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M93.4263 22.5176L93.4263 28.4861'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M93.4261 28.4858L91.626 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.6265 28.4861L91.6265 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.6265 22.5174L90.3501 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M90.3498 20.498L89.0391 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.925 28.4861L153.925 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.925 22.5174L152.649 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.649 20.4976L151.338 20.4976'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.338 20.498L150.062 22.5174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.062 22.5171L150.062 28.4856'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.062 28.4858L148.262 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.262 28.4861L148.262 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.262 22.5174L146.985 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.986 20.4976L145.675 20.4976'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.675 20.4976L144.398 22.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.398 22.5171L144.398 28.4856'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.398 28.4858L142.598 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.598 28.4859L142.598 22.5122'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.598 22.5123L140.667 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.042 22.5176L184.042 28.4861'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.043 28.4858L182.243 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.243 28.4856L182.243 22.5171'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.243 22.5174L180.967 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.967 20.498L179.656 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.655 20.498L178.379 22.5174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.379 22.5176L178.379 28.4861'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.379 28.4858L176.579 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.58 28.4856L176.58 22.5171'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.58 22.5174L175.303 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.303 20.498L173.992 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M173.992 20.498L172.716 22.5174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.716 22.5176L172.716 28.4861'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.716 28.4858L170.916 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.916 28.4856L170.916 22.5171'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.916 22.5174L169.639 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.639 20.498L168.329 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.329 20.498L167.052 22.5174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.052 22.5176L167.052 28.4861'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.052 28.4858L165.252 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.252 28.4861L165.252 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.252 22.5174L163.976 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.976 20.498L162.665 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.665 20.498L161.389 22.5174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.389 22.5176L161.389 28.4861'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.389 28.4858L159.589 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.588 28.4861L159.588 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.589 22.5174L158.312 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.313 20.4976L157.002 20.4976'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.001 20.498L155.725 22.5174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.725 22.5176L155.725 28.4861'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.7632 22.5171L87.7632 28.4856'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.7625 28.4858L85.9624 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85.9629 28.4861L85.9629 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85.9629 22.5174L84.6865 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.6862 20.498L83.3755 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.376 20.4976L82.0996 22.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.0996 22.5171L82.0996 28.4856'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.0994 28.4858L78.3677 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.0395 20.4976L87.7632 22.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.598 33.0115L142.598 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.262 33.0166L148.262 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.398 33.0166L144.398 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.925 33.0166L153.925 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.062 33.0161L150.062 28.4854'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85.9629 33.0166L85.9629 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.0996 33.0166L82.0996 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.6265 33.0166L91.6265 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.7632 33.0166L87.7632 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.29 33.0166L97.29 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M93.4268 33.0166L93.4268 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.954 33.0166L102.954 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.0898 33.0166L99.0898 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.617 33.0166L108.617 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.753 33.0166L104.753 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.28 33.0166L114.28 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.417 33.0166L110.417 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.944 33.0166L119.944 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.081 33.0166L116.081 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.608 33.0166L125.608 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.744 33.0166L121.744 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.271 33.0161L131.271 28.4854'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.407 33.0166L127.407 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.935 33.0166L136.935 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.071 33.0166L133.071 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.734 33.0115L138.734 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.588 33.0166L159.588 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.725 33.0166L155.725 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.252 33.0166L165.252 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.389 33.0166L161.389 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.916 33.0166L170.916 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.052 33.0166L167.052 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.58 33.0166L176.58 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.716 33.0166L172.716 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.243 33.0166L182.243 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.379 33.0166L178.379 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.042 33.0166L184.042 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.766 97.8564L123.766 85.5918'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.011 97.8564L128.011 85.5918'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.322 85.5918L153.322 97.8564'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.567 97.8564L157.567 85.5918'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.761 81.5356L153.127 81.5356'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.761 79.9585L153.127 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.128 85.5915L153.128 78.6724'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.205 85.5915L128.205 78.6724'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.571 79.9585L128.205 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.571 85.5918L123.766 85.5918'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.571 81.5356L128.205 81.5356'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.571 87.1106L123.571 78.6729'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.077 87.1108L123.571 87.1108'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.077 78.6729L121.077 87.1106'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.828 79.9585L121.077 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.93 79.9585L118.743 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.93 87.8105L118.743 87.8105'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.742 87.8103L118.742 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.015 79.9585L111.828 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.828 87.8103L111.828 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.015 79.9585L110.015 87.8103'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.015 87.8105L111.828 87.8105'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.01 85.5918L128.205 85.5918'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.205 85.5918L128.205 87.1105'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.205 87.1108L130.7 87.1108'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.699 87.1104L130.699 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.699 79.9585L150.633 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.633 79.9585L150.633 87.1104'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.633 87.1108L153.127 87.1108'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.128 87.1105L153.128 85.5918'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.128 85.5918L153.323 85.5918'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M171.318 79.9585L169.505 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.59 87.8103L162.59 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.403 87.8105L162.59 87.8105'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.403 79.9585L162.59 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 79.9585L160.256 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.256 78.6729L160.256 87.1106'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.256 87.1104L157.762 87.1104'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.761 87.1106L157.761 78.6729'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.762 85.5918L157.567 85.5918'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 87.8103L169.505 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 87.8105L171.318 87.8105'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M171.318 87.8103L171.318 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.3677 78.6729L140.666 78.6729'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.3677 79.9585L110.015 79.9585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.3677 74.7241L140.666 74.7241'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 110.214L158.794 110.214'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 109.132L158.794 109.132'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 108.532L158.794 108.532'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 107.477L158.794 107.477'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 106.881L158.794 106.881'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.083 107.477L123.083 106.881'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.539 108.532L122.539 107.477'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 106.881L122.539 106.881'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 107.477L122.539 107.477'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 108.532L122.539 108.532'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.083 109.132L123.083 108.532'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.539 110.213L122.539 109.132'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 109.132L122.539 109.132'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 110.214L122.539 110.214'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 110.214L129.237 110.214'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.238 110.213L129.238 109.132'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.238 109.132L128.693 109.132'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 109.132L128.693 108.532'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 108.532L129.237 108.532'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.237 108.532L129.237 107.477'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.238 107.477L128.693 107.477'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 107.477L128.693 106.881'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 106.881L129.237 106.881'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.095 106.881L152.64 106.881'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 106.881L152.64 107.477'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 107.477L152.095 107.477'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.095 107.477L152.095 108.532'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.095 108.532L152.64 108.532'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 108.532L152.64 109.132'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 109.132L152.095 109.132'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.095 109.132L152.095 110.214'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.095 110.214L152.64 110.214'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.794 110.214L158.794 109.132'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.25 109.132L158.25 108.532'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.794 108.532L158.794 107.477'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.25 107.477L158.25 106.881'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.794 97.8564L152.095 97.8564'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.539 97.8564L128.011 97.8564'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.238 97.8564L128.011 97.8564'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.322 97.8564L152.095 97.8564'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.539 106.881L122.539 97.8564'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.237 106.881L129.237 97.8564'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.095 97.8564L152.095 106.881'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.794 106.881L158.794 97.8564'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.7 74.7241L200.7 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.965 79.9588L202.965 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.234 33.0166L195.37 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.906 33.0166L185.974 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.966 31.2336C186.514 31.2336 186.958 30.7893 186.958 30.2413C186.958 29.6934 186.514 29.249 185.966 29.249C185.418 29.249 184.974 29.6934 184.974 30.2413C184.974 30.7893 185.418 31.2336 185.966 31.2336Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.57 33.0161L191.638 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.707 33.0166L191.638 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.63 31.2341C192.177 31.2341 192.622 30.7898 192.622 30.2418C192.622 29.6939 192.177 29.2495 191.63 29.2495C191.082 29.2495 190.637 29.6939 190.637 30.2418C190.637 30.7898 191.082 31.2341 191.63 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.234 33.0161L197.302 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.37 33.0161L197.302 33.0161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.293 31.2341C197.841 31.2341 198.285 30.7898 198.285 30.2418C198.285 29.6939 197.841 29.2495 197.293 29.2495C196.745 29.2495 196.301 29.6939 196.301 30.2418C196.301 30.7898 196.745 31.2341 197.293 31.2341Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.57 33.0166L189.706 33.0166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.965 28.4858L199.233 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.233 28.4861L199.233 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.233 22.5174L197.957 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.957 20.4976L196.646 20.4976'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.646 20.4976L195.37 22.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.37 22.5171L195.37 28.4856'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.37 28.4858L193.57 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.57 28.4861L193.57 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.57 22.5174L192.293 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.294 20.498L190.983 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M190.983 20.4976L189.707 22.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.707 22.5171L189.707 28.4856'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.707 28.4858L187.907 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.906 28.4861L187.906 22.5176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.906 22.5174L186.63 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M186.63 20.498L185.319 20.498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.906 33.0166L187.906 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.57 33.0166L193.57 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.707 33.0166L189.707 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.233 33.0166L199.233 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.37 33.0166L195.37 28.4858'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.591 395.72L133.591 427.369'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.742 427.369L147.742 395.72'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.34 405.735L146.34 426.647'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.993 405.735L134.993 426.647'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6943 263.795C88.0977 263.838 86.5047 263.968 84.9224 264.187'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6943 263.795L84.9224 264.187'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.487 270.893L115.487 246.637'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.656 273.725L112.656 243.805'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.956 275.423L110.956 242.105'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.376 275.423L170.376 242.105'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.677 273.725L168.677 243.805'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.846 270.893L165.846 246.637'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.647 322.495L174.647 231.13'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 341.566L191.638 229.431'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6948 341.566L89.6948 229.431'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.685 322.495L106.685 231.13'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 255.293L174.647 255.293'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 231.13L191.638 231.13'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 229.43L157.657 229.43'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 190.729L93.2817 179.401'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 189.127L93.2822 177.799'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.522 192.969L121.522 179.622'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 189.127L108.651 185.085'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 190.728L108.651 186.687'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 185.122L104.609 189.126'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 185.122L104.609 189.126'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 185.122L104.609 189.126'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 185.122L104.609 189.126'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 196.335L93.2817 185.008'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 194.733L93.2822 183.406'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 179.621L159.81 192.969'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.651 199.319L108.651 178.212'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.676 201.744L123.676 179.621'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.676 179.621L123.676 201.744'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.682 199.319L172.682 178.212'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M93.2817 196.221L93.2817 149.494'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.828 199.319L111.828 137.089'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.522 192.969L121.522 130.739'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M92.5688 149.72L92.5688 195.508'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 192.969L159.81 130.739'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 199.319L169.505 137.089'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.657 230.968L157.657 179.621'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.657 229.431L157.657 179.621'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.783 149.154L105.783 147.812'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.209 148.48L102.024 148.898'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.024 148.897L101.083 147.955'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 148.023L104.609 149.88'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 149.88L105.783 148.706'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 149.88L102.577 147.848'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 157.089L96.2339 148.714'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 155.487L97.5649 148.443'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 151.482L101.083 147.956'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.745 161.219L106.745 144.074'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.676 167.169L123.676 148.491'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.5649 148.442L99.2011 150.079'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.7943 150.274L96.2339 148.714'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.2339 148.714L92.5688 149.72'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.657 167.169L157.657 148.491'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.588 161.219L174.588 144.074'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.794 132.525L158.794 114.209'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.539 132.525L122.539 114.209'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.095 114.209L152.095 132.525'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.237 132.525L129.237 114.209'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 114.209L158.794 114.209'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 113.668L158.794 113.668'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 112.527L158.794 112.527'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 111.956L158.794 111.956'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 110.814L158.794 110.814'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.539 111.956L122.539 110.814'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.083 112.527L123.083 111.956'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.539 113.669L122.539 112.527'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.083 114.209L123.083 113.668'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 110.814L122.539 110.814'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 111.956L122.539 111.956'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 112.527L122.539 112.527'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 113.668L122.539 113.668'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 114.209L122.539 114.209'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.828 118.674L116.93 118.674'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.238 111.956L129.238 110.814'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.238 110.814L128.693 110.814'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 110.814L152.095 110.814'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.095 110.814L152.095 111.956'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.095 111.956L152.64 111.956'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 111.956L152.64 112.527'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 112.527L152.095 112.527'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.095 112.527L152.095 113.669'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.095 113.668L152.64 113.668'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 113.668L152.64 114.209'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.64 114.209L152.095 114.209'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.238 114.209L128.693 114.209'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 114.209L128.693 113.668'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 113.668L129.237 113.668'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.237 113.669L129.237 112.527'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.238 112.527L128.693 112.527'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 112.527L128.693 111.956'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.693 111.956L129.237 111.956'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 118.674L164.403 118.674'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.25 114.209L158.25 113.668'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.794 113.669L158.794 112.527'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.25 112.527L158.25 111.956'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.794 111.956L158.794 110.814'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 136.517L151.57 136.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 140.702L140.667 140.702'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 147.868L140.667 147.868'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 148.491L140.667 148.491'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 145.687L140.667 145.687'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 139.767L140.667 139.767'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 132.525L152.095 132.525'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.504 135.95L145.255 135.95'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.244 136.517L147.244 135.95'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.515 136.517L145.515 135.95'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.255 135.95L145.255 136.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.078 135.95L136.078 136.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.818 136.517L135.818 135.95'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.089 136.517L134.089 135.95'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.83 135.95L133.83 136.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.83 135.95L136.078 135.95'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.522 132.525L129.237 132.525'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.676 145.688L123.676 140.702'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.522 139.768L140.666 139.768'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.522 145.688L140.666 145.688'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.522 148.492L140.666 148.492'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.522 147.868L140.666 147.868'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.522 140.702L140.666 140.702'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.828 147.249L116.93 147.249'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.827 139.629L106.745 139.629'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.522 130.739L116.929 130.739'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.522 136.517L129.763 136.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.504 136.517L145.255 136.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.83 136.517L136.078 136.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.827 137.089L106.745 137.089'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.745 137.089L106.745 144.074'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.745 144.074L105.783 144.074'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.783 144.074L105.783 147.813'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.783 147.813L102.577 147.848'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.577 147.848L103.208 148.48'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.082 147.956L97.5645 148.443'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.095 132.525L151.57 132.525'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.57 132.525L151.57 136.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.57 136.517L147.503 136.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.503 136.517L147.503 135.95'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.503 135.95L148.022 135.95'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.022 135.95L149.011 134.653'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.011 134.653L149.011 134.135'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.01 134.135L143.748 134.135'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.748 134.135L143.748 134.653'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.748 134.653L144.736 135.95'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.736 135.95L145.255 135.95'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.255 135.95L145.255 136.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.255 136.517L136.078 136.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.078 136.517L136.078 135.95'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.078 135.95L136.596 135.95'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.597 135.95L137.585 134.653'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.585 134.653L137.585 134.135'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.585 134.135L132.322 134.135'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.322 134.135L132.322 134.653'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.322 134.653L133.829 135.95'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.83 135.95L133.83 136.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.829 136.517L129.763 136.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.763 136.516L129.763 132.524'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.763 132.525L129.238 132.525'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 130.739L164.403 130.739'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 139.629L174.588 139.629'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 147.249L164.403 147.249'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.657 145.687L157.657 140.702'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.55 147.812L175.55 144.074'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.549 144.074L174.587 144.074'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.588 144.074L174.588 137.089'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.588 137.089L169.505 137.089'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 168.301L108.651 164.26'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 172.307L93.2822 160.98'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 168.302L93.2822 156.975'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.651 172.553L108.651 162.457'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.682 172.553L172.682 162.457'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 177.913L93.2817 166.586'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 173.909L93.2817 162.582'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 167.169L140.667 167.169'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 155.487L106.745 153.351'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 157.089L106.745 154.953'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 161.093L106.745 158.958'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 162.695L106.745 160.56'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 166.7L108.651 162.658'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 162.695L104.609 166.7'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 157.088L104.609 161.093'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 151.482L104.609 155.487'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.265 156.434L105.265 154.832'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.265 162.04L105.265 160.438'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.264 167.647L105.264 166.045'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 151.482L104.609 155.487'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 157.088L104.609 161.093'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 162.695L104.609 166.7'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 162.695L104.609 166.7'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 157.088L104.609 161.093'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 151.482L104.609 155.487'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.954 167.647L103.954 166.045'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.954 162.04L103.954 160.438'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.954 156.434L103.954 154.832'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 151.482L104.609 155.487'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 157.088L104.609 161.093'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 162.695L104.609 166.7'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 166.7L93.2822 155.373'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 162.695L93.2822 151.368'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 161.093L93.2822 149.767'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.28 162.457L109.28 161.218'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.651 162.457L111.828 162.457'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.745 151.059L111.828 151.059'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.827 161.219L106.745 161.219'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.522 167.169L140.666 167.169'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.2011 150.079L97.7944 150.274'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 161.219L174.588 161.219'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.588 151.059L169.505 151.059'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.682 162.457L169.505 162.457'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.053 162.457L172.053 161.218'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.783 149.154L106.745 149.154'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 151.482L106.745 149.346'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.264 150.827L105.264 149.225'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.954 150.827L103.954 149.225'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 179.621L140.667 179.621'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 183.52L108.651 179.478'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 185.121L108.651 181.08'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 179.515L104.609 183.52'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 179.515L104.609 183.52'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 179.515L104.609 183.52'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 179.515L104.609 183.52'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 185.122L93.2822 173.795'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 177.913L108.651 173.872'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 179.515L108.651 175.474'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 173.908L104.609 177.913'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.265 178.86L105.265 177.258'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 173.908L104.609 177.913'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 173.908L104.609 177.913'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.954 178.86L103.954 177.258'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 173.908L104.609 177.913'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.217 174.818L109.217 174.252'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.217 176.513L109.217 175.946'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.651 175.946L108.651 174.818'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.651 177.646L108.651 176.513'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.217 178.212L109.217 177.646'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.651 174.818L111.828 174.818'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.827 175.946L108.65 175.946'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.651 174.252L111.828 174.252'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.827 176.513L108.65 176.513'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.827 177.645L108.65 177.645'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.827 178.212L108.65 178.212'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.523 179.621L140.667 179.621'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.523 179.621L123.676 179.621'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.265 184.467L105.265 182.865'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.954 184.467L103.954 182.865'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.522 184.03L116.929 184.03'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 178.212L172.682 178.212'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 177.646L172.682 177.646'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 176.513L172.682 176.513'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.682 174.252L169.505 174.252'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 175.946L172.682 175.946'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.682 174.818L169.505 174.818'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.116 178.212L172.116 177.646'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.682 177.646L172.682 176.513'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.682 175.946L172.682 174.818'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.116 176.513L172.116 175.946'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.116 174.818L172.116 174.252'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.657 179.621L159.811 179.621'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 184.03L164.403 184.03'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 172.307L108.651 168.265'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 168.302L104.609 172.307'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.264 173.254L105.264 171.652'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 168.302L104.609 172.307'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 168.302L104.609 172.307'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.954 173.253L103.954 171.651'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 168.302L104.609 172.307'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.217 173.119L109.217 172.553'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.651 173.119L111.828 173.119'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.651 172.553L111.828 172.553'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.682 172.553L169.505 172.553'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.682 173.119L169.505 173.119'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.116 173.119L172.116 172.553'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 183.52L93.2817 172.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 173.908L108.651 169.867'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 179.515L93.2822 168.188'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.651 174.252L108.651 173.119'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.682 174.252L172.682 173.119'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.676 230.968L123.676 220.957'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.402 205.099L116.402 229.431'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.676 210.566L123.676 201.744'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.419 197.751L102.249 197.98'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.929 200.589L111.827 200.589'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.1147 197.453L96.6678 198.006'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.6685 198.005L101.362 198.694'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.362 198.694L100.419 197.751'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.419 197.751L102.249 197.98'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.25 197.98L103.07 198.8'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.07 198.8L106.149 198.8'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.148 198.8L106.969 197.98'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.969 197.98L108.651 197.77'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.651 197.77L107.857 198.694'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.856 198.694L108.65 198.619'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.651 198.619L108.651 199.319'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.651 199.319L111.828 199.319'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.828 199.319L111.828 200.589'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.828 200.589L116.93 200.589'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.676 201.744L116.402 205.099'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.403 200.589L169.505 200.589'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.403 200.589L169.505 200.589'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 200.589L169.505 199.319'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.505 199.319L172.682 199.319'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.682 199.319L172.682 198.619'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 194.733L108.651 190.691'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 196.335L108.651 192.293'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 190.728L104.609 194.733'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.265 195.68L105.265 194.078'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.264 190.073L105.264 188.472'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 190.728L104.609 194.733'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 190.728L104.609 194.733'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.954 190.073L103.954 188.471'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.954 195.68L103.954 194.078'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 190.728L104.609 194.733'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.93 190.26L121.523 190.26'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.522 192.969L116.929 192.969'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M92.5688 195.508L94.2951 197.235'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.93 192.969L121.523 192.969'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 192.969L164.403 192.969'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.403 190.26L159.81 190.26'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.81 192.969L164.403 192.969'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.969 197.98L108.651 196.298'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 196.335L104.609 198.831'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 196.335L104.609 198.831'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 196.335L104.609 198.831'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.609 196.335L104.609 198.831'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.1153 197.452L93.2822 194.619'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.419 197.751L93.2822 190.615'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.249 197.98L93.2822 189.013'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.2949 197.234L96.1152 197.453'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.93 200.589L116.93 192.969'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.403 192.969L164.403 200.589'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.239 216.894L156.523 216.894'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.427 211.881L156.687 211.881'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.862 215.522L156.687 211.881'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.556 214.628L156.524 214.628'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.39 221.113C125.346 221.113 127.742 218.717 127.742 215.761C127.742 212.806 125.346 210.41 122.39 210.41C119.435 210.41 117.039 212.806 117.039 215.761C117.039 218.717 119.435 221.113 122.39 221.113Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.239 214.628L130.17 214.628'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.252 215.522L145.427 211.881'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.862 215.522L148.251 215.522'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.864 215.522L136.475 215.522'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.865 215.522L128.04 211.881'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.475 215.522L139.3 211.881'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.3 211.882L128.04 211.882'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.239 214.628L128.239 216.894'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.169 214.628L147.558 214.628'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.523 216.894L156.523 214.628'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.316 255.293L182.316 231.13'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.0171 255.293L99.0171 231.129'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6948 229.43L89.6948 263.795'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.935 232.231L107.675 232.231'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.11 235.871L118.935 232.23'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.5 235.871L107.675 232.23'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.5 235.872L116.111 235.872'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.667 231.13L89.6948 231.13'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.402 229.43L123.676 229.43'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.402 229.43L89.6948 229.43'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.833 235.872L165.222 235.872'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.833 235.871L173.658 232.23'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.223 235.871L162.398 232.23'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.398 232.23L173.658 232.23'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.647 237.844L140.666 237.844'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.677 240.407L140.667 240.407'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.978 242.106L140.666 242.106'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.655 240.407L110.956 242.106'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.354 242.106L112.655 243.805'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.354 242.106L140.666 242.106'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.656 240.407L140.667 240.407'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.686 237.844L140.667 237.844'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.979 242.106L168.678 243.805'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.677 240.407L170.376 242.106'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6948 255.293L106.685 255.293'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.146 244.938L140.666 244.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.187 244.938L140.667 244.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.186 244.938L115.487 246.637'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.146 244.938L165.846 246.637'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.971 198.694L184.664 198.005'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 185.122L188.05 173.795'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 189.126L188.05 177.799'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 190.729L188.05 179.401'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 194.733L188.05 183.406'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 196.335L188.05 185.008'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.083 197.98L188.051 189.013'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.914 197.751L188.051 190.615'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 161.093L188.05 149.767'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 162.695L188.05 151.368'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 166.7L188.05 155.373'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 168.302L188.05 156.975'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 172.307L188.05 160.98'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 157.089L185.099 148.714'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.099 148.714L183.539 150.274'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 183.52L188.05 172.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 173.909L188.05 162.582'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 177.913L188.05 166.586'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 179.515L188.05 168.188'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 185.122L176.724 189.127'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 185.122L176.724 189.127'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 185.122L176.724 189.127'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 185.122L176.724 189.127'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 190.728L172.682 186.687'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 189.127L172.682 185.085'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.55 149.154L175.55 147.812'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.25 147.956L179.309 148.897'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.308 148.898L178.124 148.48'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 148.023L176.724 149.88'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 151.482L180.25 147.956'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 155.487L183.768 148.443'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 149.88L178.756 147.848'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 149.88L175.55 148.706'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.132 150.079L183.768 148.443'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.768 148.443L180.25 147.956'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.125 148.479L178.756 147.848'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.756 147.848L175.55 147.812'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 168.301L172.682 164.26'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 162.695L176.724 166.7'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 157.089L176.724 161.094'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 151.482L176.724 155.487'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.378 156.434L177.378 154.832'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.378 162.04L177.378 160.438'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.378 167.647L177.378 166.045'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 151.482L176.724 155.487'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 157.089L176.724 161.094'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 162.695L176.724 166.7'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 162.695L176.724 166.7'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 157.089L176.724 161.094'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 151.482L176.724 155.487'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.068 167.647L176.068 166.045'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.068 162.04L176.068 160.438'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.068 156.434L176.068 154.832'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 151.482L176.724 155.487'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 157.089L176.724 161.094'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 162.695L176.724 166.7'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 166.7L172.682 162.658'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 162.695L174.588 160.56'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 161.094L174.588 158.958'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 157.089L174.588 154.953'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 155.487L174.588 153.351'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.538 150.274L182.132 150.079'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.549 149.154L174.587 149.154'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.378 150.827L177.378 149.225'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.068 150.827L176.068 149.225'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 151.482L174.588 149.347'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 179.515L176.724 183.52'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 179.515L176.724 183.52'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 179.515L176.724 183.52'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 179.515L176.724 183.52'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 185.121L172.682 181.08'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 183.52L172.682 179.478'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 173.908L176.724 177.913'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.378 178.86L177.378 177.258'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 173.908L176.724 177.913'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 173.908L176.724 177.913'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.068 178.86L176.068 177.258'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 173.908L176.724 177.913'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 179.515L172.682 175.474'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 177.913L172.682 173.872'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.378 184.467L177.378 182.865'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.068 184.467L176.068 182.865'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 168.302L176.724 172.307'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.378 173.254L177.378 171.652'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 168.302L176.724 172.307'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 168.302L176.724 172.307'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.068 173.254L176.068 171.652'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 168.302L176.724 172.307'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 172.307L172.682 168.265'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 173.908L172.682 169.867'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.914 197.751L179.083 197.98'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.682 198.619L173.476 198.695'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M173.476 198.694L172.682 197.77'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.682 197.77L174.364 197.98'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.364 197.98L175.184 198.8'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.184 198.8L178.263 198.8'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.263 198.8L179.083 197.98'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.083 197.98L180.914 197.751'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.914 197.751L179.971 198.694'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 190.728L176.724 194.733'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.378 195.68L177.378 194.078'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.378 190.073L177.378 188.472'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 190.728L176.724 194.733'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 190.728L176.724 194.733'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.068 190.073L176.068 188.472'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.068 195.68L176.068 194.078'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 190.728L176.724 194.733'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 196.335L172.682 192.293'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 194.733L172.682 190.691'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 196.335L176.724 198.831'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 196.335L176.724 198.831'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 196.335L176.724 198.831'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.724 196.335L176.724 198.831'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.364 197.98L172.682 196.298'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M188.05 196.221L188.05 149.569'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M188.764 195.508L188.764 149.72'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M188.764 149.72L185.099 148.714'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.665 198.006L185.218 197.453'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.038 197.234L188.764 195.508'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.218 197.453L188.051 194.62'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.218 197.453L187.038 197.234'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 263.795L191.638 229.43'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.152 348.207L188.806 348.207'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 326.176L163.32 326.176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.647 279.24L191.638 279.24'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.862 341.217L119.862 322.495'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.471 341.217L161.471 322.495'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.012 345.375L118.012 322.494'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.32 345.375L163.32 322.494'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2407 340.997C85.3762 341.339 87.5328 341.529 89.695 341.567'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2407 340.997L89.695 341.567'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.386 308.327L153.386 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.554 308.327L150.554 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.722 308.327L147.722 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.891 308.327L144.891 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.059 308.327L142.059 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.274 308.327L139.274 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.442 308.327L136.442 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.611 308.327L133.611 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.779 308.327L130.779 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.947 308.327L127.947 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.115 308.327L125.115 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.284 308.327L122.284 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.452 308.327L119.452 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.62 308.327L116.62 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.788 308.327L113.788 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.656 311.284L112.656 283.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.956 312.983L110.956 282.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.376 312.983L170.376 282.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.677 311.284L168.677 283.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.544 308.327L167.544 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.712 308.327L164.712 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.881 308.327L161.881 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.049 308.327L159.049 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.218 308.327L156.218 287.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6944 302.598L76.6685 289.572'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 307.404L76.6689 294.378'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 313.812L76.6689 300.786'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 309.006L76.6689 295.98'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 315.413L76.6689 302.387'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.6685 339.441L76.6685 266.169'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.5361 268.578L75.5361 337.033'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.355 282.239L112.656 283.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.979 282.239L168.678 283.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6944 294.589L76.6685 281.563'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 296.191L76.6689 283.165'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6944 288.181L76.6685 275.155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 283.376L76.6689 270.35'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6944 289.783L76.6685 276.757'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.677 280.54L140.667 280.54'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 277.123L168.677 277.123'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.978 282.239L140.666 282.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 275.424L166.978 275.424'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 272.592L164.146 272.592'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.655 280.54L110.956 282.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.186 272.592L115.487 270.893'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.655 277.123L110.956 275.424'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.355 275.423L112.656 273.724'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 272.592L117.186 272.592'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 275.424L114.354 275.424'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.354 282.239L140.666 282.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 277.123L112.655 277.123'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.656 280.54L140.667 280.54'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.685 279.24L89.6948 279.24'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.979 275.423L168.678 273.724'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.677 277.123L170.376 275.424'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.146 272.592L165.846 270.893'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.677 280.54L170.376 282.239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.5354 264.401C82.2097 264.629 80.8952 264.918 79.5962 265.268'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85.9356 265.199C85.4638 265.254 84.9931 265.318 84.5234 265.389'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5249 266.196C80.1025 266.303 79.6819 266.416 79.2632 266.536'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.3504 265.624C77.786 265.795 77.2252 265.977 76.6685 266.17'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.9224 264.187L85.9348 265.199'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M85.9356 265.199L84.5234 265.389'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.5232 265.389L83.5356 264.401'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.5354 264.401L79.5962 265.268'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.5967 265.268L80.5249 266.196'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5249 266.196L79.2632 266.536'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.2631 266.536L78.3506 265.624'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.3504 265.624L76.6685 266.17'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.6685 266.169L75.5356 268.578'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6943 268.958L84.9224 264.187'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6943 275.366L79.5962 265.268'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 281.774L76.6689 268.748'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6945 270.56L83.5356 264.401'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6947 276.968L78.3506 265.624'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.545 287.194L140.667 287.194'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.788 287.194L112.655 283.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.788 287.194L140.666 287.194'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.544 287.194L168.677 283.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 300.996L76.6689 287.97'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 333.034L76.6689 320.008'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 334.635L76.6689 321.609'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 326.627L76.6689 313.601'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6944 321.821L76.6685 308.795'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 328.228L76.6689 315.202'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.647 318.224L140.666 318.224'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.686 318.224L140.667 318.224'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.677 314.682L140.667 314.682'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.978 312.983L140.666 312.983'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.544 308.326L140.666 308.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.788 308.326L112.655 311.283'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.788 308.326L140.666 308.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.956 312.982L112.655 314.682'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.656 311.284L114.355 312.983'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.354 312.983L140.666 312.983'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.656 314.682L140.667 314.682'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.677 311.284L166.978 312.983'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.376 312.982L168.677 314.682'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.544 308.326L168.677 311.283'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 320.218L76.6689 307.192'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.647 322.494L140.666 322.494'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.424 333.502L112.424 326.176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.0371 333.503L112.424 333.503'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M97.0371 326.176L97.0371 333.502'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.0693 340.418C89.278 340.425 89.4862 340.43 89.6949 340.433'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.686 322.494L140.667 322.494'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6943 326.176L118.012 326.176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.295 326.176L184.295 333.502'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.296 333.503L168.909 333.503'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.909 333.502L168.909 326.176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.6685 339.441C78.1842 339.921 79.7237 340.32 81.2812 340.639'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.7822 339.141C80.4645 339.309 81.1508 339.461 81.8404 339.597'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.2812 340.639L76.6685 336.026'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.2406 340.997L76.6685 334.425'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.5361 337.033L76.669 339.442'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.6685 339.441L81.2812 340.639'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.2815 340.64L79.7822 339.141'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.7822 339.141L81.8404 339.597'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.8403 339.597L83.2408 340.997'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 341.044L76.6689 328.018'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6949 339.441L76.6689 326.415'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.278 384.52L150.587 379.114'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.577 380.526L146.577 372.885'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.756 380.526L134.756 372.885'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.054 384.52L130.745 379.114'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.009 371.439L149.009 390.353'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.742 395.719L147.742 371.438'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.591 395.719L133.591 371.438'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.324 371.439L132.324 390.353'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.862 345.375L119.862 390.352'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.471 390.352L161.471 345.375'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.392 348.314L149.538 386.664'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.94 348.314L131.794 386.664'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.392 346.631L151.392 365.516'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.94 346.63L129.94 365.515'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.611 360.526L144.611 349.886'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.722 360.526L136.722 349.886'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.774 358.461L123.774 360.941'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.296 357.874L124.296 361.528'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.937 362.035L124.937 357.179'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.396 362.036L156.396 357.18'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.037 357.874L157.037 361.528'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.559 358.461L157.559 360.941'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.777 373.437L154.777 345.989'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.691 373.437L152.691 345.989'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.641 373.437L128.641 345.989'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.556 373.437L126.556 345.989'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.009 371.438L149.532 351.013'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.324 371.438L131.801 351.013'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.382 350.946L145.382 346.63'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.951 350.946L135.951 346.63'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6948 345.375L92.5264 348.207'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.18 348.208L118.012 345.376'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.32 345.375L166.152 348.207'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.471 345.375L140.667 345.375'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.691 345.989L154.777 345.989'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.392 346.63L140.667 346.63'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.94 346.63L140.666 346.63'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.642 345.989L126.556 345.989'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.862 345.375L140.666 345.375'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.012 345.375L119.862 345.375'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.471 345.375L163.32 345.375'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.392 348.314L152.691 348.314'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.691 355.308C152.29 354.794 151.856 354.308 151.392 353.85'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.691 357.286C152.305 356.66 151.87 356.066 151.392 355.509'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.382 351.013L151.156 351.013'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.168 349.926L146.168 347.812'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.169 349.926L145.302 349.926'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.392 357.855L152.691 357.855'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.169 347.812L145.302 347.812'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.382 347.737L140.667 347.737'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.382 349.886L144.611 349.886'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.611 359.355L140.666 359.355'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 357.843L144.611 357.843'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 357.843L136.721 357.843'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.722 359.355L140.666 359.355'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.951 349.886L136.722 349.886'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.951 347.737L140.666 347.737'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.164 347.812L136.031 347.812'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.941 357.855L128.642 357.855'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.164 349.926L136.031 349.926'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.164 349.926L135.164 347.812'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.95 351.013L130.176 351.013'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.296 358.461L123.773 358.461'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.937 357.874L124.296 357.874'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.937 357.179L126.556 357.179'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.941 355.509C129.462 356.066 129.028 356.66 128.642 357.286'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.94 353.85C129.476 354.308 129.043 354.794 128.641 355.308'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.556 348.314L119.862 348.314'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.941 348.314L128.642 348.314'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M92.5264 348.207L115.18 348.207'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.777 348.314L161.471 348.314'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.395 357.18L154.776 357.18'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.396 357.874L157.036 357.874'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.037 358.461L157.559 358.461'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.012 341.216L119.862 341.216'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.321 341.216L161.471 341.216'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.862 341.217L119.862 345.376'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6943 341.566L89.6943 345.376'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.471 341.216L161.471 345.375'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.777 373.437L152.691 373.437'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.016 376.784C151.24 375.836 152.313 374.707 153.197 373.437'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.09 375.249C151.102 374.345 151.977 373.298 152.691 372.144'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.742 371.439L149.009 371.439'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.587 379.114L149.903 379.114'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.742 371.439L143.599 371.439'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.547 379.491L145.799 379.491'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.8 379.491L145.8 374.325'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.8 374.326L146.547 374.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.8 375.299L144.563 375.299'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.563 375.299L144.563 378.593'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.563 378.593L145.8 378.593'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.563 378.144L140.667 378.144'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.563 375.973L143.599 375.973'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.577 372.885L147.742 372.885'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.497 372.551L143.599 372.551'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.497 372.551L146.576 373.63'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.836 372.551L134.756 373.63'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.836 372.551L137.734 372.551'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.756 372.885L133.591 372.885'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.77 375.973L137.734 375.973'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.77 378.144L140.666 378.144'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.77 378.593L135.533 378.593'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.77 375.299L136.77 378.593'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.533 375.299L136.77 375.299'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.533 374.326L134.786 374.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.533 379.492L135.533 374.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.786 379.491L135.533 379.491'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.591 371.439L137.734 371.439'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.745 379.114L131.429 379.114'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.59 371.438L132.323 371.438'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.556 373.437L128.641 373.437'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.641 372.144C129.355 373.298 130.23 374.345 131.242 375.249'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.136 373.437C129.02 374.707 130.093 375.836 131.317 376.784'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.556 365.613L151.392 365.515'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 360.525L144.611 360.525'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.691 361.6L151.392 361.6'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.641 361.6L129.94 361.6'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 360.525L136.722 360.525'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.777 365.613L129.941 365.515'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.774 360.94L124.297 360.94'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.296 361.528L124.937 361.528'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.556 362.035L124.937 362.035'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.556 363.889C126.524 364.439 126.524 364.99 126.556 365.541'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.777 365.541C154.809 364.991 154.809 364.439 154.777 363.889'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.777 362.035L156.396 362.035'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.037 361.528L156.396 361.528'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.559 360.94L157.036 360.94'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.599 375.973L143.599 360.525'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.734 375.973L137.734 360.525'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.636 362.035C125.125 364.901 125.444 367.854 126.556 370.544'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.777 370.544C155.888 367.853 156.208 364.901 155.697 362.035'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.629 395.72L146.629 405.736'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.703 395.72L134.703 405.736'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.009 390.353L161.471 390.353'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.862 390.353L132.323 390.353'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.324 390.353L132.324 395.719'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.324 395.72L133.591 395.72'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.742 395.72L149.009 395.72'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.009 395.719L149.009 390.353'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.471 387.851L149.009 387.851'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.471 384.043L150.306 384.043'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.471 381.114L152.278 381.114'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.009 386.664L149.538 386.664'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.645 384.52L150.279 384.52'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.229 383.685L151.442 379.949'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.179 382.85L152.297 380.784'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.326 383.685L151.229 383.685'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.442 379.949L150.54 379.949'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.297 380.784L151.394 380.784'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.276 382.851L152.179 382.851'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.742 380.525L146.576 380.525'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.591 380.526L134.756 380.526'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.056 382.851L129.153 382.851'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.036 380.784L129.938 380.784'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.891 379.949L130.793 379.949'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.007 383.685L130.104 383.685'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.153 382.85L129.035 380.784'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.104 383.685L129.891 379.949'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.688 384.52L131.055 384.52'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.323 386.664L131.794 386.664'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.862 381.114L129.054 381.114'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.862 384.043L131.027 384.043'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.862 387.851L132.323 387.851'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.629 395.719L146.629 380.525'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.703 395.72L134.703 380.526'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.742 405.735L146.339 405.735'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.591 405.735L134.993 405.735'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.41 264.187C194.828 263.968 193.235 263.837 191.638 263.795'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.41 264.187L191.638 263.795'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 341.567L198.092 340.997'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 341.567C193.8 341.529 195.957 341.339 198.092 340.997'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 315.414L204.664 302.388'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 309.006L204.664 295.98'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 313.812L204.664 300.786'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 307.404L204.664 294.378'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 302.598L204.664 289.572'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.664 339.441L204.664 266.169'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.797 337.033L205.797 268.578'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 289.783L204.664 276.757'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 283.376L204.664 270.35'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 288.181L204.664 275.155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 296.191L204.664 283.165'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 294.589L204.664 281.563'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 268.958L196.41 264.186'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.664 266.17C204.107 265.977 203.546 265.795 202.982 265.624'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.07 266.536C201.651 266.417 201.23 266.303 200.808 266.196'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.81 265.389C196.34 265.318 195.869 265.254 195.397 265.199'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.737 265.268C200.438 264.918 199.123 264.629 197.797 264.401'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.797 268.578L204.664 266.169'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.664 266.17L202.982 265.624'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.982 265.624L202.07 266.537'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.07 266.536L200.808 266.196'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.808 266.196L201.736 265.268'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.737 265.268L197.797 264.401'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.797 264.401L196.81 265.389'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.81 265.389L195.397 265.199'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.397 265.199L196.41 264.187'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 276.968L202.982 265.624'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 270.56L197.797 264.401'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 281.774L204.664 268.748'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 275.366L201.736 265.268'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 300.997L204.664 287.971'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 334.636L204.664 321.61'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 333.034L204.664 320.008'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 328.228L204.664 315.202'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 321.821L204.664 308.795'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 326.627L204.664 313.601'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 320.219L204.664 307.193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 339.441L204.664 326.415'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 341.044L204.664 328.018'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.092 340.997L204.664 334.425'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.051 340.639L204.664 336.026'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 340.433C191.847 340.43 192.055 340.425 192.264 340.418'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.493 339.597C200.182 339.461 200.868 339.309 201.551 339.141'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.051 340.639C201.609 340.32 203.148 339.921 204.664 339.441'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.092 340.997L199.493 339.597'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.493 339.597L201.551 339.141'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.551 339.141L200.051 340.64'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.051 340.639L204.664 339.441'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.664 339.442L205.797 337.033'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M188.807 348.208L191.638 345.376'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.638 345.376L191.638 341.566'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.788 451.061L142.788 473.928'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.545 451.061L138.545 473.928'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.014 427.368L127.148 467.246'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.184 467.246L157.319 427.368'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.742 427.368L147.742 445.89'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.018 437.021L144.018 438.156'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.629 426.647L146.629 444.779'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.703 426.647L134.703 444.779'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.315 437.021L137.315 438.155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.591 427.368L133.591 445.89'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.319 427.368L154.675 427.368'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.674 427.368L152.252 427.368'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.34 426.647L147.742 426.647'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.339 425.157L145.299 425.157'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.299 425.158L145.299 418.644'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.299 418.644L146.34 418.644'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.299 420.08L144.724 420.08'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.724 420.08L144.724 424.295'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.724 424.296L145.3 424.296'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.018 424.1L144.018 434.751'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.018 435.318L144.018 436.453'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.018 434.751L140.667 434.751'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.018 435.318L140.667 435.318'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.018 436.453L140.667 436.453'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.018 437.021L140.667 437.021'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.6 437.021L143.6 436.454'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.6 435.318L143.6 434.751'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.723 420.323L140.666 420.323'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.724 424.1L144.017 424.1'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.609 424.1L137.315 424.1'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.609 420.323L140.666 420.323'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.733 435.318L137.733 434.751'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.733 437.021L137.733 436.454'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.315 437.021L140.666 437.021'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.315 436.454L140.666 436.454'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.315 435.318L140.666 435.318'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.315 434.751L140.666 434.751'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.315 435.318L137.315 436.453'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.315 424.1L137.315 434.751'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.609 424.296L136.033 424.296'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.609 420.08L136.609 424.295'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.034 420.08L136.609 420.08'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.033 418.644L134.993 418.644'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.034 425.158L136.034 418.644'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.993 425.158L136.034 425.158'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.993 426.647L133.591 426.647'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.658 427.368L129.08 427.368'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.591 427.368L129.08 427.368'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.08 427.369L128.616 425.154'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.617 425.153L127.18 425.153'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.18 425.153L126.658 427.368'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.658 427.368L124.014 427.368'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.675 427.369L154.153 425.154'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.153 425.153L152.716 425.153'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.716 425.153L152.252 427.368'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.252 427.368L147.742 427.368'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.609 447.023L143.979 447.023'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.035 444.44L147.742 444.44'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.035 438.963L149.894 438.963'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.895 438.963L149.895 443.189'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.894 443.188L149.035 443.188'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.018 438.723L144.018 439.857'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.018 438.155L140.667 438.155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.018 438.723L140.667 438.723'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 451.061L144.017 451.061'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.6 438.723L143.6 438.155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.018 439.856L144.018 451.06'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.497 445.911L143.979 445.911'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.497 445.912L146.63 444.779'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.609 447.023L147.742 445.891'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.723 447.023L133.59 445.891'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.836 445.912L134.703 444.779'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.835 445.911L137.354 445.911'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.315 439.856L137.315 451.06'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.733 438.723L137.733 438.155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 451.061L137.315 451.061'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.315 438.723L140.666 438.723'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.315 438.155L140.666 438.155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.315 438.723L137.315 439.857'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.438 443.188L132.298 443.188'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.438 438.963L131.438 443.189'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.298 438.963L131.438 438.963'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.298 444.44L133.591 444.44'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.723 447.023L137.353 447.023'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.035 437.5L147.742 437.5'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.298 437.5L133.591 437.5'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.035 444.44L149.035 437.5'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.298 444.44L132.298 437.5'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.218 478.109L143.218 464.174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.415 473.928L145.415 478.109'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.918 473.928L135.918 478.109'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.115 478.109L138.115 464.174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.175 473.928L134.175 478.109'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.157 478.109L147.157 473.928'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.788 470.634L143.218 470.634'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.218 464.173L145.414 464.173'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.415 464.173L145.415 470.634'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.788 473.928L143.218 473.928'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.415 473.928L147.158 473.928'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.415 470.634L150.52 470.634'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.918 470.634L130.813 470.634'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.919 473.928L134.175 473.928'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.545 473.928L138.114 473.928'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.918 464.173L135.918 470.634'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.114 464.173L135.918 464.173'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.545 470.634L138.114 470.634'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.149 467.246L130.813 470.634'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.813 470.634L135.918 470.634'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.918 470.634L135.918 473.928'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.919 473.928L134.175 473.928'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.157 473.928L145.414 473.928'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.415 473.928L145.415 470.634'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.415 470.634L150.52 470.634'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.52 470.634L154.184 467.246'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.157 478.108L145.414 478.108'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.175 478.108L135.918 478.108'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.175 478.108L135.918 478.108'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.918 478.108L135.918 479.502'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.918 479.502L138.115 479.502'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.115 479.502L138.115 478.108'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.115 478.108L140.667 478.108'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.666 478.108L143.218 478.108'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.218 478.108L143.218 479.502'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.218 479.502L145.414 479.502'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.415 479.502L145.415 478.108'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.415 478.108L147.158 478.108'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_95_559'>
        <rect width='500' height='281.113' fill='white' transform='translate(281.223) rotate(90)' />
      </clipPath>
    </defs>
  </svg>
);
