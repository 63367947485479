import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import type { ReactElement, ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Toaster } from 'react-hot-toast';

import { kebabToSentenceCase } from '@fleet/utils';

import { ErrorFallback } from '../components/atoms/ErrorBoundary/ErrorBoundary';
import { getIntercomScript, pendoScript } from '../const/Scripts';
import { useBrandingTheme, useUpdateCheck } from '../hooks';
import { axiosInstance, getFromEnvConfig } from '../utils';

import '../styles/globals.css';

type IntercomEvent = 'boot' | 'update';

type IntercomFunction = (
  event: IntercomEvent,
  opts: { app_id: string; email: string; user_id: string } | { hide_default_launcher: boolean }
) => void;

declare global {
  interface Window {
    pendo: {
      initialize(opts: {
        visitor: Record<string, string | null>;
        account: Record<string, string | null>;
      }): void;
      track(event: string, properties?: Record<string, unknown>): void;
    };
    Intercom: IntercomFunction;
    __APP_VERSION_FILE__: string;
  }
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  useUpdateCheck({ type: 'interval', interval: 30_000 });

  const [envFetched, setEnvFetched] = useState(false);
  const theme = useBrandingTheme();
  const router = useRouter();
  const routeName = kebabToSentenceCase(router.pathname.split('/')[1]);

  const toastClass = useMemo(() => ({ className: '!rounded-full !max-w-[600px]' }), []);

  const intercomScript = useMemo(
    () => (envFetched ? getIntercomScript() : undefined),
    [envFetched]
  );

  useEffect(() => {
    const fetchEnv = async () => {
      const response = await fetch('/env.json', {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache'
        }
      });
      const env = await response.json();
      window.env = env;
      axiosInstance.defaults.baseURL = `${getFromEnvConfig('FLEET_API_URL')}/api/v1`;
      setEnvFetched(true);
    };

    if (!envFetched) fetchEnv();
  }, [envFetched]);

  // const onRedirectCallback = useCallback(
  //   (appState: AppState | undefined) => {
  //     router.replace(appState?.returnTo || '/');
  //   },
  //   [router]
  // );

  const authorizationParams = useMemo(
    () =>
      envFetched
        ? {
            redirect_uri: typeof window === 'undefined' ? '' : window.location.origin,
            audience: getFromEnvConfig('AUTH0_AUDIENCE'),
            scope: 'read:current_user update:current_user_metadata'
          }
        : {},
    [envFetched]
  );

  useEffect(() => {
    // TODO - this is a hack to fix the issue with the nivo canvas graphs not
    // rendering canvas tooltip correctly. Will be fixed in nivo package update
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (HTMLCanvasElement.prototype as any).getBBox = function () {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      return { width: this.offsetWidth, height: this.offsetHeight };
    };
  }, []);

  useEffect(() => {
    // Set var(--brand-color) based on origin
    if (theme) return document.documentElement.style.setProperty('--brand-color', theme.color);

    // Fallback to default enterprise colors
    document.documentElement.style.setProperty('--brand-color', '255, 125, 51');
  }, [theme]);

  if (!theme || !envFetched) return null;

  const getLayout = Component.getLayout || (page => page);

  return (
    <>
      <Head>
        <title>{routeName ? `${routeName} - ` : ''}Blindsight Reporting</title>
        <meta name='viewport' content='width=device-width, initial-scale=0.1' />
        <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png?v=2' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png?v=2' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png?v=2' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg?v=2' color='#ff7d33' />
      </Head>
      <Script id='Intercom' dangerouslySetInnerHTML={intercomScript} />
      <Script id='Pendo' dangerouslySetInnerHTML={pendoScript} />

      <Auth0Provider
        domain={getFromEnvConfig('AUTH0_DOMAIN')}
        clientId={getFromEnvConfig('AUTH0_CLIENT_ID')}
        // onRedirectCallback={onRedirectCallback}
        authorizationParams={authorizationParams}
        //cacheLocation='localstorage'
      >
        <Toaster position='top-right' toastOptions={toastClass} />
        {getLayout(<Component {...pageProps} />)}
      </Auth0Provider>
    </>
  );
}

export default Sentry.withErrorBoundary(App, {
  fallback: <ErrorFallback />
});
