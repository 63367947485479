import { Tab, TabGroup, TabList } from '@headlessui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface TabItem {
  name: string;
  route: string;
  id?: string;
  isActive?: boolean;
}

interface TabsProps {
  tabs: TabItem[];
  currentRoute: string;
}

export const Tabs: React.FC<TabsProps> = ({ tabs, currentRoute }) => {
  const [selectedTab, setSelectedTab] = useState(-1);
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const router = useRouter();
  const tabListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const newIndex = tabs.findIndex(
      ({ route, isActive }) => router.pathname.includes(route) || isActive
    );

    if (newIndex !== selectedTab && router.isReady) {
      setSelectedTab(newIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname, selectedTab, router.isReady]);

  useEffect(() => {
    if (tabListRef.current && selectedTab !== -1) {
      const tab = tabListRef.current.children[selectedTab] as HTMLElement;
      setIndicatorStyle({
        width: tab.offsetWidth,
        transform: `translateX(${tab.offsetLeft}px)`
      });
    } else {
      setIndicatorStyle({ width: 0, transform: 'translateX(0px)' });
    }
  }, [selectedTab]);

  return (
    <TabGroup selectedIndex={selectedTab}>
      <TabList ref={tabListRef} className='relative flex gap-6'>
        {tabs.map(({ name, route, id, isActive }, index) => (
          <Link
            shallow
            href={{
              pathname: route,
              query: router.query
            }}
            key={name}
            id={id}
            className='focus:outline-none'
          >
            <Tab
              className={twMerge(
                'relative flex h-16 cursor-pointer items-center px-4 text-secondary-text transition focus:outline-none',
                (currentRoute === route || isActive) &&
                  'bg-primary-element font-medium text-primary-text'
              )}
            >
              {name}
            </Tab>
          </Link>
        ))}
        <span
          className='absolute bottom-0 h-0.5 bg-black transition-transform duration-300 ease-out'
          style={indicatorStyle}
        />
      </TabList>
    </TabGroup>
  );
};
