'use client';

import { useRouter } from 'next/router';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { Error, Loading } from '@fleet/components';

import { Notification, NotificationType } from '../components/atoms/Notification/Notification';
import type { OrganizationUserRole } from '../const';
import { LocalStorageKeys, Routes } from '../const';
import type { Organization, User } from '../hooks';
import {
  useGetOrganizations,
  useGetProjects,
  useTrackers,
  useUpdateUser,
  useValidateRoute
} from '../hooks';
import type { AccessRoles, AuthType } from '../hooks/api/useGetAuthContext';
import { useGetAuthContext } from '../hooks/api/useGetAuthContext';
import {
  getFromLocalStorage,
  getNowUtc,
  setToLocalStorage,
  shouldUserUpdateDetails
} from '../utils';

interface AuthContextType {
  user?: User;
  roles?: AccessRoles;
  globalAdmin?: boolean;
  authType?: AuthType;
  currentOrganizationId?: string;
  currentOrganization?: Organization | null;
  currentOrganizationProjectIds?: string;
  currentOrganizationRole?: OrganizationUserRole | null;
  allOrganizations?: Organization[];
  allOrganizationIds?: string;
  changeOrganization: (organizationId: string) => void;
  refetchAllQueries: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  changeOrganization: () => void 0,
  refetchAllQueries: () => void 0
});

// Responsible for providing auth context and managing selected organization
const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useValidateRoute(); // TODO: remove after filters refactor

  const router = useRouter();
  const [currentOrganizationId, setCurrentOrganizationId] = useState<string | undefined>();
  const {
    data: authContext,
    isLoading: isLoadingAuthContext,
    mutate: mutateAuthContext
  } = useGetAuthContext();

  useTrackers({ user: authContext?.user });

  const {
    data: organizationsData,
    isLoading: isLoadingOrgs,
    mutate: mutateOrgs
  } = useGetOrganizations();

  const {
    data: projectsData,
    isLoading: isLoadingProjects,
    mutate: mutateProjects
  } = useGetProjects({
    organizationIds: currentOrganizationId
  });

  const { trigger: updateUser } = useUpdateUser();

  // Update users last activity every 5 minutes
  useEffect(() => {
    updateUser({ lastActive: getNowUtc() });
    const interval = setInterval(() => updateUser({ lastActive: getNowUtc() }), 300_000);
    return () => clearInterval(interval);
  }, [updateUser]);

  useEffect(() => {
    if (!authContext?.user) return;
    if (router.pathname !== Routes.ACCOUNT && shouldUserUpdateDetails(authContext.user)) {
      const toastId = 'update-profile-notification';
      toast(
        t => (
          <Notification
            description='User details are incomplete'
            buttonLabel='Update'
            type={NotificationType.Warning}
            onButtonClick={() => {
              toast.dismiss(t.id);
              router.push(Routes.ACCOUNT);
            }}
            showCloseButton
            onCloseClick={() => toast.dismiss(toastId)}
          />
        ),
        { id: toastId, duration: 5000 }
      );
    }
  }, [router, authContext?.user]);

  const changeOrganization = useCallback(
    (organizationId: string) => {
      setToLocalStorage(LocalStorageKeys.ORGANIZATION_ID, organizationId);
      setCurrentOrganizationId(organizationId);
      if (router.query.organizationId !== organizationId) {
        router.replace({
          query: {
            ...router.query,
            organizationId
          }
        });
      }
    },
    [router]
  );

  const refetchAllQueries = useCallback(() => {
    mutateAuthContext();
    mutateOrgs();
    mutateProjects();
  }, [mutateAuthContext, mutateOrgs, mutateProjects]);

  useEffect(() => {
    if (!router.isReady || currentOrganizationId || !organizationsData?.results.length) return;

    let organizationId: string | undefined;
    const organizationIdFromLocalStorage = getFromLocalStorage<string>(
      LocalStorageKeys.ORGANIZATION_ID
    );

    // Get organizationId from query params, local storage, or default to Presien, Aptella, or the first organization in the array
    if (router.query.organizationId) {
      organizationId = router.query.organizationId as string;
    } else if (organizationIdFromLocalStorage) {
      organizationId = organizationIdFromLocalStorage;
    } else {
      const presienOrgId = organizationsData.results.find(({ name }) => name === 'Presien')?.id;
      const aptellaOrgId = organizationsData.results.find(({ name }) => name === 'Aptella')?.id;
      const firstOrgId = organizationsData.results[0]?.id;
      organizationId = presienOrgId || aptellaOrgId || firstOrgId;
    }

    // Check that the user is a member of the organization
    if (organizationId && !organizationsData.results.some(({ id }) => id === organizationId)) {
      organizationId = organizationsData.results[0]?.id;
    }

    if (!organizationId) return;

    changeOrganization(organizationId);
  }, [
    organizationsData,
    changeOrganization,
    router.query.organizationId,
    router.isReady,
    currentOrganizationId
  ]);

  useEffect(() => {
    if (!router.isReady || !currentOrganizationId) return;
    if (currentOrganizationId !== router.query.organizationId) {
      router.replace({
        query: { ...router.query, organizationId: currentOrganizationId }
      });
    }
  }, [currentOrganizationId, router]);

  const value = useMemo(
    () => ({
      user: authContext?.user,
      roles: authContext?.roles,
      globalAdmin: authContext?.globalAdmin,
      authType: authContext?.authType,
      currentOrganizationId,
      currentOrganization: organizationsData?.results.find(
        ({ id }) => id === currentOrganizationId
      ),
      // The projects the user has access to in the current organization
      currentOrganizationProjectIds: projectsData?.results
        ?.filter(({ id }) => Object.keys(authContext?.roles?.projects ?? {}).includes(id))
        .map(({ id }) => id)
        .join(','),
      currentOrganizationRole:
        (currentOrganizationId && authContext?.roles?.organizations?.[currentOrganizationId]) ||
        null,
      allOrganizations: organizationsData?.results,
      allOrganizationIds: Object.keys(authContext?.roles?.organizations ?? {}).join(','),
      changeOrganization,
      refetchAllQueries
    }),
    [
      authContext,
      currentOrganizationId,
      organizationsData?.results,
      projectsData?.results,
      changeOrganization,
      refetchAllQueries
    ]
  );

  if (isLoadingAuthContext || isLoadingOrgs || isLoadingProjects) return <Loading />;

  if (!currentOrganizationId) return <Error error='User is not a member of any organization.' />;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
