const style: React.CSSProperties = { maskType: 'luminance' };

export const FrannaTopSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='283'
    height='500'
    viewBox='0 0 283 500'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_172_3150)'>
      <path
        d='M117.998 287.307L113.172 287.282L112.953 328.967'
        stroke='black'
        strokeWidth='0.547442'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M169.118 329.262L169.203 312.954L169.336 287.577L164.51 287.552'
        stroke='black'
        strokeWidth='0.547442'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M112.953 328.967L130.119 355.823L151.672 355.937L169.118 329.261'
        stroke='black'
        strokeWidth='0.547442'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M164.51 287.552L164.614 267.745'
        stroke='black'
        strokeWidth='0.547442'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M151.563 246.236L152.481 71.1601L132.297 71.0542L131.378 246.13'
        stroke='black'
        strokeWidth='0.547442'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M122.963 344.629L122.866 363.245L159.285 363.436L159.386 344.141'
        stroke='black'
        strokeWidth='0.547442'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask
        id='mask0_172_3150'
        style={style}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='283'
        height='501'
      >
        <path
          d='M282.902 1.47076L280.286 500.001L0.000142319 498.531L2.61523 0.000488281L282.902 1.47076Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_172_3150)'>
        <path
          d='M118.102 267.501C118.102 267.501 129.09 264.109 131.378 246.131'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.346 462.542L122.766 382.42C122.774 380.796 124.098 379.486 125.721 379.495L156.261 379.655C157.885 379.664 159.194 380.987 159.185 382.611L158.765 462.733C158.756 464.357 157.433 465.666 155.81 465.657L125.27 465.497C123.647 465.489 122.337 464.166 122.346 462.542Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.65 380.91C158.65 380.91 151.277 384.457 149.503 387.958C148.115 390.698 148.516 408.857 158.877 441.242'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.642 380.726C123.642 380.726 130.978 384.35 132.715 387.87C134.074 390.624 133.157 409.075 122.456 441.349'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.073 371.642L130.679 379.522L151.303 379.63L147.992 371.715L134.073 371.642Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.184 382.743L159.286 363.436L122.866 363.245L122.765 382.552'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.616 451.098C138.799 451.089 137.317 452.554 137.308 454.372C137.298 456.189 138.764 457.67 140.581 457.68C142.399 457.689 143.88 456.224 143.89 454.406C143.899 452.589 142.433 451.108 140.616 451.098Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.055 465.55L135.045 467.305L146.454 467.365L146.463 465.61L135.055 465.55Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.337 287.577L192.108 296.472C192.108 296.472 195.611 297.807 195.593 301.317L194.874 438.271L192.106 464.093L163.585 463.944L158.765 462.734'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.585 463.944L163.567 467.454L186.822 467.576L188.596 464.076L163.585 463.944Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.147 386.443L159.166 386.254'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.445 329.399L169.118 329.261'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M194.896 434.27L158.915 434.081'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.535 296.671L192.585 287.031C192.585 287.031 192.616 283.31 190.422 283.299L164.533 283.163'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.173 287.283L90.3098 295.939C90.3098 295.939 86.7928 297.237 86.7744 300.747L86.0559 437.701L88.5532 463.551L117.074 463.7L121.907 462.541'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.073 463.701L117.055 467.211L93.7992 467.089L92.0625 463.569L117.073 463.701Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.3271 385.871L122.746 386.062'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.626 328.828L112.953 328.966'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.0762 433.699L122.057 433.888'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.8801 296.133L89.9307 286.493C89.9307 286.493 89.9392 282.772 92.1334 282.783L118.022 282.919'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.222 439.829L167.16 451.676L176.813 451.727L176.875 439.88L167.222 439.829Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.382 446.423L169.329 456.515L174.594 456.543L174.647 446.45L169.382 446.423Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.445 434.136L169.415 439.84L174.681 439.868L174.711 434.163L169.445 434.136Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.949 427.499L158.924 432.326L167.7 432.372L167.725 427.545L158.949 427.499Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.712 434.164C174.712 434.164 175.185 427.584 167.726 427.545'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.699 432.372C167.699 432.372 169.454 432.381 169.445 434.136'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.12 395.029L159.097 399.417L165.679 399.452L165.702 395.064L159.12 395.029Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.847 399.473C165.364 399.45 161.71 403.065 161.687 407.548C161.663 412.032 165.279 415.685 169.762 415.708C174.245 415.732 177.899 412.117 177.922 407.634C177.946 403.15 174.33 399.497 169.847 399.473Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.829 407.799C166.133 407.79 164.751 409.158 164.742 410.855C164.733 412.551 166.101 413.933 167.797 413.942C169.493 413.951 170.876 412.583 170.885 410.887C170.894 409.191 169.526 407.808 167.829 407.799Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.944 399.479C170.944 399.479 170.528 395.089 165.701 395.063'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.643 400.112L165.679 399.451'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.394 461.169L136.38 463.802L140.768 463.825L140.781 461.192L136.394 461.169Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.563 246.236C153.663 264.238 164.615 267.744 164.615 267.744L164.695 252.343C164.695 252.343 164.27 249.813 162.517 249.365C160.817 248.931 159.009 248.908 157.245 250.654C155.48 252.4 156.808 256.984 156.808 256.984C156.808 256.984 159.372 263.391 164.192 264.732'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.918 178.663L151.564 246.236L187.105 246.423L187.46 178.85L151.918 178.663Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.459 178.85L167.526 130.916L152.168 130.835L152.192 126.219L189.981 126.417C189.981 126.417 197.386 126.684 197.37 129.756L197.031 194.486C197.031 194.486 195.669 203.027 193.037 203.013L187.332 202.983'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.102 181.094L187.448 181.043'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.217 159.155L179.227 159.061'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.336 136.338L169.692 136.193'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.058 151.897L152.028 157.601L158.61 157.636L158.64 151.931L152.058 151.897Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.369 195.962L187.332 202.983L192.159 203.008L192.196 195.987L187.369 195.962Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.316 206.054L187.222 224.044L195.559 224.088L195.653 206.098L187.316 206.054Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.633 210.047L195.607 214.874L198.679 214.89L198.704 210.063L195.633 210.047Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.559 224.089C195.559 224.089 191.981 237.006 187.154 236.98'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.653 206.098L192.159 203.008'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.911 129.717L189.89 133.666L197.35 133.705L197.37 129.756L189.911 129.717Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.89 133.666L189.907 130.594L174.549 130.514L174.533 133.585L189.89 133.666Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.761 208.5L151.563 246.236L155.951 246.259L156.149 208.524L151.761 208.5Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.204 227.555L156.05 227.392'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.302 208.687L156.148 208.523'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.443 181.921L183.274 181.899L152.34 181.737'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.274 181.899L183.245 187.463L163.648 187.36C163.648 187.36 156.259 187.322 151.886 184.517'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.912 181.866L176.884 187.131'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.01 227.544L185.231 185.42'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.476 185.411L185.231 185.42'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.694 252.448L164.727 246.305'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.757 246.248L153.741 249.32L159.006 249.347L159.022 246.276L153.757 246.248Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.563 246.236C153.663 264.238 164.615 267.744 164.615 267.744L164.695 252.343C164.695 252.343 164.27 249.813 162.517 249.365C160.817 248.931 159.009 248.908 157.245 250.654C155.48 252.4 156.808 256.984 156.808 256.984C156.808 256.984 159.372 263.391 164.192 264.732'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.918 178.663L151.564 246.236L187.105 246.423L187.46 178.85L151.918 178.663Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.459 178.85L167.526 130.916L152.168 130.835L152.192 126.219L189.981 126.417C189.981 126.417 197.386 126.684 197.37 129.756L197.031 194.486C197.031 194.486 195.669 203.027 193.037 203.013L187.332 202.983'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.102 181.094L187.448 181.043'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.217 159.155L179.227 159.061'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.336 136.338L169.692 136.193'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.058 151.897L152.028 157.601L158.61 157.636L158.64 151.931L152.058 151.897Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.369 195.962L187.332 202.983L192.159 203.008L192.196 195.987L187.369 195.962Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.316 206.054L187.222 224.044L195.559 224.088L195.653 206.098L187.316 206.054Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.633 210.047L195.607 214.874L198.679 214.89L198.704 210.063L195.633 210.047Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.559 224.089C195.559 224.089 191.981 237.006 187.154 236.98'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M195.653 206.098L192.159 203.008'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.911 129.717L189.89 133.666L197.35 133.705L197.37 129.756L189.911 129.717Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.89 133.666L189.907 130.594L174.549 130.514L174.533 133.585L189.89 133.666Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.761 208.5L151.563 246.236L155.951 246.259L156.149 208.524L151.761 208.5Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.204 227.555L156.05 227.392'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.302 208.687L156.148 208.523'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.443 181.921L183.274 181.899L152.34 181.737'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.274 181.899L183.245 187.463L163.648 187.36C163.648 187.36 156.259 187.322 151.886 184.517'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.912 181.866L176.884 187.131'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.01 227.544L185.231 185.42'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.476 185.411L185.231 185.42'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.694 252.448L164.727 246.305'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.757 246.248L153.741 249.32L159.006 249.347L159.022 246.276L153.757 246.248Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.379 246.13C129.091 264.109 118.103 267.5 118.103 267.5L118.184 252.099C118.184 252.099 118.636 249.573 120.393 249.144C122.099 248.727 123.906 248.724 125.652 250.488C127.398 252.252 126.023 256.822 126.023 256.822C126.023 256.822 123.392 263.201 118.558 264.493'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.733 178.558L131.379 246.131L95.8369 245.944L96.1914 178.371L131.733 178.558Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.1918 178.37L116.627 130.648L131.984 130.729L132.009 126.113L94.2203 125.915C94.2203 125.915 86.8123 126.104 86.7962 129.176L86.4566 193.906C86.4566 193.906 87.7282 202.46 90.361 202.474L96.0652 202.504'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.5264 180.514L96.1798 180.565'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.6416 158.575L104.632 158.669'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M86.7607 135.758L114.404 135.903'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.874 151.791L131.844 157.496L125.262 157.461L125.292 151.757L131.874 151.791Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.1019 195.483L96.0651 202.504L91.2386 202.479L91.2754 195.458L96.1019 195.483Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.049 205.575L95.9546 223.566L87.6175 223.522L87.7119 205.532L96.049 205.575Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.6916 209.481L87.6663 214.307L84.5948 214.291L84.6201 209.465L87.6916 209.481Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.6172 223.522C87.6172 223.522 91.0595 236.476 95.8864 236.501'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.7124 205.532L91.2387 202.479'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.2552 129.215L94.2345 133.165L86.7752 133.125L86.7959 129.176L94.2552 129.215Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.2349 133.164L94.251 130.093L109.609 130.173L109.593 133.245L94.2349 133.164Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.577 208.395L131.379 246.13L126.992 246.107L127.189 208.372L131.577 208.395Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.9355 227.076L127.089 227.24'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.0352 208.208L127.189 208.372'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.1758 181.442L100.344 181.464L131.279 181.627'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.344 181.464L100.315 187.028L119.911 187.131C119.911 187.131 127.3 187.17 131.703 184.411'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.706 181.498L106.678 186.763'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.1306 227.087L98.3516 184.964'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.107 184.974L98.3516 184.964'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.184 252.204L118.216 246.062'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.186 246.119L129.169 249.19L123.904 249.163L123.92 246.091L129.186 246.119Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.105 74.5898L137.059 83.3656L147.152 83.4185L147.198 74.6428L137.105 74.5898Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.151 83.4185L146.07 289.649C146.07 289.649 152.201 291.945 152.15 301.563C152.1 311.181 152.257 323.03 152.257 323.03'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.287 71.1489L149.521 217.265C149.521 217.265 149.833 241.401 151.563 246.236'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.378 246.13C131.378 246.13 132.994 239.329 133.041 230.228L133.588 126.121C133.588 126.121 136.884 116.714 137.059 83.3657'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.105 74.5898L137.085 78.539L147.177 78.592L147.198 74.6428L137.105 74.5898Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.297 71.0542L137.084 78.5389'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.177 78.5922L150.274 73.7817'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.242 57.5039C138.486 57.4842 135.424 60.5131 135.405 64.2696C135.385 68.0257 138.414 71.0865 142.17 71.1062C145.927 71.1259 148.988 68.0971 149.007 64.3409C149.027 60.5844 145.998 57.5236 142.242 57.5039Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.227 60.1366C139.925 60.1245 138.049 61.9811 138.037 64.2833C138.025 66.5854 139.881 68.4616 142.183 68.4737C144.486 68.4857 146.362 66.6292 146.374 64.327C146.386 62.0249 144.529 60.1487 142.227 60.1366Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.14 54.3896L134.052 71.0634L150.726 71.1509L150.813 54.4771L134.14 54.3896Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.43 35.9079L136.333 54.401L149.058 54.4678L149.155 35.9747C149.165 34.1685 147.708 32.6963 145.901 32.6868L139.718 32.6544C137.911 32.6449 136.44 34.1018 136.43 35.9079Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.04 40.562L138.996 48.9615C138.99 50.0348 139.855 50.9095 140.929 50.9151L144.501 50.9338C145.575 50.9395 146.449 50.074 146.455 49.0006L146.499 40.6012C146.505 39.5278 145.639 38.6532 144.566 38.6476L140.993 38.6288C139.92 38.6232 139.045 39.4887 139.04 40.562Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.477 44.8008L139.018 44.7617'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.958 32.6558L139.94 36.0696L145.644 36.0995L145.662 32.6857L139.958 32.6558Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.774 61.9365L150.726 71.1509L152.481 71.1601L152.529 61.9457L150.774 61.9365Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.47 73.354L152.421 82.5684L154.176 82.5776L154.225 73.3632L152.47 73.354Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.53 73.2388L130.482 82.4532L132.237 82.4624L132.285 73.248L130.53 73.2388Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.346 61.8398L132.297 71.0543L134.052 71.0635L134.101 61.8491L132.346 61.8398Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.131 100.986L147.06 100.97'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M146.719 165.911L133.556 165.842'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.398 164.456L120.343 174.987L127.803 175.026L127.858 164.495L120.398 164.456Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.634 166.202L118.599 172.784L120.354 172.793L120.389 166.211L118.634 166.202Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.491 155.102L159.062 155.005'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.837 154.826L106.408 154.729'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.902 333.175L123.867 339.792C123.855 342.085 125.704 343.953 127.997 343.965L139.879 344.028C142.171 344.04 144.04 342.191 144.052 339.898L144.086 333.281C144.098 330.989 142.25 329.121 139.957 329.109L128.075 329.046C125.782 329.034 123.914 330.883 123.902 333.175Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M125.652 334.088L125.626 338.898C125.617 340.721 127.086 342.205 128.908 342.215L138.984 342.268C140.806 342.277 142.291 340.808 142.3 338.986L142.325 334.176C142.335 332.354 140.866 330.869 139.044 330.859L128.968 330.806C127.146 330.797 125.661 332.266 125.652 334.088Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.413 346.61L130.365 355.825L151.865 355.938L151.914 346.723L130.413 346.61Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.888 351.549L130.388 351.437'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.479 301.002L135.364 322.941L145.895 322.997L146.01 301.057L135.479 301.002Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.35 331.967L154.941 331.902L155.04 312.952L169.204 313.026'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.51 287.551L155.162 312.952'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.27 300.301L160.056 300.253'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.378 331.909L156.34 339.286L148.003 339.243L148.056 329.15L154.953 329.187'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.258 312.733L126.202 312.801L126.149 322.893L154.67 323.043'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.029 281.375C118.029 281.375 132.039 287.381 131.938 306.688L131.855 322.484'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.089 323.008L148.188 304.175C148.188 304.175 150.909 287.427 164.553 279.407'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.447 312.792C124.447 312.792 124.98 294.804 117.999 287.308L118.103 267.501'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.832 272.398L119.793 279.857L121.987 279.869L122.026 272.409L119.832 272.398Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.3 300.019L115.233 312.743L121.815 312.778L121.882 300.053L115.3 300.019Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.858 304.441L115.276 304.406'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.896 307.278L160.867 312.983L164.377 313.001L164.407 307.297L160.896 307.278Z'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.114 329.526L126.14 324.649L120.874 324.621L120.83 332.958L123.902 332.974'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.349 329.56L145.894 322.997'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141.398 343.746L141.382 346.668L146.209 346.693L146.244 340.111L144.042 340.1'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.723 256.209L141.418 256.276L141.432 253.643L129.727 253.581'
          stroke='black'
          strokeWidth='0.547442'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_172_3150'>
        <rect
          width='498.537'
          height='280.29'
          fill='white'
          transform='translate(282.901 1.47021) rotate(90.3005)'
        />
      </clipPath>
    </defs>
  </svg>
);
