import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

export interface SelectOption<ValueType = string> {
  label: string;
  value?: ValueType | undefined;
}

export const getLabelFromValue = (options: SelectOption[], value: string) =>
  options.find(option => option.value === value)?.label ?? '';

interface SelectProps<ValueType> {
  label: string;
  options: SelectOption<ValueType>[];
  value: ValueType | string;
  onChange: (value: ValueType) => void;
  disabled?: boolean;
}

export const Select = <ValueType extends string>({
  label,
  options,
  value,
  onChange,
  disabled
}: SelectProps<ValueType>) => {
  return (
    <div className={twMerge('text-gray-700', disabled ? 'pointer-events-none opacity-40' : '')}>
      <Listbox onChange={onChange}>
        <Label className='block font-medium leading-4 text-gray-500'>{label}</Label>
        <ListboxButton className='mt-3 flex w-full items-center justify-between border border-neutral-300 px-4 py-3 transition-[border-radius] duration-300 focus:outline-none ui-open:rounded-t-xl ui-not-open:rounded-xl'>
          {options.find(option => option.value === value)?.label ?? 'Please select'}
          <ChevronDownIcon className='h-5 w-5 transition-transform duration-300 ui-open:rotate-180 ui-not-open:rotate-0' />
        </ListboxButton>
        <Transition
          enter='transition-[max-height,opacity] ease-in duration-300'
          enterFrom='opacity-0 max-h-0 border-0'
          enterTo='opacity-100 max-h-60 border border-t-0'
          leave='transition-[max-height,opacity] ease-out duration-300'
          leaveFrom='opacity-100 max-h-60 border border-t-0'
          leaveTo='opacity-0 max-h-0 border-0'
        >
          <ListboxOptions
            anchor='bottom'
            className='w-[var(--button-width)] overflow-auto rounded-b-xl border border-t-0 bg-white [--anchor-max-height:18rem] focus:outline-none'
          >
            {options.map(({ label, value }, idx) => (
              <ListboxOption key={label} className='select-none' value={value ?? label}>
                <p className='block truncate px-4 py-3 ui-active:bg-brand/10'>{label}</p>
                {idx !== options.length - 1 && <hr className='mx-4 my-1' />}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </Listbox>
    </div>
  );
};
