import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Notification, NotificationType } from '../components/atoms/Notification/Notification';

/**
 * This file was taken from https://github.com/iamkd/react-update-notification/blob/master/src/types.ts
 * and modified to work with the nextjs server side rendered window object.
 */

interface VersionFileResponse {
  version: string;
}

interface UpdateHookParams {
  type: 'mount' | 'interval' | 'manual';
  interval?: number;
}

export enum UpdateStatus {
  CHECKING = 'checking',
  CURRENT = 'current',
  AVAILABLE = 'available'
}

const CLOUD_VERSION_FILE = 'version.json';

export const useUpdateCheck = ({ interval, type }: UpdateHookParams) => {
  const reloadPage = useCallback(() => window.location.reload(), []);

  const [status, setStatus] = useState<UpdateStatus>(UpdateStatus.CHECKING);

  const checkUpdate = useCallback(() => {
    const currentVersion = process.env.COMMIT_HASH;

    if (currentVersion === undefined || window.location.hostname.includes('localhost')) {
      setStatus(UpdateStatus.CURRENT);
      return;
    }

    setStatus(UpdateStatus.CHECKING);

    const requestStr = `/${CLOUD_VERSION_FILE}?v=${Date.now()}`;

    fetch(requestStr)
      .then<VersionFileResponse>(res => res.json())
      .then(data => {
        if (data.version === currentVersion) {
          setStatus(UpdateStatus.CURRENT);
        } else {
          setStatus(UpdateStatus.AVAILABLE);
        }
      })
      .catch(() => setStatus(UpdateStatus.CURRENT));
  }, []);

  useEffect(() => {
    if (type !== 'manual') checkUpdate();
  }, [checkUpdate, type]);

  useEffect(() => {
    if (status !== UpdateStatus.CURRENT) return;

    if (type === 'interval') {
      const timeoutId = setTimeout(() => checkUpdate(), interval || 10_000);
      return () => clearTimeout(timeoutId);
    }
  }, [type, interval, status, checkUpdate]);

  useEffect(() => {
    if (status === UpdateStatus.AVAILABLE) {
      toast(
        () => (
          <Notification
            description='New version available'
            buttonLabel='Update'
            onButtonClick={reloadPage}
            type={NotificationType.Success}
          />
        ),
        { duration: Number.POSITIVE_INFINITY }
      );
    }
  }, [reloadPage, status]);

  return { status, checkUpdate };
};
