import { BalfourBeattyLogo, JCBLogo, PresienLogo, TopconLogo } from '@fleet/components';

export type BrandingLogo = {
  src: string;
  width: number;
  height: number;
};

export type BrandingTheme = {
  origins: string[];
  logo: BrandingLogo;
  color: string;
};

type BrandingThemes = BrandingTheme[];

export const BRANDING_THEMES: BrandingThemes = [
  {
    origins: [
      'http://localhost:3001',
      'https://fleet-nightly.blindsight.ai',
      'https://fleet-uat.blindsight.ai',
      'https://fleet.blindsight.ai',
      'https://enterprise-nightly.blindsight.ai',
      'https://enterprise-uat.blindsight.ai',
      'https://enterprise.blindsight.ai'
    ],
    logo: { src: 'https://enterprise.blindsight.ai/blindsight.svg', width: 51, height: 34 },
    color: '255, 125, 51'
  },
  {
    origins: ['https://hexagon.blindsight.ai'],
    logo: { src: 'https://hexagon.blindsight.ai/hexagon.png', width: 150, height: 50 },
    color: '52, 153, 190'
  },
  {
    origins: ['https://jcb.blindsight.ai'],
    logo: { src: 'https://jcb.blindsight.ai/jcb.png', width: 100, height: 50 },
    color: '231, 175, 40'
  },
  {
    origins: ['https://topcon.blindsight.ai'],
    logo: { src: 'https://topcon.blindsight.ai/topcon.svg', width: 130, height: 50 },
    color: '0, 125, 197'
  },
  {
    origins: ['https://trimble.blindsight.ai'],
    logo: { src: 'https://trimble.blindsight.ai/trimble.svg', width: 130, height: 50 },
    color: '0, 99, 163'
  },
  {
    origins: ['https://balfourbeatty.blindsight.ai'],
    logo: { src: 'https://balfourbeatty.blindsight.ai/balfour-beatty.png', width: 120, height: 20 },
    color: '74, 115, 138'
  }
];

export interface SageBrandingTheme {
  origins: string[];
  logo: React.ReactNode;
  theme: string;
}

export const SAGE_BRANDING_THEMES: SageBrandingTheme[] = [
  {
    origins: [
      'http://localhost:3001',
      'https://fleet-nightly.blindsight.ai',
      'https://fleet-uat.blindsight.ai',
      'https://fleet.blindsight.ai',
      // 'https://enterprise-nightly.blindsight.ai',
      // 'https://enterprise-uat.blindsight.ai',
      'https://enterprise.blindsight.ai'
    ],
    logo: <PresienLogo />,
    theme: 'Presien'
  },
  {
    origins: ['https://balfourbeatty.blindsight.ai'],
    logo: <BalfourBeattyLogo />,
    theme: 'Balfour Beatty'
  },
  {
    origins: [
      'https://jcb.blindsight.ai',
      'https://enterprise-nightly.blindsight.ai',
      'https://enterprise-uat.blindsight.ai'
    ],
    logo: <JCBLogo />,
    theme: 'JCB'
  },
  {
    origins: ['https://topcon.blindsight.ai'],
    logo: <TopconLogo />,
    theme: 'Topcon'
  }
];
