import { useApi } from './useApiHooks';
import type { Organization } from './useGetOrganizations';
import { generateURLSearchParams } from '../../utils';

export interface GetAdminOrganizationsQuery {
  organizationIds?: string;
  select?: (keyof Organization)[];
}

export const useGetAdminOrganizations = (
  queryParams: GetAdminOrganizationsQuery = {},
  disableQuery = false
) =>
  useApi<Organization[]>(
    'get',
    disableQuery ? null : `/admin/organizations${generateURLSearchParams(queryParams)}`
  );
