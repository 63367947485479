import { twMerge } from 'tailwind-merge';

interface ErrorProps {
  error?: string | string[];
  className?: string;
}

export const Error: React.FC<ErrorProps> = ({ error, className }) => (
  <div
    className={twMerge(
      'flex h-full items-center justify-center text-xl font-medium text-error',
      className
    )}
  >
    {error && Array.isArray(error) ? (
      <ul>
        {error.map(e => (
          <li key={e}>{e}</li>
        ))}
      </ul>
    ) : (
      <p>{error ?? 'Unexpected Error'}</p>
    )}
  </div>
);
