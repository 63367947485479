import { ReviewStatus } from '../const';

export const handleReviewStatus = (reviewStatus: ReviewStatus | undefined) => {
  switch (reviewStatus) {
    case ReviewStatus.ALL:
    case undefined:
      return [
        ReviewStatus.CONFIRMED,
        ReviewStatus.NOT_REVIEWED,
        ReviewStatus.REJECTED,
        ReviewStatus.IGNORED
      ].join(',') as ReviewStatus;
    // Fallback 'DEFAULT' case to handle old 'DEFAULT' url param if it was set
    // However, 'ALL' is now the default
    case ReviewStatus.CONFIRMED_OR_NOT_REVIEWED:
    case 'DEFAULT' as ReviewStatus:
      return [ReviewStatus.CONFIRMED, ReviewStatus.NOT_REVIEWED].join(',') as ReviewStatus;
    default:
      return reviewStatus;
  }
};
