import { useEffect, useRef } from 'react';

export const useOnClickOutside = <T extends HTMLElement>(callback: () => void) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const detectClick = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) return;
      event.stopPropagation();
      callback();
    };

    document.addEventListener('mouseup', detectClick);
    document.addEventListener('touchend', detectClick);

    return () => {
      document.removeEventListener('mouseup', detectClick);
      document.removeEventListener('touchend', detectClick);
    };
  }, [ref, callback]);

  return ref;
};
