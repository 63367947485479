const style: React.CSSProperties = { maskType: 'luminance' };

export const LoaderTopSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='282'
    height='500'
    viewBox='0 0 282 500'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_120_2133)'>
      <path
        d='M102.489 308.199L102.489 251.83L178.99 251.83L178.99 308.199L102.489 308.199Z'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask
        id='mask0_120_2133'
        style={style}
        maskUnits='userSpaceOnUse'
        x='0'
        y='-1'
        width='282'
        height='501'
      >
        <path
          d='M281.114 -0.000475993L281.114 499.999L0.000954707 499.999L0.000976562 -0.000488281L281.114 -0.000475993Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_120_2133)'>
        <path
          d='M102.49 251.829L102.49 248.206C102.49 244.951 105.129 242.313 108.383 242.313L173.098 242.313C176.353 242.313 178.991 244.951 178.991 248.206L178.991 251.829L102.49 251.829Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.81 394.948L103.588 394.948L103.588 331.99L177.892 331.99L177.892 394.948L118.229 394.948'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.588 329.063L103.588 308.199L177.892 308.199L177.892 329.063L103.588 329.063Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.991 306.002L102.49 306.002'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.062 329.062L176.062 331.991'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.784 329.062L105.784 331.991'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.725 303.074C108.603 303.074 106.882 301.354 106.882 299.231C106.882 297.108 108.603 295.388 110.725 295.388C112.848 295.388 114.569 297.108 114.569 299.231C114.569 301.354 112.848 303.074 110.725 303.074Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M128.661 352.855C126.538 352.855 124.817 351.134 124.817 349.011C124.817 346.889 126.538 345.168 128.661 345.168C130.783 345.168 132.504 346.889 132.504 349.011C132.504 351.134 130.783 352.855 128.661 352.855Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.811 389.275C109.811 386.748 111.695 384.699 114.02 384.699C116.345 384.699 118.229 386.748 118.229 389.275'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.229 389.092L118.229 394.948L118.229 396.778L109.811 396.778L109.811 394.948L109.811 389.092'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.954 396.778L177.918 396.778L177.918 409.912C177.918 409.912 174.639 436.31 160.793 439.604L121.756 439.604'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.924 446.925L164.519 446.925C165.985 446.925 167.44 446.643 168.783 446.057C170.838 445.161 173.561 443.374 175.462 439.865C175.555 439.694 175.63 439.51 175.687 439.324L183.231 414.841C183.352 414.447 183.425 414.042 183.442 413.63C183.521 411.759 183.22 406.882 177.918 406.947'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.892 396.778L103.651 396.778L103.651 409.912C103.651 409.912 106.943 436.31 120.84 439.604L160.023 439.604'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.652 406.947C98.3307 406.882 98.028 411.758 98.1071 413.63C98.1246 414.041 98.1971 414.447 98.3186 414.841L105.767 438.922C105.858 439.215 105.99 439.492 106.16 439.748C106.905 440.873 109.18 443.97 112.684 445.759C114.246 446.556 115.991 446.925 117.744 446.925L146.807 446.925'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.259 403.367L198.625 403.455L198.625 309.765L191.114 309.765L178.332 309.765'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.99 267.993L191.245 267.993L191.245 309.765'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.39 326.5L177.893 326.5'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.658 316.722C201.403 316.501 200.14 316.369 198.873 316.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.658 316.722L198.873 316.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 394.948L203.992 394.372'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 394.948C200.588 394.91 202.299 394.718 203.992 394.372'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 368.509L209.205 355.34'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 362.031L209.205 348.862'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 366.889L209.205 353.721'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 360.413L209.205 347.244'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 355.554L209.205 342.385'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M209.205 392.8L209.205 318.727'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M210.104 390.365L210.104 321.161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 342.598L209.205 329.43'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 336.121L209.205 322.952'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 340.979L209.205 327.811'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 349.076L209.205 335.907'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 347.457L209.205 334.288'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 321.546L202.658 316.722'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M209.204 318.726C208.763 318.531 208.318 318.347 207.87 318.175'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.148 319.097C206.816 318.975 206.482 318.861 206.147 318.753'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.976 317.937C202.603 317.865 202.23 317.801 201.855 317.745'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M206.882 317.814C205.852 317.46 204.809 317.168 203.758 316.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M210.103 321.162L209.205 318.728'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M209.204 318.726L207.87 318.175'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.871 318.176L207.147 319.098'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.148 319.097L206.147 318.753'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M206.146 318.753L206.883 317.814'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M206.882 317.814L203.758 316.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.759 316.938L202.976 317.937'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.976 317.937L201.855 317.745'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.854 317.745L202.658 316.722'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 329.644L207.871 318.176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 323.165L203.758 316.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 334.502L209.205 321.333'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 328.024L206.883 317.815'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 353.934L209.205 340.766'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 387.942L209.205 374.773'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 386.322L209.205 373.153'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 381.465L209.205 368.296'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 374.987L209.205 361.818'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 379.845L209.205 366.676'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 373.367L209.205 360.198'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 392.801L209.205 379.632'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 394.42L209.205 381.251'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.992 394.372L209.205 387.729'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.546 394.012L209.204 389.349'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.873 393.803C199.038 393.799 199.204 393.794 199.369 393.787'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.103 392.956C205.649 392.819 206.194 392.665 206.735 392.495'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.546 394.011C206.781 393.689 208.002 393.284 209.204 392.8'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.992 394.373L205.103 392.957'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.103 392.956L206.735 392.495'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M206.736 392.496L205.547 394.012'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.546 394.011L209.204 392.8'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M209.205 392.801L210.103 390.366'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.756 394.948L177.526 394.948'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.0977 309.765L103.149 309.765'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.221 403.367L82.8555 403.455L82.8555 309.765L90.3664 309.765L95.9009 309.765'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.49 267.994L90.2354 267.994L90.2354 273.726L90.2353 309.765'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.0977 326.5L103.588 326.5'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.0898 326.5L95.901 326.5'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.8232 316.722C80.078 316.501 81.3415 316.369 82.608 316.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.8232 316.722L82.608 316.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.6076 394.948L77.4883 394.372'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.6076 394.948C80.8927 394.91 79.1819 394.718 77.4883 394.372'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 368.509L72.2754 355.34'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 362.031L72.2754 348.862'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 366.889L72.2754 353.721'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 360.412L72.2754 347.243'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 355.554L72.2754 342.385'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M72.2764 392.8L72.2764 318.727'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M71.377 390.365L71.377 321.161'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 342.598L72.2754 329.43'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 336.121L72.2754 322.952'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 340.979L72.2754 327.811'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 349.076L72.2754 335.907'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 347.457L72.2754 334.288'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 321.546L78.8223 316.722'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M72.2764 318.726C72.7182 318.531 73.1629 318.347 73.6106 318.175'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M74.333 319.098C74.6654 318.976 74.9988 318.862 75.3337 318.754'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.5059 317.937C78.8781 317.865 79.2518 317.801 79.6259 317.745'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M74.5986 317.814C75.6286 317.46 76.6715 317.168 77.7231 316.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M71.377 321.161L72.2752 318.727'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M72.2764 318.726L73.6106 318.175'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.6094 318.176L74.333 319.098'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M74.333 319.098L75.3337 318.754'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.3337 318.753L74.5977 317.814'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M74.5986 317.814L77.7231 316.938'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.7217 316.938L78.505 317.937'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.5059 317.937L79.6259 317.745'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.6253 317.745L78.8223 316.722'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.6072 329.644L73.6094 318.176'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.6077 323.166L77.7227 316.939'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 334.502L72.2754 321.333'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.6081 328.024L74.5986 317.815'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 353.934L72.2754 340.766'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 387.942L72.2754 374.773'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 386.323L72.2754 373.154'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 381.465L72.2754 368.296'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 374.987L72.2754 361.818'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 379.845L72.2754 366.676'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 373.367L72.2754 360.198'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 392.801L72.2754 379.632'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.607 394.42L72.2754 381.251'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4881 394.372L72.2754 387.729'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.9339 394.011L72.2754 389.348'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.6077 393.803C82.4422 393.799 82.2768 393.794 82.1113 393.787'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.3776 392.957C75.8308 392.82 75.2865 392.666 74.7451 392.496'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.9339 394.011C74.6985 393.689 73.4774 393.284 72.2754 392.8'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.4885 394.373L76.3779 392.957'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.3776 392.957L74.7451 392.496'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M74.7451 392.496L75.9344 394.012'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.9339 394.011L72.2754 392.8'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M72.2752 392.801L71.377 390.366'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.7246 394.948L103.954 394.948'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.9014 350.658L95.9014 326.5L95.9014 303.44C95.9014 302.833 96.3929 302.342 96.9995 302.342C97.606 302.342 98.0976 302.833 98.0976 303.44L98.0976 326.5L98.0976 350.658C98.0976 351.265 97.606 351.756 96.9995 351.756C96.3929 351.756 95.9014 351.265 95.9014 350.658Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.626 273.792L90.0447 273.792'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.626 300.512L90.0447 300.512'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.626 273.792L81.626 300.512'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M88.5811 273.792L88.5811 300.512'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.37 156.443C200.115 156.222 199.657 155.972 198.391 155.929'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.757 234.26C200.472 234.223 201.011 234.439 202.704 234.094'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.024 207.67L207.917 195.062'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M192.899 207.724L207.916 188.584'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.569 207.906L207.917 193.442'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.486 207.906L207.916 186.964'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M187.775 207.778L207.917 182.106'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.916 232.521L207.916 158.447'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M208.814 230.086L208.814 160.882'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.839 197.291L207.917 169.151'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.945 190.677L207.917 162.673'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.717 195.827L207.917 167.531'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.945 203.632L207.917 175.628'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.917 202.049L207.917 174.009'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.916 158.448C207.474 158.252 207.03 158.068 206.582 157.896'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.859 158.818C205.527 158.697 205.193 158.583 204.858 158.475'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.686 157.658C201.314 157.586 200.94 157.522 200.566 157.466'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.594 157.536C204.564 157.182 203.521 156.89 202.47 156.66'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M208.815 160.883L207.917 158.448'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.916 158.448L206.582 157.896'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M206.582 157.896L205.858 158.818'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.859 158.818L204.858 158.475'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.857 158.475L205.594 157.536'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.594 157.536L202.47 156.66'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.47 156.66L201.687 157.659'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.686 157.658L200.566 157.466'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.567 157.467L201.37 156.443'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.945 184.48L206.583 157.896'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.945 178.257L202.47 156.66'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.804 189.238L207.916 161.054'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.945 183.016L205.594 157.536'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M186.404 207.906L207.917 180.487'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.897 224.378L207.916 212.875'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.829 219.619L207.917 208.017'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.756 213.215L207.916 201.54'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.978 217.789L207.916 206.397'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.779 211.566L207.917 199.92'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.016 230.599L207.917 219.352'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.001 232.43L207.916 220.972'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.704 234.094L207.917 227.45'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.258 233.733L207.916 229.069'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.814 232.678C204.361 232.541 204.906 232.387 205.447 232.217'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.258 233.733C205.493 233.41 206.714 233.006 207.916 232.521'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.704 234.094L203.815 232.678'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M203.814 232.678L205.447 232.217'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M205.447 232.217L204.258 233.733'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M204.258 233.733L207.916 232.521'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M207.917 232.521L208.815 230.086'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.926 155.929C195.15 155.967 192.823 156.316 191.069 156.661'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.673 158.561L185.673 207.54'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.773 160.996L184.773 207.54'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.931 225.948L207.917 214.494'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.672 176.588L196.003 163.42L197.585 161.267L201.37 156.443L185.672 176.588Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.391 155.929L185.673 171.73'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.927 155.929L185.673 170.11'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M191.07 156.662L185.673 163.633'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.606 157.027L185.673 162.013'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M196.368 157.193C196.653 157.197 196.937 157.202 197.222 157.209'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.774 158.039C189.35 158.165 188.928 158.307 188.508 158.464'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M189.606 157.027L185.58 158.491'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.58 158.492L184.774 160.996'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.036 240.117L172.036 207.906L198.756 207.906L198.756 240.117L172.036 240.117Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.036 237.188L172.036 214.494L176.062 214.494L176.062 237.188L172.036 237.188Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.756 216.324L176.062 216.324'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.062 221.449L172.036 221.449'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.906 207.906L174.906 159.521L170.338 159.521M170.338 159.521L170.338 121.464L167.264 121.464L167.264 236.09L170.25 236.09L170.338 159.521Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.847 198.023L174.964 198.023'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.847 190.336L174.964 190.336'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.036 218.155L170.206 218.155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.912 218.155L164.35 218.155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.035 232.429L170.571 232.429'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.277 232.429L164.349 232.429'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.349 241.991L164.349 171.73L144.773 171.73'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.558 165.08L144.584 165.08L144.584 218.154L140.375 218.154'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.584 210.468L142.021 210.468'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.584 214.128L142.021 214.128'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.584 225.475L144.584 206.808L147.512 206.808L147.512 225.475L144.584 225.475Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.375 218.154L142.754 218.154L142.754 221.083L140.375 221.083'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.036 128.477L172.036 109.077L174.964 109.077L174.964 128.477L172.036 128.477Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.52 128.477L162.52 109.077L165.448 109.077L165.448 128.477L162.52 128.477Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.244 154.831L148.244 137.627L151.172 137.627L151.172 154.831L148.244 154.831Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.218 151.537L144.218 134.333L147.146 134.333L147.146 151.537L144.218 151.537Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.218 128.477L144.218 113.47L146.048 113.47L146.048 128.477L144.218 128.477Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.036 127.379L170.572 127.379'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.278 127.379L165.813 127.379'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.036 122.986L170.572 122.986'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.278 122.986L165.813 122.986'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.912 167.642L144.584 167.642'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.361 149.707L138.361 139.092L142.754 139.092L142.754 149.707L138.361 149.707Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.754 165.08L142.754 129.941'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.558 121.156L144.218 121.156L144.218 129.941L140.558 129.941'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.278 153.001L151.173 153.001'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.244 153.001L142.754 153.001'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.361 149.707L138.361 165.08'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.728 105.051L138.728 84.9189L142.388 84.9189L142.388 105.051L138.728 105.051Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.388 88.5791L138.728 88.5791'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.858 105.783L158.858 85.6509L162.519 85.6509L162.519 105.783L158.858 105.783Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.519 89.3115L158.858 89.3115'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.99 105.783L178.99 85.6509L182.651 85.6509L182.651 105.783L178.99 105.783Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M182.651 89.3115L178.99 89.3115'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.122 105.783L199.122 85.6509L202.782 85.6509L202.782 105.783L199.122 105.783Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.782 89.3115L199.122 89.3115'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M216.326 73.938L216.326 53.0742L219.254 53.0742L219.254 73.938L216.326 73.938Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M219.254 63.3232L216.326 63.3232'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M190.703 73.938L190.703 53.0742L193.631 53.0742L193.631 73.938L190.703 73.938Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M193.631 63.3228L190.703 63.3228'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.036 73.938L172.036 53.0742L174.964 53.0742L174.964 73.938L172.036 73.938Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.964 63.3232L172.036 63.3232'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.538 73.938L151.538 53.0742L154.466 53.0742L154.466 73.938L151.538 73.938Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.467 63.3228L151.539 63.3228'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.13 92.9716L214.13 61.8589L216.326 61.8589L216.326 92.9716L214.13 92.9716Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.964 119.443L216.325 119.443L216.325 92.9717'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.129 92.9717L202.782 92.9717'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.123 92.9717L182.651 92.9717'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M216.326 100.659L203.148 100.659'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.757 100.658L182.651 100.658'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.991 100.658L162.52 100.658'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.991 92.9717L162.52 92.9717'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.859 100.658L142.388 100.658'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M158.859 92.9717L142.754 92.9717'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.7451 156.443C80.9999 156.222 81.4581 155.972 82.7243 155.929'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.3578 234.26C80.643 234.223 80.1038 234.439 78.4102 234.094'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M83.0896 207.67L73.1973 195.062'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M88.2151 207.724L73.1982 188.584'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.5463 207.906L73.1982 193.442'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M89.6283 207.906L73.1982 186.964'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M93.3395 207.777L73.1982 182.106'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.1982 232.521L73.1982 158.447'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M72.2998 230.086L72.2998 160.882'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.2762 197.291L73.1982 169.151'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.1697 190.677L73.1982 162.673'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.3981 195.827L73.1982 167.531'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.1697 203.632L73.1982 175.628'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.1979 202.05L73.1982 174.009'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.1982 158.448C73.64 158.252 74.0848 158.068 74.5324 157.896'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.2559 158.818C75.5882 158.697 75.9217 158.583 76.2566 158.475'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.4277 157.658C79.8 157.586 80.1733 157.522 80.5478 157.466'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.5205 157.536C76.5505 157.182 77.5933 156.89 78.645 156.66'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M72.2998 160.883L73.198 158.448'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.1982 158.448L74.5324 157.896'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M74.5322 157.896L75.2559 158.818'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.2559 158.818L76.2566 158.475'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.2566 158.475L75.5205 157.536'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.5205 157.536L78.645 156.66'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.6455 156.66L79.4288 157.658'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.4277 157.658L80.5478 157.466'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.5482 157.467L79.7451 156.443'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.1695 184.48L74.5322 157.896'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.17 178.257L78.6455 156.66'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.311 189.238L73.1982 161.054'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.1695 183.016L75.5205 157.536'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.7107 207.906L73.1982 180.487'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.2169 224.378L73.1982 212.875'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.2857 219.619L73.1982 208.017'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.3576 213.214L73.1973 201.539'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.1371 217.789L73.1982 206.397'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.3359 211.566L73.1982 199.92'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.0998 230.6L73.1982 219.353'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.1137 232.43L73.1982 220.972'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.4109 234.094L73.1982 227.45'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.8567 233.733L73.1982 229.069'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.2995 232.678C76.7526 232.541 76.2084 232.387 75.667 232.217'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.8567 233.733C75.6214 233.41 74.4003 233.006 73.1982 232.521'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.4103 234.094L77.2998 232.678'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M77.2995 232.678L75.667 232.217'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.667 232.217L76.8562 233.733'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.8567 233.733L73.1982 232.521'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M73.198 232.521L72.2998 230.086'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.1885 155.929C85.9641 155.967 88.2913 156.316 90.045 156.661'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.4424 158.561L95.4424 207.54'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.3418 160.996L96.3418 207.54'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.1847 225.948L73.1982 214.494'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.4428 176.588L85.1111 163.419L83.5299 161.267L79.7451 156.443L95.4428 176.588Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.7246 155.929L95.4428 171.73'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.1885 155.929L95.4425 170.11'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M90.0449 156.661L95.4424 163.633'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.5098 157.027L95.4431 162.013'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.7456 157.193C84.4608 157.197 84.1764 157.202 83.8916 157.209'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.3408 158.039C91.7651 158.165 92.1875 158.307 92.6073 158.464'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.5098 157.027L95.5361 158.491'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.5361 158.492L96.3418 160.996'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.079 240.117L109.079 207.906L82.3584 207.906L82.3584 240.117L109.079 240.117Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.078 237.188L109.078 214.494L105.052 214.494L105.052 237.188L109.078 237.188Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.3584 216.324L105.052 216.324'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.052 221.449L109.078 221.449'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.209 207.906L106.209 159.521L110.777 159.521M110.777 159.521L110.777 121.464L113.852 121.464L113.852 236.09L110.865 236.09L110.777 159.521Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.2676 198.023L106.15 198.023'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.2676 190.336L106.15 190.336'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.078 218.154L110.908 218.154'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.203 218.155L116.765 218.155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.078 232.429L110.542 232.429'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.837 232.429L116.765 232.429'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.766 241.991L116.766 171.73L136.341 171.73'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.021 218.155L142.021 177.525L139.093 177.525L139.093 218.155L142.021 218.155Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.558 165.08L136.531 165.08L136.531 218.155L140.741 218.155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.531 210.468L139.093 210.468'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.531 214.128L139.093 214.128'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.531 225.475L136.531 206.808L133.603 206.808L133.603 225.475L136.531 225.475Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.741 218.155L138.361 218.155L138.361 221.083L140.741 221.083'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.079 128.477L109.079 109.077L106.15 109.077L106.15 128.477L109.079 128.477Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.595 128.477L118.595 109.077L115.667 109.077L115.667 128.477L118.595 128.477Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.871 154.831L132.871 137.627L129.942 137.627L129.942 154.831L132.871 154.831Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.897 151.537L136.897 134.333L133.969 134.333L133.969 151.537L136.897 151.537Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.897 128.477L136.897 113.47L135.066 113.47L135.066 128.477L136.897 128.477Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.078 127.379L110.542 127.379'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.837 127.379L115.301 127.379'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.078 122.986L110.542 122.986'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.837 122.986L115.301 122.986'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M114.203 167.642L136.531 167.642'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.754 149.707L142.754 139.092L138.361 139.092L138.361 149.707L142.754 149.707Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.361 165.08L138.361 129.941'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.557 121.156L136.896 121.156L136.896 129.941L140.557 129.941'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.837 153.001L129.942 153.001'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.871 153.001L138.362 153.001'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.754 149.707L142.754 165.08'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.388 105.051L142.388 84.9189L138.728 84.9189L138.728 105.051L142.388 105.051Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.728 88.5791L142.388 88.5791'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.256 105.783L122.256 85.6509L118.596 85.6509L118.596 105.783L122.256 105.783Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.596 89.3115L122.256 89.3115'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.124 105.783L102.124 85.6509L98.4639 85.6509L98.4639 105.783L102.124 105.783Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.4639 89.3115L102.124 89.3115'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.9924 105.783L81.9924 85.6509L78.332 85.6509L78.332 105.783L81.9924 105.783Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.332 89.3115L81.9924 89.3115'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M64.7886 73.938L64.7886 53.0742L61.8604 53.0742L61.8604 73.938L64.7886 73.938Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M61.8604 63.3232L64.7886 63.3232'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M90.4107 73.938L90.4107 53.0742L87.4824 53.0742L87.4824 73.938L90.4107 73.938Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.4824 63.3228L90.4107 63.3228'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.079 73.938L109.079 53.0742L106.15 53.0742L106.15 73.938L109.079 73.938Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.15 63.3232L109.079 63.3232'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.577 73.938L129.577 53.0742L126.648 53.0742L126.648 73.938L129.577 73.938Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.648 63.3232L129.577 63.3232'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M66.9853 92.9716L66.9853 61.8589L64.7891 61.8589L64.7891 92.9716L66.9853 92.9716Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.151 119.443L64.7891 119.443L64.7891 92.9717'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M66.9844 92.9717L78.3314 92.9717'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.9922 92.9717L98.4636 92.9717'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M64.7891 100.658L77.9662 100.658'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.3584 100.658L98.4638 100.658'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.124 100.658L118.595 100.658'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.124 92.9717L118.595 92.9717'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.256 100.658L138.727 100.658'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.256 92.9717L138.361 92.9717'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.983 237.189L116.765 237.189'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.349 206.808L147.146 206.808'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M133.602 206.808L117.131 206.808'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.35 183.382L144.584 183.382'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.165 183.382L116.766 183.382'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.036 119.326L165.447 119.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.519 119.326L146.048 119.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.217 119.326L136.896 119.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.067 119.326L118.596 119.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.667 119.326L109.078 119.326'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M214.13 61.8589L193.632 61.8589'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M190.703 61.8589L174.964 61.8589'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.036 61.8589L154.467 61.8589'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.539 61.8589L129.577 61.8589'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.648 61.8589L109.078 61.8589'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.151 61.8589L90.4111 61.8589'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M87.4822 61.8594L66.9844 61.8594'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.021 183.382L139.093 183.382'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_120_2133'>
        <rect width='500' height='281.113' fill='white' transform='translate(281.112) rotate(90)' />
      </clipPath>
    </defs>
  </svg>
);
