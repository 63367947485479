export const ConeIconSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.67111 2.5367L6.23907 10.3477C6.18892 10.5147 6.16239 10.7041 6.16211 10.8971C6.16183 11.09 6.18781 11.2796 6.23747 11.4472C6.28712 11.6148 6.35872 11.7545 6.44515 11.8523C6.53157 11.9502 6.6298 12.0028 6.73007 12.0049H11.5941C11.6944 12.0028 11.7926 11.9502 11.8791 11.8523C11.9655 11.7545 12.0371 11.6148 12.0868 11.4472C12.1364 11.2796 12.1624 11.09 12.1621 10.8971C12.1618 10.7041 12.1353 10.5147 12.0852 10.3477L9.65311 2.5367C9.60192 2.37436 9.52985 2.24013 9.44385 2.14698C9.35784 2.05382 9.26081 2.00488 9.16211 2.00488C9.06341 2.00488 8.96638 2.05382 8.88037 2.14698C8.79437 2.24013 8.7223 2.37436 8.67111 2.5367Z'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <rect x='4.16211' y='12' width='10' height='2' rx='1' />
  </svg>
);
