import axios from 'axios';
import { preload } from 'swr';

import { getFromEnvConfig } from './Env';

export const axiosInstance = axios.create();

export const generateURLSearchParams = <Request>(queryParams: Request) => {
  const searchParams = new URLSearchParams(queryParams as unknown as Record<string, string>);

  const entries = [...searchParams.entries()];

  for (const [key, value] of entries) {
    if (!value || value === 'undefined' || value === 'ALL') {
      searchParams.delete(key);
    }
  }

  return searchParams.toString() ? `?${searchParams.toString()}` : '';
};

export const preloadResponse = <Response>(method: string, url: string) =>
  preload<Response>(`${getFromEnvConfig('FLEET_API_URL')}/api/v1${url}`, () =>
    axiosInstance<Response>(url, { method }).then(res => res.data)
  );

export type AxiosErrorResponseBody = {
  error: string;
  message: string;
};
