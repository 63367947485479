import * as Sentry from '@sentry/react';
import type { ReactNode } from 'react';
import React from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

export const ErrorFallback: React.FC = () => {
  return (
    <div role='alert' className='flex h-full items-center justify-center text-xl font-medium'>
      <h1>Something went wrong. We are looking into it. Please refresh the page and try again.</h1>
    </div>
  );
};

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  return <Sentry.ErrorBoundary fallback={<ErrorFallback />}>{children}</Sentry.ErrorBoundary>;
};

export default ErrorBoundary;
