export type EnvConfig = {
  FLEET_API_URL: string;
  ENV: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_AUDIENCE: string;
  GOOGLE_MAPS_API_KEY: string;
  INTERCOM_ID: string;
  SENTRY_ORG: string;
  SENTRY_PROJECT: string;
  SENTRY_RELEASE: string;
  SENTRY_DSN: string;
};

declare global {
  interface Window {
    env: EnvConfig;
  }
}

export const getFromEnvConfig = (key: keyof EnvConfig): string => {
  // Return empty string if we are in a server-side context, or if the env is not set
  if (typeof window === 'undefined' || !window.env) return '';

  if (!window.env[key]) {
    // eslint-disable-next-line no-console
    console.error(`Missing environment variable: ${key}`);
  }

  return window.env[key];
};
