export const LayerIconSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='44'
    height='45'
    viewBox='0 0 44 45'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect y='0.5' width='44' height='44' rx='22' fill='#F2F2F2' />
    <path
      d='M22 17.5L12 22.5L22 27.5L32 22.5L22 17.5Z'
      stroke='#828282'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
