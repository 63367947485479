import { useApi } from './useApiHooks';
import type { User } from './useGetOrganizations';
import type { AlertUserRole, OrganizationUserRole, ProjectUserRole } from '../../const';

export enum AuthType {
  PARTNER = 'PARTNER',
  USER = 'USER',
  LIMITED_ACCESS = 'LIMITED_ACCESS',
  INTERNAL_SERVICES = 'INTERNAL_SERVICES'
}

export interface AccessRoles {
  alerts?: {
    [key: string]: AlertUserRole;
  };
  projects?: {
    [key: string]: ProjectUserRole;
  };
  organizations: {
    [key: string]: OrganizationUserRole;
  };
}

// New context that supports roles without a user
export interface AuthContext {
  authType: AuthType;

  roles?: AccessRoles;
  globalAdmin?: boolean;

  userId?: string; // Convenience field
  user?: User;
}

export const useGetAuthContext = (disableQuery?: boolean) =>
  useApi<AuthContext>('get', disableQuery ? null : `/auth/context`);
