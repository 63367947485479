import type { SWRMutationConfig } from './api/useApiHooks';
import { useMutationApi } from './api/useApiHooks';

interface SageSafetyReportRequest {
  videoDescription: string;
  orgGrounding: string[];
}

export interface SageSafetyReportResponse {
  status: number;
  data: {
    response: string;
  };
}

export const useSageSafetyReport = (swrConfig?: SWRMutationConfig<SageSafetyReportResponse>) =>
  useMutationApi<SageSafetyReportRequest, SageSafetyReportResponse>(
    'post',
    '/sage/safety-report',
    undefined,
    swrConfig
  );
