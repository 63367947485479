export const snakeToSentenceCase = (str: string | undefined) => {
  if (!str) return;

  return str
    .replaceAll('_', ' ')
    .toLocaleLowerCase()
    .replaceAll(/^./g, g => g.toUpperCase());
};

export const kebabToSentenceCase = (str: string | undefined) => {
  if (!str) return;

  return str
    .replaceAll('-', ' ')
    .toLocaleLowerCase()
    .replaceAll(/^./g, g => g.toUpperCase());
};

export const copyToClipboard = (str: string) => {
  navigator.clipboard.writeText(str);
};

export const isEmpty = (str: string | undefined): boolean => !str || str.trim() === '';
