import { useApi } from './useApiHooks';
import { generateURLSearchParams } from '../../utils';

export interface DeviceMetrics {
  timezone: string;
  organizationId: string;
  projectId: string;
  deviceId: string;
  periodStartAt: string;
  runTimeSecs: number;
  disabledTimeSecs: number;
  enabledTimeSecs: number;
  idleTimeSecs: number;
  networkSecs: number;
  networkMb: number;
}

export interface GetDeviceMetricsQuery {
  organizationIds?: string;
  projectIds?: string;
  deviceIds?: string;
  startAt: string;
  reportingPeriodSecs: number;
  timezone?: string;
}

export interface GetDeviceMetricsResponse {
  results: DeviceMetrics[];
}

export const useGetDeviceMetrics = ({
  queryParams,
  disableQuery,
  variant,
  version = 1
}: {
  queryParams: GetDeviceMetricsQuery;
  disableQuery?: boolean;
  variant: 'weekly' | 'daily';
  version?: number;
}) => {
  const versionPath = version === 1 ? '' : `/v${version}`;
  return useApi<GetDeviceMetricsResponse>(
    'get',
    disableQuery
      ? null
      : `/devices/metrics/${variant}${versionPath}${generateURLSearchParams(queryParams)}`
  );
};
