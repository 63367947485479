import type { InputHTMLAttributes } from 'react';
import React, { useCallback } from 'react';
import type {
  Control,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  RegisterOptions
} from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { Input } from '../../atoms';

interface ControlledInputProps<TFieldValues extends FieldValues = FieldValues>
  extends InputHTMLAttributes<HTMLInputElement> {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  rules?: RegisterOptions<TFieldValues>;
  label: string;
}

export const ControlledInput = <TFieldValues extends FieldValues>({
  control,
  name,
  rules,
  ...rest
}: ControlledInputProps<TFieldValues>) => {
  const render = useCallback(
    ({
      field,
      fieldState
    }: {
      field: ControllerRenderProps<TFieldValues, Path<TFieldValues>>;
      fieldState: ControllerFieldState;
    }) => <Input {...field} error={fieldState.error?.message} {...rest} />,
    [rest]
  );
  return <Controller name={name} control={control} rules={rules} render={render} />;
};
