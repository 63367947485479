import { twMerge } from 'tailwind-merge';

export const GroundingIcon: React.FC<React.SVGProps<SVGSVGElement> & { theme?: string }> = ({
  theme,
  className,
  ...props
}) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={twMerge('rounded-full', className)}
      {...props}
    >
      <g clipPath='url(#clip0_684_1272)'>
        <g filter='url(#filter0_di_684_1272)'>
          <circle cx='6' cy='6' r='4' className='fill-brand-200' />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_di_684_1272'
          x='0'
          y='0'
          width='12'
          height='12'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='2'
            operator='dilate'
            in='SourceAlpha'
            result='effect1_dropShadow_684_1272'
          />
          <feOffset />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values={
              theme === 'JCB'
                ? '0 0 0 0 1 0 0 0 0 0.964706 0 0 0 0 0.901961 0 0 0 1 0'
                : '0 0 0 0 0.847059 0 0 0 0 0.901961 0 0 0 0 0.929412 0 0 0 1 0'
            }
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_684_1272' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_684_1272'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='1'
            operator='erode'
            in='SourceAlpha'
            result='effect2_innerShadow_684_1272'
          />
          <feOffset />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values={
              theme === 'JCB'
                ? '0 0 0 0 0.905882 0 0 0 0 0.701961 0 0 0 0 0.360784 0 0 0 1 0'
                : '0 0 0 0 0.588235 0 0 0 0 0.729412 0 0 0 0 0.796078 0 0 0 1 0'
            }
          />
          <feBlend mode='normal' in2='shape' result='effect2_innerShadow_684_1272' />
        </filter>
        <clipPath id='clip0_684_1272'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
