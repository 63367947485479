export const TruckIconSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='44'
    height='45'
    viewBox='0 0 44 45'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect y='0.5' width='44' height='44' rx='22' fill='#F2F2F2' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.435 13.8237C11.9655 13.8237 11.585 14.2043 11.585 14.6737V25.9781C11.585 26.4475 11.9655 26.8281 12.435 26.8281H13.6285C13.4335 27.2279 13.3241 27.6772 13.3241 28.152C13.3241 29.8221 14.6779 31.1759 16.348 31.1759C18.0181 31.1759 19.3719 29.8221 19.3719 28.152C19.3719 27.6772 19.2625 27.2279 19.0675 26.8281H24.9329C24.7379 27.2279 24.6284 27.6772 24.6284 28.152C24.6284 29.8221 25.9823 31.1759 27.6524 31.1759C29.3224 31.1759 30.6763 29.8221 30.6763 28.152C30.6763 27.6772 30.5668 27.2279 30.3718 26.8281H31.5654C32.0348 26.8281 32.4154 26.4475 32.4154 25.9781V21.6303C32.4154 21.4048 32.3258 21.1886 32.1664 21.0292L29.5577 18.4205C29.3983 18.2611 29.1821 18.1716 28.9567 18.1716H26.3284V14.6737C26.3284 14.2043 25.9479 13.8237 25.4784 13.8237H12.435ZM27.6524 25.1281H30.7154V21.9823L28.6046 19.8716H26.3284V25.1281H27.6524ZM16.348 25.1281L16.3456 25.1281H13.285V15.5237H24.6284V19.0216V25.1281H16.3504L16.348 25.1281ZM15.0241 28.152C15.0241 27.4208 15.6168 26.8281 16.348 26.8281C17.0792 26.8281 17.6719 27.4208 17.6719 28.152C17.6719 28.8832 17.0792 29.4759 16.348 29.4759C15.6168 29.4759 15.0241 28.8832 15.0241 28.152ZM26.3284 28.152C26.3284 27.4208 26.9212 26.8281 27.6524 26.8281C28.3835 26.8281 28.9763 27.4208 28.9763 28.152C28.9763 28.8832 28.3835 29.4759 27.6524 29.4759C26.9212 29.4759 26.3284 28.8832 26.3284 28.152Z'
      fill='#828282'
    />
  </svg>
);
