const style: React.CSSProperties = { maskType: 'luminance' };

export const GraderTopSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='282'
    height='500'
    viewBox='0 0 282 500'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_119_986)'>
      <path
        d='M129.14 271.042L131.299 267.567L140.774 267.567'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M151.972 271.042L149.813 267.567L140.337 267.567'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M161.549 372.965L152.107 379.061'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M163.881 377.363L154.375 382.826L154.02 382.865'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M173.256 355.434L140.188 355.434'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.553 345.603L172.342 345.603L172.342 364.81L140.007 364.81'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.556 476.956L179.118 476.956L179.118 379.965'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M179.117 344.674L179.117 271.051L140.555 271.051'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M164.058 468.806L140.555 468.806'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M179.211 343.386L138.94 343.386'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M179.212 303.141L139.974 303.141'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M179.211 275.795L140.555 275.795'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M116.872 476.956L116.872 372.526'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.555 476.956L101.993 476.956L101.993 379.965'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M117.053 468.806L140.556 468.806'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.533 460.007L142.707 460.007L142.707 457.249L141.913 457.249'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M142.707 459.245L144.582 459.245L144.582 448.319L141.836 448.319'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M138.404 459.245L136.529 459.245L136.529 448.319L139.275 448.319'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M139.198 458.231L139.198 455.058L140.635 455.058'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M141.913 458.231L141.913 455.058L140.477 455.058'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M139.198 458.231L140.635 458.231'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.578 460.007L138.404 460.007L138.404 457.249L139.198 457.249'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M141.913 458.231L140.477 458.231'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.627 449.769L139.274 449.769L139.274 445.664L140.627 445.664'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.483 449.769L141.836 449.769L141.836 445.664L140.483 445.664'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M118.88 364.365L118.88 345.692'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M140.558 345.603L108.769 345.603L108.769 362.805'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M96.1317 365.088L94.6533 362.744L96.5907 351.991'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M107.298 352.348L107.961 360.4'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M121.612 364.81L121.612 345.693'
        stroke='black'
        strokeWidth='0.549048'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask
        id='mask0_119_986'
        style={style}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='282'
        height='500'
      >
        <path
          d='M281.113 1.22878e-05L281.113 500L-2.18557e-05 500L0 0L281.113 1.22878e-05Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_119_986)'>
        <path
          d='M101.993 369.086C105.5 369.086 108.343 366.243 108.343 362.736C108.343 359.229 105.5 356.386 101.993 356.386C101.443 356.386 100.896 356.457 100.364 356.598C96.9743 357.498 94.956 360.975 95.8557 364.365'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.6877 431.926L78.3564 431.926L78.3564 291.641L79.6178 291.641'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.4541 401.562L101.514 401.563'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.4541 408.425L101.514 408.425'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.4541 415.287L101.514 415.287'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.4541 422.149L101.514 422.149'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.879 431.926L79.6875 431.926'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M95.8564 364.364C96.3806 366.339 97.8235 367.941 99.7331 368.669'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.707 411.151L112.093 411.151L111.394 410.452L111.254 409.334L111.254 399.133'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.029 424.916L116.872 424.916'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.71 431.926L99.3174 431.926'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.281 364.809L141.104 364.809'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.299 371.764L151.911 375.373L153.662 375.023L154.712 373.623L154.887 371.699L153.662 369.774L150.511 369.249L124.228 365.191'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.954 381.84C106.731 381.84 105.74 380.848 105.74 379.626C105.74 378.411 106.719 377.423 107.933 377.412'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.063 385.598L103.063 373.505'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.993 379.966L101.993 369.086'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.707 387.115L112.093 387.115L111.394 387.814L111.254 388.932L111.254 399.134'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.164 381.801L115.164 385.598L103.063 385.598L115.211 385.598'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.872 381.801L107.953 381.801'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.063 373.505L115.164 373.505L115.164 377.451'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.872 377.451L107.953 377.451'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.037 369.081L115.617 370.794'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.575 364.01L108.468 362.758'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.7334 368.67C100.455 368.945 101.221 369.086 101.994 369.086'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.677 362.619L117.294 372.409L117.269 372.582L115.409 372.265L116.818 362.302L118.677 362.619Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.618 363.104L121.21 373.067L123.069 373.384L123.094 373.21L124.478 363.42L122.618 363.104Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.487 371.03L121.427 371.527'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.364 364.904L118.439 364.298'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.752 364.809L122.378 364.809'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.728 376.364L147.309 380.812L140.377 380.812'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.358 372.642L133.802 380.813L140.734 380.813'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.088 381.271L156.487 384.837L154.911 385.974L152.984 385.974L150.62 385.012L149.597 375.081'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.422 383.008C152.209 383.008 151.226 382.025 151.226 380.812C151.226 380.004 151.668 379.263 152.377 378.879'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.263 382.841C153.997 382.951 153.711 383.008 153.422 383.008'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.423 378.577L148.504 378.577'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.971 369.011L148.78 367.162L136.899 367.147'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.687 378.577L132.606 378.577'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M78.5195 294.427L101.513 294.427'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.0874 283.684C98.0874 279.04 94.3224 275.275 89.6782 275.275C89.4948 275.275 89.3114 275.281 89.1284 275.293C84.4937 275.597 80.9831 279.6 81.2869 284.234'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M96.7246 352.415C97.5778 354.579 99.6671 356 101.993 356'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.7111 345.153C96.9275 346.379 95.6094 349.585 96.7254 352.414'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.6182 291.641L101.514 291.641'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.7842 284.772L81.2421 283.821'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M79.7842 291.603L79.7842 284.771'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M81.2412 291.638L81.2412 283.412'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.47 319.877C138.148 319.877 136.266 317.995 136.266 315.673C136.266 313.351 138.148 311.468 140.47 311.468C142.792 311.468 144.675 313.351 144.675 315.673C144.675 317.995 142.792 319.877 140.47 319.877Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.993 344.674L101.993 271.051L140.555 271.051'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.899 343.386L142.17 343.386'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.736 355.433L140.923 355.433'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.994 356.001C105.121 356.001 107.657 353.465 107.657 350.337C107.657 347.209 105.121 344.674 101.994 344.674C101.208 344.674 100.43 344.837 99.7109 345.154'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.872 345.602L116.872 343.386'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.246 275.795L140.556 275.795'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M101.899 303.141L141.138 303.141'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.3574 291.641L99.3574 284.772'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.0459 291.345L98.0459 283.48'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.6416 291.641L100.692 291.641'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M99.357 284.772L98.0459 283.888'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.238 476.956L164.238 377.363'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.423 431.926L202.754 431.926L202.754 291.641L201.493 291.641'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.118 369.086C175.612 369.086 172.769 366.243 172.769 362.736C172.769 359.229 175.612 356.386 179.118 356.386C182.625 356.386 185.468 359.229 185.468 362.736C185.468 363.286 185.397 363.833 185.256 364.365'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.498 364.809L159.498 345.692'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.231 364.809L162.231 345.692'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M173.814 352.348L173.151 360.4'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.978 365.088L186.457 362.744L184.52 351.991'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.403 411.151L169.018 411.151L169.717 410.452L169.857 409.334L169.857 399.133'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.539 401.563L179.479 401.562'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.112 431.926L201.423 431.926'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.538 422.149L179.112 422.149'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.539 415.287L179.479 415.287'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.539 408.425L179.479 408.425'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.082 424.916L164.239 424.916'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.117 379.966L179.117 369.086'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.403 387.115L169.018 387.115L169.717 387.814L169.857 388.932L169.857 399.134'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.687 372.702L177.509 372.2'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.418 372.05L172.594 364.186L172.523 364.228'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M171.06 365.176L175.911 373.136L176.694 372.659'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M172.862 363.749L172.721 364.343'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.238 370.775L164.238 367.48'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
        <path
          d='M164.28 367.161L159.464 367.161L158.112 375.184'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.135 371.942L166.472 369.787L168.056 368.764L170.674 367.074L171.665 366.232'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.165 378.557L167.074 378.408L161.929 369.965L160.291 370.9L165.527 379.493L167.165 378.557Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.777 376.277L169.221 374.298L170.857 373.358L174.672 371.166'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M185.255 364.364C184.73 366.339 183.288 367.941 181.378 368.669'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.378 368.67C180.656 368.945 179.89 369.086 179.117 369.086'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.292 369.087L176.307 370.227'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.238 345.602L164.238 343.386'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.4 345.153C184.184 346.379 185.502 349.585 184.386 352.414'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.117 356.001C175.99 356.001 173.454 353.465 173.454 350.337C173.454 347.209 175.99 344.674 179.117 344.674C179.903 344.674 180.681 344.837 181.4 345.154'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M184.385 352.415C183.532 354.579 181.443 356 179.117 356'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.428 291.641L179.112 291.641'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.113 284.901C182.774 279.998 185.622 276.035 190.217 275.363C190.62 275.304 191.027 275.274 191.434 275.274C196.079 275.274 199.844 279.039 199.844 283.684C199.844 283.867 199.837 284.051 199.825 284.234'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M202.591 294.426L179.112 294.426'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M183.064 291.345L183.064 284.024'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.754 291.641L181.754 284.772'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.469 291.641L180.419 291.641'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M181.754 284.772L183.065 283.888'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M199.869 291.638L199.869 283.412'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.328 291.603L201.328 284.771'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M201.328 284.772L199.87 283.821'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.354 179.06C157.926 181.482 159.765 185.758 159.065 190.017'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.298 196.154L153.298 196.017'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.257 196.146L153.427 196.146'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.333 172.684L158.756 172.684L158.756 173.985L154.268 173.985'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.888 163.405L164.888 168.108L154.223 179.073'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.373 208.115L178.449 208.115'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.455 209.544L151.135 209.544'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.314 214.415L150.321 214.415'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.516 217.873L149.745 217.873'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.516 219.139L149.534 219.139'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.588 226.627L155.588 227.299L163.778 227.299L163.778 222.426L155.543 222.426L155.543 223.219'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.15 128.827L159.202 128.626L159.639 128.287L159.784 127.559L159.59 126.541L159.105 125.425L158.425 124.406L157.649 123.582L156.92 123.097L156.289 122.66L155.853 122.417'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M157.15 133.219L159.978 133.041L161.289 132.507L161.92 131.779L162.454 130.567L162.551 129.16L162.503 127.85L162.211 126.007L161.483 124.018L160.658 122.611L159.299 121.447L158.182 120.865L156.726 120.186L155.853 119.847'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M155.885 119.425L160.813 119.425L160.813 115.413'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.885 113.589L160.813 113.589L160.813 117.601'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.503 169.669C156.462 169.669 155.437 169.409 154.521 168.914'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.502 168.296C156.458 168.296 155.44 167.961 154.6 167.341'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.085 157.626C155.85 157.306 156.672 157.141 157.502 157.141C158.749 157.141 159.968 157.514 161.003 158.211'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.007 159.198C155.762 158.75 156.624 158.514 157.502 158.514C159.764 158.514 161.73 160.064 162.258 162.263'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.888 163.405L164.888 158.545L156.384 149.801'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.226 164.672C161.653 166.81 159.715 168.296 157.502 168.296'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.644 165.952C156.158 165.952 154.954 164.748 154.954 163.262C154.954 161.777 156.158 160.573 157.644 160.573C159.129 160.573 160.333 161.777 160.333 163.262C160.333 164.748 159.129 165.952 157.644 165.952Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.609 88.5503L150.306 88.5503'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.445 49.8972L159.006 54.2702L165.745 54.2753L165.754 42.0305L140.679 25.6699'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.601 78.9155L150.562 78.9155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.393 210.959L140.556 210.959'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.393 213.067L140.556 213.067'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.6387 83.7324L82.6387 118.293'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.0967 83.7324L84.0967 120.774'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.213 83.7324L102.213 118.293'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.901 83.7324L100.901 120.408'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.304 193.243L155.333 152.674'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.652 189.706L132.652 121.096'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.964 210.959L143.964 180.272'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.918 210.959L142.918 180.273'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.994 210.959L145.994 180.273'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.948 210.959L144.948 180.273'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.723 210.959L135.723 180.272'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.799 210.959L138.799 180.272'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.459 131.561L148.459 95.7334'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.459 199.838L148.459 132.391'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.205 195.976L152.491 150.28'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.817 178.961L111.428 179.499L112.343 180.685L111.982 181.276L110.499 181.058L110.154 181.627L111.109 182.711L110.732 183.324L109.318 183.056L108.941 183.669L110.012 184.639L109.715 185.277L108.381 185.035'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.652 208.115L90.542 208.115'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.3477 214.415L132.652 214.415'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M90.9443 219.139L132.651 219.139'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.2191 222.948L91.3867 222.948'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M98.5858 227.047L91.4668 227.047'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M94.7168 227.049L94.7168 222.96'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.4568 227.166C90.272 227.166 89.3115 226.206 89.3115 225.021C89.3115 223.836 90.272 222.876 91.4568 222.876C91.4656 222.876 91.474 222.876 91.4828 222.876'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M70.5329 208.114L58.6885 208.114L58.6885 214.414L71.8656 214.414'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M62.1553 214.414L62.1553 219.138L76.391 219.138'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M75.1592 219.139L92.9952 219.139'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M91.9972 208.115L70.4844 208.115'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M70.4844 214.414L93.0953 214.414'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M76.1182 219.097L76.1182 214.479'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M70.4844 214.366L70.4844 208.256'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.964 234.553L143.964 223.427'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.948 228.653L144.948 223.428'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.918 235.708L142.918 223.428'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.723 235.708L135.723 223.428'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.799 235.708L138.799 223.428'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.652 226.981L132.652 225.666'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.94 227.29L157.94 222.531'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.687 227.29L155.687 222.531'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.881 227.077L127.232 226.699L127.503 226.159L127.557 225.537L127.557 225.105L127.557 224.943'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.7 227.893L107.572 227.893L107.572 222.903L107.468 222.175L107.294 221.171L106.638 219.237'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M111.7 227.893L111.7 221.22L110.174 219.237L104.173 219.237L103.826 219.389L103.548 219.771L101.514 222.897'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.456 267.566L147.548 213.067'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141.455 267.566L150.547 213.067'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.46 235.975L148.46 225.581'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.868 228.754C127.608 228.575 126.852 227.577 126.751 227.279'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.37 227.046L98.2637 227.046'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.827 227.047L111.763 227.047'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.812 267.566L149.812 235.926L146.733 235.926'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.299 267.566L131.299 235.926L141.014 235.926'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.735 235.927L140.097 235.927'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.652 235.67L132.652 226.895'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.162 226.191C130.796 227.656 130.077 228.434 129.751 228.68C129.751 228.68 128.966 229.486 127.868 228.754'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.543 227.29L160.304 228.215L159.123 227.295L158.53 227.654L158.741 229.137L158.17 229.48L157.091 228.52L156.476 228.894L156.737 230.309L156.123 230.683L155.158 229.608L154.519 229.902L154.755 231.237'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.805 231.183L154.205 231.468L153.205 230.354L152.558 230.603L152.505 232.1L151.882 232.337L150.989 231.201L150.318 231.461L150.326 232.9L149.655 233.16L148.894 231.932L148.49 232.036'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.308 226.607L155.321 226.607'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.662 223.013C126.77 222.609 126.931 222.222 127.14 221.86C127.356 221.484 127.651 221.159 128.004 220.906C128.249 220.73 128.544 220.635 128.847 220.635C129.149 220.635 129.444 220.73 129.69 220.906C130.043 221.159 130.337 221.484 130.554 221.86C130.784 222.259 130.956 222.689 131.063 223.137C131.195 223.687 131.261 224.251 131.261 224.816C131.261 225.268 131.218 225.719 131.134 226.163'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.557 225.105L127.53 224.699L127.476 224.348L127.422 223.97L127.26 223.673L127.071 223.349L126.665 222.917'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M70.7666 213.067L140.555 213.067'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M70.7666 210.959L140.555 210.959'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.856 204.962L120.428 192.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.342 204.962L118.77 192.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.887 200.5L117.635 205.022L119.708 205.022'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
        <path
          d='M106.141 205.868L105.989 205.221L107.288 204.477L107.181 203.793L105.729 203.426L105.628 202.767L106.926 202.133L106.813 201.422L105.405 201.127L105.292 200.416L106.653 199.932L106.624 199.228L105.299 198.94'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.37 222.948L97.8926 222.948'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.807 207.939L106.699 207.94L106.435 207.27L107.66 206.504L107.481 205.824L106.125 205.827'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.746 222.948L111.763 222.948'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.985 222.93L116.495 221.919L116.029 221.406L114.614 221.901L114.167 221.407L114.901 220.162L114.415 219.631L113.077 220.161L112.591 219.631L112.929 219.181'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.14 191.528L105.988 192.175L107.287 192.919L107.18 193.603L105.728 193.97L105.627 194.629L106.925 195.263L106.812 195.973L105.404 196.269L105.291 196.98L106.652 197.464L106.623 198.167L105.298 198.455'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.435 184.984L108.147 185.583L109.257 186.588L109.005 187.234L107.508 187.28L107.268 187.902L108.399 188.8L108.136 189.47L106.697 189.456L106.434 190.126L107.659 190.892L107.479 191.572L106.124 191.569'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.501 196.543L124.419 194.773L124.911 192.674L124.862 190.875C124.357 185.598 127.584 180.679 132.625 179.039'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.473 194.474C120.651 194.46 120.828 194.442 121.004 194.42C121.349 194.376 121.689 194.303 122.021 194.202C122.325 194.109 122.61 193.966 122.866 193.778C122.966 193.704 123.025 193.587 123.025 193.462C123.025 193.338 122.966 193.221 122.866 193.147C122.61 192.959 122.325 192.815 122.021 192.723C121.689 192.621 121.349 192.549 121.004 192.505C120.538 192.446 120.068 192.417 119.598 192.417C119.128 192.417 118.659 192.446 118.192 192.505C117.848 192.549 117.508 192.621 117.176 192.723C116.872 192.815 116.587 192.959 116.331 193.147C116.231 193.221 116.172 193.338 116.172 193.462C116.172 193.587 116.231 193.704 116.331 193.778C116.587 193.966 116.872 194.109 117.176 194.202C117.508 194.303 117.848 194.376 118.192 194.42C118.39 194.445 118.589 194.465 118.788 194.479'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M118.66 196.414C118.115 196.636 117.666 197.044 117.393 197.566C116.756 198.784 117.228 200.288 118.446 200.924'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.741 200.93C121.25 200.667 121.653 200.237 121.881 199.711C122.429 198.451 121.851 196.985 120.591 196.437'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.652 210.959L132.652 193.066'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.578 206.349L153.654 200.206L153.586 200.035'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.83 223.428L141.39 223.428L141.39 221.372L146.207 221.372'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.652 225.666L132.652 213.38'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.723 221.371L135.723 213.067'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.799 221.371L138.799 213.067'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.189 223.428L135.189 221.372L139.582 221.372L139.582 223.428L135.189 223.428Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.176 222.885L132.608 222.885'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.176 226.912L132.608 226.912'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.918 221.371L142.918 213.067'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.964 221.371L143.964 213.067'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.948 221.371L144.948 213.067'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.994 221.371L145.994 213.067'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.875 223.251L155.32 223.251'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.889 205.567L150.233 205.959'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.899 210.959L148.774 205.715'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.898 210.959L151.692 206.202'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.578 206.349L150.233 205.958'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.652 193.066L132.652 191.765'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.652 191.169L132.652 189.706'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.652 191.765L132.652 191.169'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.211 195.099L153.304 193.244'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M147.828 210.819L147.95 202.788L148.766 198.279L150.11 196.024'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.869 207.891L154.954 200.749L154.524 196.894'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.584 200.035L152.428 197.664L150.771 197.401L148.731 199.471'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
        <path
          d='M153.181 195.704L153.211 195.099'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.562 196.212L153.159 196.124L153.18 195.704'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M154.505 196.85L154.481 196.801L153.562 196.212'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.854 118.165C101.526 122.76 98.3458 127.03 93.7503 127.702C93.3473 127.761 92.9406 127.791 92.5336 127.791C87.889 127.791 84.124 124.026 84.124 119.381C84.124 119.198 84.1302 119.014 84.1423 118.831'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.6387 118.294L84.2752 121.104'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.918 139.762L142.918 133.127'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.964 139.762L143.964 133.127'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.948 139.762L144.948 133.127'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.994 139.762L145.994 133.127'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.723 178.217L135.723 133.127'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.799 178.217L138.799 133.127'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.086 139.185L152.086 137.819L156.728 137.819L156.728 139.185L152.086 139.185Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.886 166.096L127.264 166.332L127.376 167.825L126.749 168.12L125.632 167.122L125.029 167.408L125.231 168.838L124.579 169.142L123.546 168.141L122.894 168.445L123.254 169.844L122.655 170.214L121.675 169.278'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.723 169.254L121.157 169.603L121.549 171.048L120.989 171.456L119.703 170.687L119.166 171.082L119.634 172.448L119.051 172.87L117.847 172.082L117.265 172.504L117.882 173.81L117.364 174.286L116.225 173.552'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M116.303 173.685L115.82 174.14L116.494 175.477L116.027 175.99L114.613 175.495L114.166 175.989L114.9 177.234L114.414 177.764L113.076 177.235L112.59 177.765L113.458 178.92L113.046 179.49L111.782 179.001'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.918 178.217L142.918 141.818'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.964 178.217L143.964 141.818'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.948 178.217L144.948 141.818'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.994 178.217L145.994 141.818'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141.286 180.273L141.286 178.217L148.106 178.217L148.106 180.273L141.286 180.273Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.823 180.273L134.823 178.217L139.582 178.217L139.582 180.273L134.823 180.273Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.119 164.711L132.088 164.671L131.445 164.846L131.391 166.29L130.695 166.474L129.855 165.307L129.159 165.491L129.267 166.932L128.613 167.19L127.812 166.095'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.461 172.683L151.371 172.683'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.305 173.985L149.461 173.985L149.461 172.776'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.731 181.044L150.854 181.044'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.505 168.5L148.731 168.5'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.505 170.174L148.731 170.174'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.64 165.612C151.046 164.035 151.113 162.286 151.825 160.759'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141 141.818L141 139.762L147.82 139.762L147.82 141.818L141 141.818Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.605 150.033L151.896 143.803L151.999 143.65'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.353 150.292L156.743 144.068L156.657 143.905'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.222 155.674L148.732 155.674'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.146 157.347L148.732 157.347'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.193 155.059L155.457 150.243'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.213 155.39L152.503 150.082'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.353 150.292L153.979 150.162'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.605 150.032L153.979 150.162'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.731 145.766L151.804 145.766'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.885 142.737L155.885 139.185'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.927 142.407L152.927 139.217'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.999 143.65L153.645 141.588L154.445 141.632'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.657 143.905L155.246 141.676L154.445 141.632'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.231 118.294L100.999 120.409'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.963 133.127L143.963 112.258L141.162 101.433L141.162 95.7334'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.918 133.127L142.918 113.848L139.92 101.658L139.92 95.7334'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.799 125.065L138.661 122.837L138.243 121.097L137.686 119.01L136.781 117.479L135.249 116.227L133.439 115.67L132.992 115.65'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.927 128.031L152.927 104.347'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.885 128.031L155.885 104.346'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.723 131.071L135.723 125.065'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.799 131.071L138.799 125.065'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M134.823 133.127L134.823 131.071L139.582 131.071L139.582 133.127L134.823 133.127Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M135.723 125.065L135.389 122.906L135.041 121.653L134.483 120.193L133.578 119.288L132.812 118.94'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.948 133.127L145.246 132.074L145.834 131.131L146.453 130.631L147.101 130.542L150.456 130.542'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M145.994 133.127L146.512 131.839L147.512 131.368L148.459 131.349L150.574 131.309'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M156.089 133.521L151.811 133.521L151.811 132.122'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.927 133.521L157.205 133.521L157.205 132.121'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.927 137.612L152.927 133.585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.885 137.612L155.885 133.586'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.66 132.194L150.55 132.194L150.55 129.338L151.57 129.338'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.328 132.004L152.328 129.758'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M156.587 132.004L156.587 129.925'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.811 129.559L151.811 128.031L156.089 128.031'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.205 129.559L157.205 128.031L152.927 128.031'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.157 129.691L151.792 129.691'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.157 132.004L151.759 132.004'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M152.843 120.914L151.77 120.478L150.787 120.26L149.447 120.097L148.731 120.042'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.731 119.425L152.926 119.425'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.652 121.14L132.652 95.7334'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.731 113.589L152.926 113.589'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M155.885 104.347C155.698 104.284 155.507 104.236 155.313 104.204C155.198 104.185 155.08 104.182 154.964 104.195C154.783 104.215 154.603 104.25 154.427 104.298C154.243 104.35 154.055 104.386 153.865 104.409C153.743 104.423 153.619 104.426 153.496 104.418C153.305 104.406 153.115 104.382 152.927 104.347'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.855 49.3006C100.914 48.8976 100.944 48.4909 100.944 48.0839C100.944 43.4393 97.1788 39.6743 92.5346 39.6743C92.3509 39.6743 92.1675 39.6805 91.9845 39.6926C87.3501 39.9961 83.8395 43.9994 84.1433 48.6337'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M84.0967 83.7331L84.0967 47.8125'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.6387 83.7326L82.6387 49.1719'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M82.6387 49.172L84.2752 46.3623'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.445 95.8841L148.445 44.6396'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.666 87.8163L132.666 44.6392'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.47 52.1129L139.47 43.5415L140.675 43.5415'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141.642 52.0876L141.642 43.5415L140.437 43.5415'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M100.901 83.7324L100.901 47.8799'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.213 83.7326L102.213 49.1719'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.424 74.8939L130.806 74.8939L130.806 44.6318L132.627 44.6399'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.767 61.6307L127.767 41.0703L129.243 41.0703L129.243 61.6307L127.767 61.6307Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.69 49.886L122.112 54.2663L115.355 54.2663L115.355 41.9903L140.506 25.606'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.345 61.6307L153.345 41.0703L151.868 41.0703L151.868 61.6307L153.345 61.6307Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.688 74.8934L150.305 74.8934L150.305 44.6313L148.731 44.6394'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
        <path
          d='M130.566 79.0466L124.208 76.2827'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.939 78.9155L130.264 78.9155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.645 70.3594L132.626 97.4037'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M117.113 71.5986L132.626 99.6023'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.805 74.6095L125.519 72.3115'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.766 60.0566L126.585 60.0566L126.585 55.9248L127.766 55.9248'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.858 74.7461L108.103 70.0708'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.375 77.1111L103.375 62.3937L102.25 61.9761'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.967 71.272L123.845 73.8547'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.963 71.2811L125.37 70.2998L126.22 70.6995L124.688 74.2215'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.437 88.5498L115.043 88.5498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.51 78.9155L115.044 78.9155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M106.437 88.5574L106.437 78.9429'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.345 87.471L103.774 87.0984L104.52 86.5391L104.831 85.7939L105.018 84.6756L105.018 83.6196'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.216 90.3893L104.086 89.8926L104.459 88.961L104.645 88.0298L105.204 87.6572L105.743 87.6572'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.283 90.5755L103.215 90.3892'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.743 87.6572L106.272 87.6572'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M103.216 77.0762L104.086 77.5729L104.459 78.5044L104.645 79.4356L105.204 79.8082L105.743 79.8082'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.345 79.9946L103.774 80.3672L104.52 80.9265L104.831 81.6718L105.018 82.79L105.018 83.846'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.283 76.8901L103.215 77.0764'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M105.743 79.8081L106.272 79.8081'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.151 79.0269L118.151 88.5411'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.152 79.0269L118.152 76.9248L115.044 76.9248L115.044 84.0236'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.152 88.4386L118.152 90.5407L115.044 90.5407L115.044 83.4419'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.151 88.4386L118.151 78.9243'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.151 88.5498L126.504 88.5498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M118.151 78.9155L121.167 78.9155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.385 78.9155L125.076 78.9155'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.722 76.4367L123.03 75.7266L123.655 74.2892L123.844 73.854'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.727 76.4276L122.287 77.3951L123.159 77.7435L123.63 76.6593L124.255 75.2219L124.69 74.2212'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M122.726 76.428L120.461 75.4434'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.298 63.6733L107.37 70.6635'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M124.964 71.281L109.989 64.7715'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.586 63.7021L103.353 62.3804'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.586 67.269L103.386 67.9096'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M109.566 65.8696L111.184 67.3524L110.82 68.1899'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.274 68.4383L104.586 67.2695'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M107.117 70.866L106.366 70.5296L107.928 66.9355'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.469 68.7979L110.445 69.0406L110.815 68.1899'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M108.582 65.4326L104.576 63.6914'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M104.586 67.3039L104.586 63.7021'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M110.249 63.6607L109.49 63.3408L107.928 66.9349'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.959 68.8315L117.645 70.3597'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M113.594 70.1382L117.115 71.5979'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.52 68.9994L112.597 68.8193'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.569 68.8849L113.013 67.6924L114.117 68.2202L113.603 69.4025'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.52 68.9995L111.95 70.1375L113.089 70.5844L113.603 69.4021'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.605 68.7979L142.605 72.9194'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.346 68.9033L138.346 72.8239'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.581 92.956C139.141 92.8583 137.948 92.426 137.551 92.2134C137.179 92.0142 136.853 91.739 136.595 91.4052C136.426 91.1863 136.334 90.9172 136.334 90.6405C136.334 90.3638 136.426 90.0948 136.595 89.8759C136.853 89.5421 137.179 89.2668 137.551 89.0677C137.948 88.855 138.373 88.6976 138.813 88.5999C139.363 88.478 139.925 88.4165 140.488 88.4165C141.051 88.4165 141.613 88.478 142.162 88.5999C142.603 88.6976 143.028 88.855 143.425 89.0677C143.797 89.2668 144.122 89.5421 144.38 89.8759C144.55 90.0948 144.642 90.3638 144.642 90.6405C144.642 90.9172 144.55 91.1863 144.38 91.4052C144.122 91.739 143.797 92.0142 143.425 92.2134C143.028 92.426 141.852 92.8583 141.412 92.956'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M143.293 89.9932L142.15 90.1835L141.768 90.8504L141.387 92.8508L141.387 95.5181'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.42 81.1358C138.42 81.0004 138.465 80.8686 138.548 80.7617C138.675 80.5981 138.835 80.4634 139.018 80.3661C139.213 80.2617 139.422 80.1845 139.638 80.1369C139.909 80.0773 140.185 80.0469 140.462 80.0469C140.738 80.0469 141.014 80.0773 141.285 80.1369C141.501 80.1845 141.71 80.2617 141.905 80.3661C142.088 80.4634 142.248 80.5981 142.375 80.7617C142.458 80.8686 142.503 81.0004 142.503 81.1358'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.406 81.1807L138.406 84.5372'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.796 88.9294L130.796 74.8936L132.659 74.8936'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.666 90.9555L132.666 87.8164'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.626 91.4917L130.796 91.4917L130.796 88.5635'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.666 95.8842L132.666 90.9556'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.722 88.5498L130.805 88.5498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M137.818 89.9932L138.962 90.1839L139.344 90.8504L139.725 92.8508L139.725 95.5181'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.334 86.7852C136.334 86.5085 136.426 86.2394 136.595 86.0205C136.853 85.6867 137.179 85.4115 137.551 85.2123C137.948 84.9997 138.373 84.8423 138.813 84.7445C139.197 84.6593 139.588 84.6036 139.981 84.5776L141.131 84.5879C141.478 84.6168 141.823 84.6691 142.162 84.7445C142.603 84.8423 143.028 84.9997 143.425 85.2123C143.797 85.4115 144.122 85.6867 144.38 86.0205C144.55 86.2394 144.642 86.5085 144.642 86.7852'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M136.288 90.7595L136.288 86.6899'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.334 78.3361C136.334 78.0594 136.426 77.7904 136.595 77.5715C136.853 77.2376 137.179 76.9624 137.551 76.7633C137.948 76.5506 138.373 76.3932 138.813 76.2955C138.994 76.2552 139.176 76.2219 139.359 76.1948L141.723 76.2113C141.87 76.2351 142.017 76.2633 142.162 76.2955C142.603 76.3932 143.028 76.5506 143.425 76.7633C143.797 76.9624 144.122 77.2376 144.38 77.5715C144.55 77.7904 144.642 78.0594 144.642 78.3361'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.301 78.3799L138.377 81.2426'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.515 81.3052L142.515 84.5372'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.687 74.8936L148.451 74.8936'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.731 91.3217L150.305 91.3217L150.305 75.0205'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.182 86.875L148.452 86.875'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.667 86.7622L144.667 90.394'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.682 78.3799L142.605 81.2426'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.715 78.3459L144.715 74.8415L144.38 74.1614C144.122 73.828 143.796 73.5527 143.425 73.3536C143.027 73.1405 142.602 72.9831 142.162 72.8854C141.826 72.8107 141.484 72.7588 141.141 72.7299L139.948 72.7207C139.566 72.7474 139.186 72.8027 138.813 72.8854C138.372 72.9831 137.948 73.1405 137.55 73.3536C137.178 73.5527 136.853 73.828 136.595 74.1614L136.311 74.8075L136.311 78.3459'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.3 61.124C136.558 60.7906 137.179 60.2199 137.55 60.0208C137.948 59.8081 138.373 59.6507 138.813 59.553C139.363 59.4311 139.924 59.3696 140.488 59.3696C141.051 59.3696 141.612 59.4311 142.162 59.553C142.603 59.6507 143.027 59.8081 143.425 60.0208C143.797 60.2199 144.447 60.7774 144.705 61.1108'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.42 68.7672C138.42 68.6317 138.465 68.5 138.548 68.3931C138.675 68.2295 138.835 68.0948 139.018 67.9974C139.213 67.8931 139.422 67.8159 139.638 67.7683C139.909 67.7086 140.185 67.6782 140.462 67.6782C140.738 67.6782 141.014 67.7086 141.285 67.7683C141.501 67.8159 141.71 67.8931 141.905 67.9974C142.088 68.0948 142.248 68.2295 142.375 68.3931C142.458 68.5 142.503 68.6317 142.503 68.7672'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.334 65.9672C136.334 65.6905 136.426 65.4214 136.595 65.2025C136.853 64.8687 137.179 64.5935 137.551 64.3943C137.948 64.1817 138.373 64.0243 138.813 63.9265C139.363 63.8047 139.925 63.7432 140.488 63.7432C141.051 63.7432 141.613 63.8047 142.162 63.9265C142.603 64.0243 143.028 64.1817 143.425 64.3943C143.797 64.5935 144.122 64.8687 144.38 65.2025C144.55 65.4214 144.642 65.6905 144.642 65.9672'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.301 66.0112L138.322 68.7978'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M136.311 65.9774L136.311 61.1113'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.719 60.0566L129.242 60.0566'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M129.242 55.9253L130.796 55.9253'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.47 59.426L139.47 51.7891'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141.642 59.426L141.642 51.7891'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.681 66.0112L142.66 68.7978'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.716 65.9775L144.716 63.5605'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M144.716 65.0136L144.716 61.1113'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.345 60.0566L154.526 60.0566L154.526 55.9248L153.345 55.9248'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.392 60.0566L151.868 60.0566'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M151.869 55.9248L150.562 55.9248'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M127.766 48.35L126.585 48.35L126.585 44.2183L127.766 44.2183'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M102.231 49.172L100.87 46.8359'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.581 50.121L119.498 50.121L119.498 41.8574L120.482 41.8574'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M120.368 50.121L121.451 50.121L121.451 41.8574L120.467 41.8574'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.583 37.1371C121.551 36.5456 121.062 36.0825 120.469 36.0825C120.421 36.0825 120.372 36.0858 120.324 36.092C119.713 36.1722 119.283 36.7326 119.363 37.3431'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.398 39.2011L119.398 37.1353'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M121.583 37.1372L121.583 37.8975'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.947 29.8426C132.915 29.2511 132.426 28.7881 131.834 28.7881C131.785 28.7881 131.737 28.7914 131.689 28.7976C131.078 28.8778 130.647 29.4382 130.727 30.0487'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.164 29.8426C148.197 29.2511 148.686 28.7881 149.278 28.7881C149.894 28.7881 150.394 29.2877 150.394 29.9038C150.394 29.9521 150.39 30.0008 150.384 30.0487'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141.637 43.5415L142.986 43.5415L142.986 46.3874'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141.637 48.7369L142.986 48.7369L142.986 46.3774'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.342 46.2911L148.231 46.1846L146.729 44.7951L146.095 44.3423L145.559 44.0462L145.08 43.8599L144.62 43.7362L144.142 43.6359L143.613 43.552L143.006 43.4854L142.29 43.4364L141.437 43.4067L140.418 43.3965'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.662 40.5785L139.579 40.5785L139.579 32.3149L140.563 32.3149'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.45 40.578L141.533 40.578L141.533 32.3145L140.549 32.3145'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.802 42.4476L130.72 42.4476L130.72 34.1841L131.704 34.1841'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M131.59 42.4476L132.673 42.4476L132.673 34.1841L131.688 34.1841'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.662 40.5785L139.579 40.5785L139.579 32.3149L140.563 32.3149'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.45 40.578L141.533 40.578L141.533 32.3145L140.549 32.3145'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.769 46.2911L132.879 46.1846L134.382 44.7951L135.016 44.3423L135.552 44.0462L136.031 43.8599L136.49 43.7362L136.969 43.6359L137.497 43.552L138.105 43.4854L138.821 43.4364L139.673 43.4067L140.693 43.3965'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.805 47.4727L129.243 48.6996'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.474 43.5415L138.125 43.5415L138.125 46.3874'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.125 44.6685L136.759 44.6685L136.759 46.3705'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.474 48.7374L138.125 48.7374L138.125 46.3779'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M138.125 48.0966L136.759 48.0966L136.759 46.3945'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.457 50.1694L140.57 50.1694'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141.653 50.1694L140.54 50.1694'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141.637 44.0278L141.637 44.8437'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M130.763 31.9067L130.763 29.8408'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M132.947 29.8428L132.947 30.3644'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.677 33.8887L141.547 33.8887'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.521 42.4476L148.438 42.4476L148.438 34.1841L149.423 34.1841'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M149.309 42.4476L150.391 42.4476L150.391 34.1841L149.407 34.1841'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.985 44.6685L144.351 44.6685L144.351 46.3705'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M142.985 48.0971L144.351 48.0971L144.351 46.395'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M153.345 48.35L154.526 48.35L154.526 44.2183L153.345 44.2183'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.306 47.4727L151.868 48.6996'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M148.164 29.8428L148.164 30.3644'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M150.348 31.9072L150.348 29.8413'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.584 23.0439C140.579 23.0439 140.574 23.0439 140.569 23.0439C140.52 23.0439 140.472 23.0469 140.424 23.0535C139.813 23.1336 139.383 23.6937 139.463 24.3046'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M140.527 23.0439C140.532 23.0439 140.537 23.0439 140.542 23.0439C141.158 23.0439 141.658 23.5432 141.658 24.1592C141.658 24.2079 141.654 24.2562 141.648 24.3046'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M139.498 26.1621L139.498 24.0962'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M141.612 26.1621L141.612 24.0962'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.209 83.7329L180.209 119.585'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.897 83.7324L178.897 118.293'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.014 83.7324L197.014 119.653'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.472 83.7329L198.472 118.294'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M173.782 185.576L173.544 184.956L172.051 185.075L171.753 184.449L172.746 183.326L172.458 182.726L171.028 182.934L170.721 182.284L171.717 181.245L171.41 180.595L170.013 180.962L169.641 180.365L170.572 179.38'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.878 208.114L222.423 208.114L222.423 214.414L200.164 214.414'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M222.386 209.544L198.967 209.544'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M218.868 217.873L196.33 217.873'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M218.956 214.414L218.956 219.139L195.95 219.139'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.143 214.414L197.143 219.139L178.015 219.139'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.07 217.873L178.331 217.873'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M200.574 209.544L178.1 209.544'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.015 208.115L200.609 208.115L200.609 214.415L179.249 214.415'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.256 204.962L160.684 192.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.801 200.5L159.549 205.022L161.622 205.022'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.77 204.962L162.342 192.517'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.224 200.5L163.477 205.022L161.403 205.022'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.617 198.346L176.617 199.01L175.181 199.435L175.128 200.126L176.457 200.817L176.404 201.481L174.995 201.8L174.942 202.518L176.245 203.129L176.191 203.846L174.756 204.006L174.623 204.697L175.846 205.281'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M179.155 217.873L162.642 217.873'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.955 219.139L162.641 219.139'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.141 223.367L165.683 223.848L164.349 223.167L163.835 223.632L164.323 225.048L163.963 225.371'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.516 222.165L160.516 218.139'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
        <path
          d='M162.547 222.43L162.547 217.894'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.547 218.832L162.547 217.864L163.214 217.864L163.214 215.5L159.792 215.5L159.792 217.902L160.487 217.902L160.487 218.871'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M168.655 219.139L169.154 219.981L168.757 220.517L167.393 220.043L166.968 220.623L167.75 221.831L167.326 222.411L166.023 221.788L165.545 222.304L166.273 223.447'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.518 209.595L170.518 214.432'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.85 205.228L175.725 205.88L174.234 206.027L174.052 206.695L175.227 207.625L175.091 208.114'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.85 192.168L175.724 191.515L174.234 191.369L174.051 190.7L175.226 189.771L175.049 189.128L173.605 189.082L173.417 188.387L174.581 187.541L174.393 186.846L172.953 186.961L172.692 186.307L173.783 185.502'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.106 194.42C159.762 194.376 159.422 194.303 159.09 194.202C158.786 194.109 158.501 193.966 158.246 193.778C158.145 193.704 158.086 193.587 158.086 193.462C158.086 193.338 158.145 193.221 158.246 193.147C158.501 192.958 158.786 192.815 159.09 192.722C159.422 192.621 159.762 192.548 160.106 192.505C160.573 192.446 161.042 192.416 161.512 192.416C161.983 192.416 162.452 192.446 162.919 192.505C163.263 192.548 163.603 192.621 163.935 192.722C164.239 192.815 164.524 192.958 164.78 193.147C164.88 193.221 164.939 193.338 164.939 193.462C164.939 193.587 164.88 193.704 164.78 193.778C164.524 193.966 164.239 194.109 163.935 194.202C163.603 194.303 163.263 194.376 162.919 194.42'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='square'
          strokeLinejoin='round'
        />
        <path
          d='M160.37 200.93C159.149 200.299 158.671 198.798 159.301 197.577C159.565 197.068 159.995 196.665 160.52 196.437'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.451 196.415C163.724 196.933 164.336 198.385 163.818 199.658C163.596 200.203 163.187 200.652 162.665 200.925'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinejoin='round'
        />
        <path
          d='M159.057 190.017L158.932 192.516'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M176.616 199.05L176.616 198.386L175.18 197.961L175.127 197.27L176.456 196.579L176.403 195.915L174.994 195.596L174.941 194.878L176.244 194.267L176.191 193.55L174.755 193.39L174.622 192.699L175.845 192.115'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.613 159.784C164.141 161.942 164.151 164.827 162.636 166.994'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.14 174.029L165.682 173.547L164.348 174.228L163.834 173.764L164.322 172.347L163.826 171.902L162.584 172.642L162.052 172.158L162.575 170.818L162.514 170.762'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.596 179.427L170.245 178.864L168.802 179.262L168.391 178.704L169.154 177.414L168.757 176.879L167.393 177.353L166.968 176.772L167.75 175.565L167.326 174.984L166.023 175.608L165.545 175.092L166.273 173.949'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.854 168.696L160.949 168.696'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.855 168.696C159.852 169.332 158.689 169.669 157.502 169.669'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.696 166.894C163.222 167.372 163.261 168.187 162.782 168.712C162.538 168.98 162.193 169.133 161.83 169.133'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.83 169.133C161.456 169.133 161.101 168.97 160.856 168.687'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.286 164.713C161.587 164.582 161.126 163.91 161.256 163.212C161.353 162.693 161.757 162.285 162.275 162.184'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.83 157.677C161.456 157.677 161.101 157.839 160.856 158.123'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.696 159.916C162.964 159.672 163.117 159.326 163.117 158.963C163.117 158.252 162.541 157.676 161.83 157.676'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.168 119.381C180.168 124.026 183.933 127.791 188.577 127.791C188.761 127.791 188.944 127.785 189.127 127.773C193.761 127.469 197.272 123.466 196.968 118.832'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.916 118.21L166.916 116.507'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.177 118.211L163.496 118.211L163.496 116.435'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.496 118.21L165.206 120.046'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.916 118.211L165.206 120.046'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.88 118.293L180.112 120.408'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.653 108.65L166.273 104.812'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.421 108.935L168.041 105.097'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.601 113.618L165.363 108.588'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M163.496 114.803L165.206 112.968'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.177 114.803L163.496 114.803L163.496 116.579'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.737 114.675L167.717 108.968'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.916 114.803L166.916 116.507'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.916 114.804L165.206 112.968'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.362 108.588L166.539 108.779'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.717 108.968L166.54 108.778'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.538 102.979L167.402 103.441'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M164.538 102.978L164.85 101.111L167.704 101.572'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.827 101.269L166.181 98.9321'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.58 101.876L169.98 99.5459'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.313 104.657L165.543 103.142'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.313 104.657L167.157 104.955'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.001 105.253L169.26 103.742'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.266 103.904L170.558 102.033L167.704 101.572'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.266 103.904L167.402 103.441'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.001 105.253L167.157 104.955'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M166.182 98.9321L168.081 99.2389'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.98 99.546L168.081 99.2393'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.471 118.294L196.835 121.104'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.897 83.7326L178.897 49.1719'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.209 83.7328L180.209 47.8804'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.472 83.7326L198.472 49.1719'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M197.014 83.7331L197.014 47.8125'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.674 88.5498L172.006 88.5498'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M171.602 91.062L172.786 83.7139'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M170.596 85.8502C169.411 85.8502 168.45 84.8897 168.45 83.7049C168.45 82.52 169.411 81.5596 170.596 81.5596C171.78 81.5596 172.741 82.52 172.741 83.7049C172.741 84.8897 171.78 85.8502 170.596 85.8502Z'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.314 90.3695L168.505 83.0225'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M162.959 88.1974L162.959 79.293'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.077 98.8122L168.414 90.5425'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M169.221 99.1784L170.56 90.8882'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M167.314 90.3691L169.458 90.7154'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M171.601 91.0621L169.458 90.7158'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.766 87.4705L177.336 87.0979L176.59 86.5386L176.279 85.7934L176.093 84.6751L176.093 83.6191'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M174.675 88.5575L174.675 79.0469'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.896 90.3893L177.026 89.8926L176.653 88.961L176.466 88.0298L175.907 87.6572L175.368 87.6572'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.828 90.5755L177.896 90.3892'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.368 87.6572L175.086 87.6572'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M177.896 77.0762L177.026 77.5729L176.653 78.5044L176.466 79.4356L175.907 79.8082L175.368 79.8082'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.766 79.9946L177.336 80.3672L176.59 80.9265L176.279 81.6718L176.093 82.79L176.093 83.846'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M175.369 79.8081L175.087 79.8081'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.828 76.8901L177.896 77.0764'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M180.257 49.3006C179.585 44.7051 182.765 40.4349 187.361 39.7629C187.764 39.704 188.17 39.6743 188.577 39.6743C193.222 39.6743 196.987 43.4393 196.987 48.0839C196.987 48.2673 196.981 48.4507 196.969 48.6337'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.743 50.121L159.66 50.121L159.66 41.8574L160.644 41.8574'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M160.53 50.121L161.613 50.121L161.613 41.8574L160.629 41.8574'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.527 37.1372L159.527 37.8975'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M159.527 37.1375C159.56 36.546 160.049 36.083 160.641 36.083C161.257 36.083 161.757 36.5826 161.757 37.1987C161.757 37.247 161.754 37.2957 161.747 37.3436'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M161.712 39.2011L161.712 37.1353'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M178.88 49.1721L180.21 46.8877'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M198.471 49.172L196.835 46.3623'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M165.203 200.57L164.837 193.981'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M157.883 200.57L158.249 193.981'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M115.789 200.203L116.155 193.615'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M123.109 193.615L123.475 200.203L123.31 200.5L121.562 205.023L119.489 205.023'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M119.574 227.29L120.813 228.215L121.994 227.295L122.587 227.654L122.376 229.137L122.947 229.48L124.026 228.52L124.64 228.894L124.38 230.309L124.994 230.683L125.959 229.608L126.598 229.902L126.362 231.237'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M126.312 231.183L126.911 231.468L127.912 230.354L128.559 230.603L128.612 232.1L129.235 232.337L130.128 231.201L130.798 231.461L130.791 232.9L131.462 233.16L132.223 231.932L132.627 232.036'
          stroke='black'
          strokeWidth='0.549048'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_119_986'>
        <rect width='500' height='281.113' fill='white' transform='translate(281.112) rotate(90)' />
      </clipPath>
    </defs>
  </svg>
);
