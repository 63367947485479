import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

import type { SelectOption } from '../Select/Select';

const getLabelFromValueArray = (options: SelectOption[], value: string[]) => {
  return value.map(val => options.find(option => option.value === val)?.label ?? '').join(', ');
};

interface MultiSelectProps<ValueType> {
  label: string;
  options: SelectOption<ValueType>[];
  value: ValueType[];
  onChange: (value: ValueType[]) => void;
  disabled?: boolean;
}

export const MultiSelect = <ValueType extends string>({
  label,
  options,
  value,
  onChange,
  disabled
}: MultiSelectProps<ValueType>) => {
  const handleSelect = (selectedValue: ValueType[]) => {
    onChange(selectedValue);
  };

  return (
    <div className={twMerge('text-gray-700', disabled ? 'pointer-events-none opacity-40' : '')}>
      <Listbox value={value} onChange={handleSelect} multiple>
        <Listbox.Label className='mb-1 block text-sm text-neutral-400'>{label}</Listbox.Label>
        <ListboxButton className='flex h-12 w-full items-center justify-between border border-neutral-300 px-4 transition-[border-radius] duration-300 focus:outline-none ui-open:rounded-t-xl ui-not-open:rounded-xl'>
          {getLabelFromValueArray(options, value) || 'Please select'}
          <ChevronDownIcon className='h-5 w-5 transition-transform duration-300 ui-open:rotate-180 ui-not-open:rotate-0' />
        </ListboxButton>
        <Transition
          enter='transition-[max-height,opacity] ease-in duration-300'
          enterFrom='opacity-0 max-h-0 border-0'
          enterTo='opacity-100 max-h-60 border border-t-0'
          leave='transition-[max-height,opacity] ease-out duration-300'
          leaveFrom='opacity-100 max-h-60 border border-t-0'
          leaveTo='opacity-0 max-h-0 border-0'
        >
          <ListboxOptions
            anchor='bottom'
            className='w-[var(--button-width)] overflow-auto rounded-b-xl border border-t-0 bg-white [--anchor-max-height:18rem] focus:outline-none'
          >
            {options.map(({ label, value: optionValue }, idx) => (
              <ListboxOption key={label} className='select-none' value={optionValue ?? label}>
                {({ selected }) => (
                  <>
                    <p
                      className={twMerge('block truncate px-4 py-3', selected ? 'bg-brand/10' : '')}
                    >
                      {label}
                    </p>
                    {idx !== options.length - 1 && <hr className='mx-4 my-1' />}
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </Listbox>
    </div>
  );
};
