export enum Routes {
  DETECTIONS = '/detections',
  ACTION_CENTER = '/action-center',
  SUMMARY = '/summary',
  ANALYTICS = '/analytics',
  ADD_USER = '/add-user',
  PROJECT = '/projects/[projectId]',
  ORGANIZATION = '/organization',
  ACCOUNT = '/account',
  SAGE = '/sage',
  ADMIN = '/admin',
  ADMIN_ORGANIZATIONS = '/admin/organizations',
  DEVICE = '/devices/[deviceId]',

  // Routes for OEM partners
  HEXAGON_DETECTION_DETAILS = '/hexagon/iframe/detection-details',
  HEXAGON_APP = '/hexagon/iframe/app',
  HEXAGON_DEVICE_CONFIG = '/hexagon/iframe/device-config'
}

export enum QueryParamKeys {
  ALERT_ID = 'alertId',
  VIDEO_LINK = 'videoLink',
  INSIGHT_ID = 'insightId',
  REVIEW_MODE = 'reviewMode',
  SOURCE = 'source',
  TEMP_ACCESS_TOKEN = 'tempAccessToken',
  ORGANIZATION_ID = 'organizationId'
}

export enum LocalStorageKeys {
  ROI_ENABLED = 'roiEnabled',
  BBOXES_ENABLED = 'bboxesEnabled',
  ORGANIZATION_ID = 'organizationId',
  TIMEZONE = 'timezone'
}

export enum FilterLabels {
  PROJECT = 'projectIds',
  DEVICE = 'deviceIds',
  DATE = 'date',
  DETECTION_TYPE = 'detectionType',
  ALERT_TYPE = 'alertType',
  FEATURED = 'featured',
  REVIEW_STATUS = 'reviewStatus',
  RISK_STATE = 'riskState',
  GEOLOCATION_BOUNDS = 'geolocationBounds'
}

export enum AlertType {
  GENERAL = 'ALERT',
  CRITICAL = 'CRITICAL_ALERT'
}

export enum DetectionType {
  PERSON = 'PERSON',
  VEHICLE = 'VEHICLE',
  SAFETY_CONE = 'SAFETY_CONE'
}

export enum ReviewStatus {
  ALL = 'ALL',
  CONFIRMED_OR_NOT_REVIEWED = 'CONFIRMED_OR_NOT_REVIEWED',
  NOT_REVIEWED = 'NOT_REVIEWED',
  CONFIRMED = 'CONFIRMED',
  IGNORED = 'IGNORED',
  REJECTED = 'REJECTED'
}

export enum FeaturedStatus {
  ALL = 'ALL',
  FEATURED = 'FEATURED',
  NOT_FEATURED = 'NOT_FEATURED'
}

export enum RiskState {
  ANY_RISK = 'ALL',
  EXTREME_RISK = 'RISK_EXTREME',
  HIGH_AND_ABOVE_RISK = 'RISK_HIGH',
  MODERATE_AND_ABOVE_RISK = 'RISK_MODERATE',
  UNKNOWN_RISK = 'RISK_UNKNOWN',
  RISK_NOT_ASSESSED = 'RISK_NOT_ASSESSED'
}

export enum FeaturedBy {
  ANY_USER = 'ANY_USER',
  CURRENT_USER = 'CURRENT_USER'
}

export enum DateOptions {
  //TODAY = 'Today',
  //YESTERDAY = 'Yesterday',
  //THIS_WEEK = 'This week',
  LAST_7_DAYS = 'Last 7 days',
  //LAST_WEEK = 'Last week',
  //THIS_MONTH = 'This month',
  LAST_MONTH = 'Last month',
  CUSTOM_RANGE = 'Custom range'
}

export enum DateAggregation {
  DAILY = 'daily',
  WEEKLY = 'weekly'
}

export enum OrganizationUserRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER'
}

export enum ProjectUserRole {
  ADMIN = 'ADMIN',
  REVIEWER = 'REVIEWER'
}

export enum AlertUserRole {
  ADMIN = 'ADMIN',
  REVIEWER = 'REVIEWER'
}

export enum UserTypes {
  CUSTOMER = 'CUSTOMER',
  SUPPORT = 'SUPPORT',
  ENGINEERING = 'ENGINEERING'
}
