import type { Marker } from 'leaflet';
import { useEffect, useState } from 'react';
import type { MarkerProps } from 'react-leaflet';

export const useGetMarker = () => {
  const [Marker, setMarker] =
    useState<React.ForwardRefExoticComponent<MarkerProps & React.RefAttributes<Marker>>>();

  useEffect(() => {
    if (Marker !== undefined) return;

    import('react-leaflet').then(({ Marker: LMarker }) => {
      setMarker(LMarker);
    });
  }, [Marker]);

  return Marker;
};
