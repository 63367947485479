import { jsonToCSV } from 'react-papaparse';

import { dayJS } from '../const';

export const getCSVFileName = ({
  org,
  project,
  device,
  label
}: {
  org?: string;
  project?: string;
  device?: string;
  label: string;
}) => {
  const orgName = org ? `${org}-` : '';
  const projectName = project ? `${project}-` : '';
  const deviceName = device ? `${device}-` : '';

  const date = dayJS().format('YYYYMMDD');

  return `Blindsight-${orgName}${projectName}${deviceName}${label}-${date}`;
};

export const downloadCSV = <T>(data: T[], fileName: string) => {
  const csvData = jsonToCSV(data);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${fileName}.csv`;
  document.body.append(link);
  link.click();
  link.remove();
};
