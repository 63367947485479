import { createContext } from 'react';

import { DateAggregation } from '../const';
import type { AnalyticsFilters } from '../types';

export interface AnalyticsContextType {
  analyticsFilters: AnalyticsFilters;
  dateAggregation: DateAggregation;
  comparison?: boolean;
  comparisonCurrText?: string;
  comparisonPrevText?: string;
  count: number;
}

export const AnalyticsContext = createContext<AnalyticsContextType>({
  analyticsFilters: { startAt: '', reportingPeriodSecs: 0 },
  dateAggregation: DateAggregation.DAILY,
  comparison: false,
  comparisonCurrText: '',
  comparisonPrevText: '',
  count: 0
});
