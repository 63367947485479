import type { OffsetPaginatedQuery, OffsetPaginatedResponse } from './useApiHooks';
import { useApi } from './useApiHooks';
import type { AlertType, DetectionType, ReviewStatus } from '../../const';
import { generateURLSearchParams } from '../../utils';

export enum AggregatedType {
  ANY = 'ANY'
}

export type AggregatedAlertType = AlertType | AggregatedType;
export type AggregatedDetectionType = DetectionType | AggregatedType;
export type AggregatedReviewStatus = ReviewStatus | AggregatedType;

export interface AggregatedAlert {
  timezone: string;
  organizationId: string;
  projectId: string;
  deviceId: string;
  periodStartAt: string;
  alertType: AlertType;
  detectionType: DetectionType;
  totalAlertCount: number;
  confirmedAlertCount: number;
  ignoredAlertCount: number;
  notReviewedAlertCount: number;
  rejectedAlertCount: number;
}

export interface GetAggregatedAlertsQuery extends OffsetPaginatedQuery {
  organizationIds?: string;
  projectIds?: string;
  deviceIds?: string;
  startAt: string;
  reportingPeriodSecs: number;
  alertTypes?: AggregatedAlertType[];
  detectionTypes?: AggregatedDetectionType[];
  timezone: string;
}

export interface GetAggregatedAlertsResponse extends OffsetPaginatedResponse {
  results: AggregatedAlert[];
}

export const useGetAggregatedAlerts = ({
  queryParams,
  disableQuery,
  variant
}: {
  queryParams: GetAggregatedAlertsQuery;
  disableQuery?: boolean;
  variant: 'weekly' | 'daily';
}) => {
  const query: GetAggregatedAlertsQuery = {
    ...queryParams,
    limit: 10_000
  };

  return useApi<GetAggregatedAlertsResponse>(
    'get',
    disableQuery ? null : `/alerts/time-aggregated/${variant}${generateURLSearchParams(query)}`
  );
};
