export const TopconLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    version='1.1'
    id='layer'
    x='0px'
    y='0px'
    width='120'
    height='24'
    viewBox='-135.382 221.066 611.111 108.296'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      id='Topcon-logo'
      d='M96,243v11.8H72.7v63.6H53.3V255H28.5v-12H96z M133.2,240.8c21.2,0.1,38.4,17.8,38.4,39.6
	c0,21.9-17.3,39.6-38.6,39.6c-21.3,0-38.6-17.7-38.6-39.6c0-21.8,16.7-39.5,38.6-39.6v0C133,240.8,133.1,240.8,133.2,240.8
	L133.2,240.8L133.2,240.8z M133,308.6c10.3,0,18.6-12.6,18.6-28.1c0-15.5-8.3-28-18.5-28.1v0c-11.3,0-18.8,12.5-18.8,28.1
	C114.3,296,122.7,308.6,133,308.6z M346.7,240.7c21.2,0.1,38.4,17.8,38.4,39.6c0,21.9-17.3,39.6-38.6,39.6s-38.6-17.7-38.6-39.6
	c0-21.8,16.7-39.5,38.6-39.6v0C346.6,240.7,346.6,240.7,346.7,240.7L346.7,240.7z M346.5,308.5c10.3,0,18.6-12.6,18.6-28.1
	c0-15.4-8.3-28-18.5-28.1v0c-11.3,0-18.8,12.5-18.8,28.1C327.9,295.9,336.2,308.5,346.5,308.5z M212.6,243c36.4,0,38.4,57.9-0.2,58
	h-11.8v17.4h-20.9V243H212.6z M206.1,290.2c20.9,0,21.2-36.7,0-36.7h-5.4V268v0.5v21.7H206.1z M291.1,309.4c5.3,0,12.3-2.7,14.1-6.6
	c0-0.1,0,14,0,14c-5.6,3.3-11.9,2.9-18.8,2.9c-21.2,0-38.4-17.6-38.4-39.3c0-21.7,17.2-39.3,38.4-39.3c8.7,0,17.1,1.4,23.5,6.4
	c0.1,0-4.7,10.2-4.7,10.2c-3-3.1-8.8-6.1-14.1-6.1c-12.7,0-23,12.9-23,28.9C268.1,296.5,278.4,309.4,291.1,309.4z M450.1,257.6
	l18.9,60.8h-22.3l-15.9-54.9c-1.8-6.3-2.8-9.6-7.3-9.6h-10v64.6l-19.4,0v-75.5h31.5C438,242.7,445.2,244.9,450.1,257.6z'
    ></path>
    <path
      id='Topcon-logo_1_'
      fill='#007DC5'
      d='M-44.2,257.5c22.2-25.8,48.7-38.4,58.3-28.7c6.4,6.5,4.1,22.2-4.7,39.5l-127,6
	c3.4-5.4,7.7-11.5,12.3-16.9c22.2-25.8,48.7-38.4,58.3-28.7c5.9,6,4.5,19.7-2.7,35.4C-47.9,261.9-46.1,259.7-44.2,257.5z
	 M-119.1,276.6L-119.1,276.6l120.3,5.4c-2.8,4.1-5.8,8-9.3,12c-22.2,25.8-48.6,37.8-58.1,28.1c-5.9-6-4.6-19.3,2.4-34.8
	c-1.7,2.2-3.5,4.5-5.4,6.7c-22.2,25.8-48.6,37.8-58.1,28.1C-134.5,314.9-130.9,296.4-119.1,276.6z'
    ></path>
  </svg>
);
