import type { SWRMutationConfig } from './useApiHooks';
import { useMutationApi } from './useApiHooks';

export interface UpdateCurrentUser {
  givenName?: string;
  familyName?: string;
  jobTitle?: string;
  phoneNumber?: string;
  timezone?: string;
  lastActive?: string;
}

export const useUpdateUser = (swrConfig?: SWRMutationConfig<never>) =>
  useMutationApi<UpdateCurrentUser>('patch', `/users/current`, undefined, swrConfig);
