export const ExpandIconSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='12'
    height='10'
    viewBox='0 0 12 10'
    fill='black'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M6.17645 3.68911C6.72951 4.08825 6.72951 4.91175 6.17645 5.31088L1.68526 8.55213C1.02389 9.02943 0.10005 8.55685 0.10005 7.74124L0.10005 1.25876C0.10005 0.443148 1.02389 -0.029427 1.68526 0.447872L6.17645 3.68911Z' />
  </svg>
);
