import { useApi } from './useApiHooks';
import type { AlertType, DetectionType, ReviewStatus, RiskState } from '../../const';
import { generateURLSearchParams } from '../../utils';
import type { Device } from './useGetDevices';

export interface MinDistanceRange {
  max_m: number;
  min_m: number;
}

export interface Frame {
  max_x: number;
  max_y: number;
  min_x: number;
  min_y: number;
  risk_state: RiskState;
  risk_value: number | null;
  detection_type: DetectionType;
}

export interface FramesMap {
  [frameName: `frame-${number}`]: Frame[];
}

export interface Alert {
  alertId?: string;
  occurredAt?: string;
  organizationId?: string;
  projectId?: string;
  deviceId?: string;
  alertType?: AlertType;
  detectionType?: DetectionType;
  reviewStatus?: ReviewStatus;
  geolocationLatitude?: number | null;
  geolocationLongitude?: number | null;
  videoThumbnailUrl?: string | null;
  previewVideoUrl?: string | null;
  sensor?: string;
  riskState?: RiskState;
  riskValue?: number;
  minDistanceRange?: MinDistanceRange | null;
  generalZones?: number[][][] | null;
  criticalZones?: number[][][] | null;
  exclusionZones?: number[][][] | null;
  detectionDetails?: FramesMap | null;
  featured?: boolean;
  speedKmph?: number | null;
  device?: Device;
}

export interface GetAlertQuery {
  alertId: string;
}

export interface GetAlertResponse {
  results: Alert;
}

export const useGetAlert = (queryParams?: GetAlertQuery, disableQuery = false) =>
  useApi<GetAlertResponse>(
    'get',
    !disableQuery && queryParams?.alertId ? `/alert${generateURLSearchParams(queryParams)}` : null
  );
