import { Description, Label, Radio, RadioGroup as HURadioGroup } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

export interface RadioOption<T = string> {
  label: string;
  value: T;
  icon?: React.ReactNode;
  description?: string;
}

interface RadioGroupProps<T> {
  options: RadioOption<T>[];
  defaultValue?: T;
  value?: T;
  onChange: (value: T) => void;
  className?: string;
}

export const RadioGroup = <T extends string>({
  options,
  defaultValue,
  value,
  onChange,
  className
}: RadioGroupProps<T>) => {
  return (
    <HURadioGroup
      defaultValue={defaultValue}
      value={value ?? null}
      onChange={onChange}
      className={twMerge('grid grid-cols-radiogroup gap-x-4 gap-y-5', className)}
    >
      {options.map(({ label, value, icon, description }) => (
        <Radio
          key={label}
          value={value}
          as='button'
          className='flex min-h-32 flex-col rounded-xl bg-white pt-2 ring-1 ring-charcoal-light ui-checked:ring-2 ui-checked:ring-brand'
        >
          <div className='flex items-center space-x-3 px-3'>
            <div className='flex size-5 items-center justify-center rounded-full ring-1 ring-charcoal-light ui-checked:ring-brand'>
              <div className='hidden size-3 rounded-full bg-brand ui-checked:block' />
            </div>
            <Label className='cursor-pointer text-lg font-medium text-charcoal'>{label}</Label>
          </div>
          {icon}
          <Description className='mt-3 whitespace-pre-line ps-11 text-left'>
            {description}
          </Description>
        </Radio>
      ))}
    </HURadioGroup>
  );
};
