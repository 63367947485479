import { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export interface CheckboxOption<T = string> {
  label: string;
  value: T;
  icon?: React.ReactNode;
}

interface CheckboxGroupProps<T> {
  options: CheckboxOption<T>[];
  defaultValue?: T[];
  value?: T[];
  onChange: (value: T[]) => void;
  className?: string;
}

export const CheckboxGroup = <T extends string | number>({
  options,
  defaultValue,
  value,
  onChange,
  className
}: CheckboxGroupProps<T>) => {
  const [selectedValues, setSelectedValues] = useState<T[]>([]);

  useEffect(() => {
    if (value) return setSelectedValues(value);
    if (defaultValue) return setSelectedValues(defaultValue);
  }, [value, defaultValue]);

  const handleCheckboxChange = useCallback(
    (selectedValue: T) => {
      const newSelectedValues = selectedValues.includes(selectedValue)
        ? selectedValues.filter(value => value !== selectedValue)
        : [...selectedValues, selectedValue];

      setSelectedValues(newSelectedValues);
      onChange(newSelectedValues);
    },
    [selectedValues, onChange]
  );

  return (
    <div className={twMerge('grid grid-cols-radiogroup gap-x-4 gap-y-5', className)}>
      {options.map(({ label, value, icon }) => (
        <button
          key={label}
          className={twMerge(
            'flex min-h-32 flex-col rounded-xl bg-white ring-1 ring-charcoal-light',
            selectedValues.includes(value) && 'ring-2 ring-brand'
          )}
          onClick={() => handleCheckboxChange(value)}
        >
          <div className='flex items-start p-3'>
            <div
              className={twMerge(
                'mt-1 flex size-5 min-w-5 items-center justify-center rounded-full ring-1 ring-charcoal-light',
                selectedValues.includes(value) && 'ring-brand'
              )}
            >
              <div
                className={twMerge(
                  'hidden size-3 rounded-full bg-brand',
                  selectedValues.includes(value) && 'block'
                )}
              />
            </div>
            <label
              htmlFor={label}
              className='cursor-pointer pl-3 text-left text-lg font-medium text-charcoal'
            >
              {label}
            </label>
          </div>
          {icon}
        </button>
      ))}
    </div>
  );
};
