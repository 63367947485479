import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { DEFAULT_FILTERS, Routes } from '../const';
import type { QueryParams } from '../types';
import { trackEvent } from '../utils';

export const useValidateRoute = () => {
  const router = useRouter();
  const query = router.query as QueryParams;
  const isReady = router.isReady;

  useEffect(() => {
    if (!isReady) return;

    if (router.route.split('/')[1] === Routes.ADD_USER) return;

    // TODO only here temporarily until sage has its own route validation
    if (router.route === '/sage') return;

    if (!query) {
      router.replace({ query: { ...DEFAULT_FILTERS } satisfies QueryParams });
      return;
    }

    // If query contains code or state, it means that the user has just logged in and
    // we don't want to validate the filters until the redirect is complete
    if (Object.keys(query).includes('code') || Object.keys(query).includes('state')) return;

    const tempQuery = { ...DEFAULT_FILTERS, ...query };

    if (tempQuery.source) {
      trackEvent(tempQuery.source);
      delete tempQuery.source;
    }

    for (const key of Object.keys(tempQuery)) {
      if (tempQuery[key as keyof QueryParams] === '') {
        delete tempQuery[key as keyof QueryParams];
      }
    }

    if (JSON.stringify(tempQuery) !== JSON.stringify(query)) {
      router.replace({ query: { ...tempQuery } satisfies QueryParams });
    }
  }, [query, router, isReady]);
};
