export const RotateIconSVG: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width='45'
    height='45'
    viewBox='0 0 45 45'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle cx='22.5' cy='22.4941' r='21.5' fill='white' stroke='#FF7D33' strokeWidth='2' />
    <g clipPath='url(#clip0_403_414905)'>
      <path
        d='M9.60938 13.1191V20.1518H16.642'
        stroke='#FF7D33'
        strokeWidth='1.66678'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.551 26.0106C13.3109 28.1675 14.7512 30.019 16.6548 31.2861C18.5585 32.5532 20.8224 33.1674 23.1054 33.036C25.3884 32.9046 27.5669 32.0347 29.3126 30.5576C31.0583 29.0804 32.2766 27.0759 32.7841 24.8461C33.2915 22.6163 33.0605 20.282 32.1259 18.1949C31.1913 16.1078 29.6037 14.381 27.6024 13.2746C25.601 12.1683 23.2943 11.7423 21.0298 12.0609C18.7653 12.3795 16.6657 13.4254 15.0473 15.0411L9.60938 20.1508'
        stroke='#FF7D33'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_403_414905'>
        <rect width='28.1274' height='28.1274' fill='white' transform='translate(8.4375 8.43164)' />
      </clipPath>
    </defs>
  </svg>
);
