import type { LayerProps } from '@react-leaflet/core';
import type L from 'leaflet';
import { useEffect, useState } from 'react';

interface GoogleMapsAddLayer {
  name: 'BicyclingLayer' | 'TrafficLayer' | 'TransitLayer';
}

interface GoogleMapsLayerProps extends Partial<L.TileLayerOptions> {
  zIndex?: number;
  useGoogMapsLoader?: boolean;
  googleMapsAddLayers?: GoogleMapsAddLayer[];
  apiKey?: string;
  type?: 'roadmap' | 'satellite' | 'terrain' | 'hybrid';
  maxZoom?: number;
}

export const useGetReactLeafletGoogleLayer = () => {
  const [ReactLeafletGoogleLayer, setReactLeafletGoogleLayer] =
    useState<
      React.ForwardRefExoticComponent<
        LayerProps & GoogleMapsLayerProps & React.RefAttributes<L.GridLayer>
      >
    >();

  useEffect(() => {
    if (ReactLeafletGoogleLayer !== undefined) return;

    import('react-leaflet-google-layer').then(m => {
      setReactLeafletGoogleLayer(m.default);
    });
  }, [ReactLeafletGoogleLayer]);

  return ReactLeafletGoogleLayer;
};
