import type { MarkerClusterGroupOptions } from 'leaflet';
import { useEffect, useState } from 'react';

export const useGetMarkerClusterGroup = () => {
  const [MarkerClusterGroup, setMarkerClusterGroup] = useState<
    React.ForwardRefExoticComponent<
      MarkerClusterGroupOptions & {
        children: React.ReactNode;
      } & React.RefAttributes<L.MarkerClusterGroup>
    >
  >();

  useEffect(() => {
    if (MarkerClusterGroup !== undefined) return;

    import('react-leaflet-cluster').then(m => {
      setMarkerClusterGroup(m.default);
    });
  }, [MarkerClusterGroup]);

  return MarkerClusterGroup;
};
